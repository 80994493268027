/* eslint-disable */
type CrTimestamp = number;
type CrJson = any;
type CrBigInt = number;
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserPostboxList
// ====================================================

export interface GetUserPostboxList_user_postbox_list_item_list_shop_info {
  /**
   * 스토어명
   */
  shop_name: string | null;
  /**
   * 스토어 대표 이미지
   */
  shop_image: string | null;
}

export interface GetUserPostboxList_user_postbox_list_item_list_extra_info_buttons {
  /**
   * 버튼 명
   */
  name: string | null;
  /**
   * 클릭 시 이동 url
   */
  link_url: string | null;
}

export interface GetUserPostboxList_user_postbox_list_item_list_extra_info {
  buttons: GetUserPostboxList_user_postbox_list_item_list_extra_info_buttons[] | null;
}

export interface GetUserPostboxList_user_postbox_list_item_list {
  /**
   * 기본 키
   */
  id: string;
  /**
   * 해당하는 사용자(uuid or user_account_id)
   */
  uid: string;
  /**
   * 사용자 알림 유형
   */
  category: string;
  /**
   * 알림 생성일
   */
  date_created: CrTimestamp;
  /**
   * 알림 확인일
   */
  date_read: CrTimestamp | null;
  /**
   * 알림 확인 여부
   */
  read: boolean;
  /**
   * 알림 아이콘 url
   */
  icon_image_url: string | null;
  /**
   * 알림 내용 이미지 url
   */
  image_url: string | null;
  /**
   * 알림 내용 이미지 url 목록
   */
  image_url_list: string[] | null;
  /**
   * 알림 표시 title
   */
  title: string | null;
  /**
   * 알림 표시 text
   */
  text: string;
  /**
   * Landing url
   */
  url: string;
  /**
   * 스토어 ID
   */
  shop_id: string | null;
  /**
   * 캠페인 ID
   */
  campaign_id: string | null;
  /**
   * 사이트 ID
   */
  site_id: string | null;
  /**
   * 알림 구독한 스토어 정보
   */
  shop_info: GetUserPostboxList_user_postbox_list_item_list_shop_info | null;
  /**
   * 스토어 알림의 경우 스토어별 읽지 않은 알림 갯수
   */
  shop_unseen_count: number | null;
  /**
   * 버튼 등 알림에 부가적인 요소 정보
   */
  extra_info: GetUserPostboxList_user_postbox_list_item_list_extra_info | null;
}

export interface GetUserPostboxList_user_postbox_list {
  /**
   * 알림 목록
   */
  item_list: GetUserPostboxList_user_postbox_list_item_list[];
  /**
   * 한 페이지에 보여질 아이템 수
   */
  limit: number | null;
  /**
   * 다음 페이지 커서 (값이 없을 경우 end page)
   */
  end_cursor: string | null;
  /**
   * 전체 알림 개수
   */
  total_count: number | null;
  /**
   * 전체 읽지 않은 알림 개수
   */
  total_unread_count: number | null;
}

export interface GetUserPostboxList {
  /**
   * 사용자의 알림 목록을 전달
   */
  user_postbox_list: GetUserPostboxList_user_postbox_list;
}

export interface GetUserPostboxListVariables {
  categories?: string[] | null;
  limit?: number | null;
  end_cursor?: string | null;
  shop_id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAccountShopNotificationList
// ====================================================

export interface GetUserAccountShopNotificationList_user_account_shop_notification_list_shop_info {
  /**
   * 스토어명
   */
  shop_name: string | null;
  /**
   * 스토어 대표 이미지
   */
  shop_image: string | null;
}

export interface GetUserAccountShopNotificationList_user_account_shop_notification_list_item_list_shop_info {
  /**
   * 스토어명
   */
  shop_name: string | null;
  /**
   * 스토어 대표 이미지
   */
  shop_image: string | null;
}

export interface GetUserAccountShopNotificationList_user_account_shop_notification_list_item_list_extra_info_buttons {
  /**
   * 버튼 명
   */
  name: string;
  /**
   * 클릭 시 이동 url
   */
  link_url: string;
}

export interface GetUserAccountShopNotificationList_user_account_shop_notification_list_item_list_extra_info {
  buttons: GetUserAccountShopNotificationList_user_account_shop_notification_list_item_list_extra_info_buttons[] | null;
}

export interface GetUserAccountShopNotificationList_user_account_shop_notification_list_item_list {
  /**
   * 기본 키
   */
  id: string;
  /**
   * 해당하는 사용자(uuid or user_account_id)
   */
  uid: string;
  /**
   * 사용자 알림 유형
   */
  category: UserNotificationCategory;
  /**
   * 알림 생성일
   */
  date_created: CrTimestamp;
  /**
   * 알림 확인일
   */
  date_read: CrTimestamp | null;
  /**
   * 알림 확인 여부
   */
  read: boolean;
  /**
   * 알림 아이콘 url
   */
  icon_image_url: string | null;
  /**
   * 알림 내용 이미지 url
   */
  image_url: string | null;
  /**
   * 알림 내용 이미지 url 목록
   */
  image_url_list: string[] | null;
  /**
   * 알림 표시 title
   */
  title: string | null;
  /**
   * 알림 표시 text
   */
  text: string;
  /**
   * Landing url
   */
  url: string;
  /**
   * 스토어 ID
   */
  shop_id: string | null;
  /**
   * 캠페인 ID
   */
  campaign_id: string | null;
  /**
   * 사이트 ID
   */
  site_id: string | null;
  /**
   * 알림 구독한 스토어 정보
   */
  shop_info: GetUserAccountShopNotificationList_user_account_shop_notification_list_item_list_shop_info | null;
  /**
   * 스토어 알림의 경우 스토어별 읽지 않은 알림 갯수
   */
  shop_unseen_count: number | null;
  /**
   * 버튼 등 알림에 부가적인 요소 정보
   */
  extra_info: GetUserAccountShopNotificationList_user_account_shop_notification_list_item_list_extra_info | null;
}

export interface GetUserAccountShopNotificationList_user_account_shop_notification_list {
  /**
   * 스토어 정보
   */
  shop_info: GetUserAccountShopNotificationList_user_account_shop_notification_list_shop_info | null;
  /**
   * 알림 목록
   */
  item_list: GetUserAccountShopNotificationList_user_account_shop_notification_list_item_list[];
  /**
   * 전체 알림 개수
   */
  total_count: number;
}

export interface GetUserAccountShopNotificationList {
  /**
   * 사용자가 구독하는 스토어의 상세 알림 목록을 가져온다.
   */
  user_account_shop_notification_list: GetUserAccountShopNotificationList_user_account_shop_notification_list;
}

export interface GetUserAccountShopNotificationListVariables {
  shop_id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserNotificationCategoryList
// ====================================================

export interface GetUserNotificationCategoryList_user_notification_category_list {
  /**
   * 배송 국가 id
   */
  country_id: string | null;
  /**
   * 배송 국가에 해당하는 알림 카테고리 리스트
   */
  category_list: UserNotificationCategory[] | null;
}

export interface GetUserNotificationCategoryList {
  /**
   * 배송 국가에 맞게 알림함 카테고리를 반환한다.
   */
  user_notification_category_list: GetUserNotificationCategoryList_user_notification_category_list | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserNotification
// ====================================================

export interface DeleteUserNotification {
  /**
   * 알림함에 있는 알림 개별 삭제를 하고, date_deleted를 채운다.
   */
  deleteUserNotification: boolean;
}

export interface DeleteUserNotificationVariables {
  input: DeleteUserNotificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserAccountShopNotificationList
// ====================================================

export interface DeleteUserAccountShopNotificationList {
  /**
   * 알림함에 있는 스토어별 알림 전체 삭제를 하고, date_deleted를 채운다.
   */
  deleteUserAccountShopNotificationList: boolean;
}

export interface DeleteUserAccountShopNotificationListVariables {
  input: DeleteUserAccountShopNotificationListInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserNotificationSeenDate
// ====================================================

export interface UpdateUserNotificationSeenDate {
  /**
   * 최근 알림 확인 일을 업데이트 한다.
   */
  updateUserNotificationSeenDate: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkUserNotificationAsRead
// ====================================================

export interface MarkUserNotificationAsRead_markUserNotificationAsRead {
  /**
   * 미확인 알림 수
   */
  total_unseen_count: number;
}

export interface MarkUserNotificationAsRead {
  /**
   * 읽음 표시를 설정한다.
   */
  markUserNotificationAsRead: MarkUserNotificationAsRead_markUserNotificationAsRead;
}

export interface MarkUserNotificationAsReadVariables {
  input: MarkUserNotificationAsReadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAdNotiStatus
// ====================================================

export interface GetUserAdNotiStatus_user {
  /**
   * 광고성 푸시 알림 동의 여부
   */
  ad_noti_status: AdNotiStatus | null;
}

export interface GetUserAdNotiStatus {
  /**
   * 주어진 조건 모두에 일치하는 사용자를 받는다.
   * 조건에 맞는 사용자가 없으면 null을 반환한다.
   * 조건이 주어지지 않으면 null을 반환한다.
   */
  user: GetUserAdNotiStatus_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserAdNotiStatus
// ====================================================

export interface UpdateUserAdNotiStatus_updateUserAdNotiStatus {
  /**
   * 성공여부
   */
  success: boolean;
  /**
   * 앱 푸시 상태 변경 완료 토스트 메세지
   */
  message: string | null;
}

export interface UpdateUserAdNotiStatus {
  /**
   * 사용자 광고 푸시 동의 상태를 갱신한다
   */
  updateUserAdNotiStatus: UpdateUserAdNotiStatus_updateUserAdNotiStatus;
}

export interface UpdateUserAdNotiStatusVariables {
  input: UpdateUserAdNotiStatusInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMarketingAgreementStatus
// ====================================================

export interface GetMarketingAgreementStatus_user_account_marketing_agreement {
  /**
   * sms 수신 동의 여부
   */
  sms_reception_agreed: boolean | null;
  /**
   * email 수신 동의 여부
   */
  email_reception_agreed: boolean | null;
  /**
   * 야간 혜택 알림 수신 동의 여부
   */
  nightly_ad_noti_agreed: boolean | null;
}

export interface GetMarketingAgreementStatus {
  user_account_marketing_agreement: GetMarketingAgreementStatus_user_account_marketing_agreement;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSmsReceptionAgreed
// ====================================================

export interface UpdateSmsReceptionAgreed {
  /**
   * email 수신 동의 여부를 세팅한다
   */
  setUserAccountSmsReceptionAgreed: boolean;
}

export interface UpdateSmsReceptionAgreedVariables {
  input: SetUserAccountSmsReceptionAgreedInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEmailReceptionAgreed
// ====================================================

export interface UpdateEmailReceptionAgreed {
  /**
   * sms 수신 동의 여부를 세팅한다
   */
  setUserAccountEmailReceptionAgreed: boolean;
}

export interface UpdateEmailReceptionAgreedVariables {
  input: SetUserAccountEmailReceptionAgreedInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateNightlyAdNotiAgreed
// ====================================================

export interface UpdateNightlyAdNotiAgreed {
  /**
   * 야간 혜택 알림 수신 동의 여부를 세팅한다
   */
  setUserAccountNightlyAdNotiAgreed: boolean;
}

export interface UpdateNightlyAdNotiAgreedVariables {
  input: SetUserAccountNightlyAdNotiAgreedInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserPushAgreementStatusList
// ====================================================

export interface GetUserPushAgreementStatusList_user_notification_push_agreement_list_item_list {
  /**
   * 알림 카테고리
   */
  category_id: UserNotificationCategory;
  /**
   * 알림 수신 동의 여부
   */
  agreed: boolean;
}

export interface GetUserPushAgreementStatusList_user_notification_push_agreement_list {
  /**
   * 알림 수신 동의 레코드 개수
   */
  total_count: number;
  /**
   * 사이트별 알림 수신 동의 리스트
   */
  item_list: GetUserPushAgreementStatusList_user_notification_push_agreement_list_item_list[];
}

export interface GetUserPushAgreementStatusList {
  /**
   * 사용자 기기의 사이트별 알림 수신 동의 정보를 가져온다
   * [error_code]
   * - user_account_not_found: user_account_id 에 해당하는 유저 계정을 찾을 수 없습니다.
   */
  user_notification_push_agreement_list: GetUserPushAgreementStatusList_user_notification_push_agreement_list;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserPushAgreementStatus
// ====================================================

export interface UpdateUserPushAgreementStatus {
  /**
   * 알림 푸시 수신을 카테고리별로 설정한다.
   */
  setUserNotificationPushAgreed: boolean;
}

export interface UpdateUserPushAgreementStatusVariables {
  input: SetUserNotificationPushAgreedInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserNotificationAgreementList
// ====================================================

export interface GetUserNotificationAgreementList_user_notification_agreement_list_item_list_item_list {
  /**
   * 순서
   */
  order: number;
  /**
   * 동의 항목 명
   */
  name: string;
  /**
   * 동의 항목 설명
   */
  description: string | null;
  /**
   * 설정 타입
   */
  type: string;
  /**
   * 설정 항목
   */
  category: string;
  /**
   * 동의 여부
   */
  agreed: boolean;
}

export interface GetUserNotificationAgreementList_user_notification_agreement_list_item_list {
  /**
   * 순서
   */
  order: number;
  /**
   * 그룹 명
   */
  name: string;
  /**
   * 그룹 설명
   */
  description: string | null;
  /**
   * 동의 항목 목록
   */
  item_list: GetUserNotificationAgreementList_user_notification_agreement_list_item_list_item_list[];
}

export interface GetUserNotificationAgreementList_user_notification_agreement_list {
  item_list: GetUserNotificationAgreementList_user_notification_agreement_list_item_list[];
}

export interface GetUserNotificationAgreementList {
  /**
   * 사용자의 알림 설정을 전달
   */
  user_notification_agreement_list: GetUserNotificationAgreementList_user_notification_agreement_list;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAgreeUserNotification
// ====================================================

export interface UpdateAgreeUserNotification_agreeUserNotification {
  message: string | null;
}

export interface UpdateAgreeUserNotification {
  /**
   * 알림 동의 여부를 받는다.
   */
  agreeUserNotification: UpdateAgreeUserNotification_agreeUserNotification;
}

export interface UpdateAgreeUserNotificationVariables {
  input: AgreeUserNotificationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserNotificationItem
// ====================================================

export interface UserNotificationItem_shop_info {
  /**
   * 스토어명
   */
  shop_name: string | null;
  /**
   * 스토어 대표 이미지
   */
  shop_image: string | null;
}

export interface UserNotificationItem_extra_info_buttons {
  /**
   * 버튼 명
   */
  name: string;
  /**
   * 클릭 시 이동 url
   */
  link_url: string;
}

export interface UserNotificationItem_extra_info {
  buttons: UserNotificationItem_extra_info_buttons[] | null;
}

export interface UserNotificationItem {
  /**
   * 기본 키
   */
  id: string;
  /**
   * 해당하는 사용자(uuid or user_account_id)
   */
  uid: string;
  /**
   * 사용자 알림 유형
   */
  category: UserNotificationCategory;
  /**
   * 알림 생성일
   */
  date_created: CrTimestamp;
  /**
   * 알림 확인일
   */
  date_read: CrTimestamp | null;
  /**
   * 알림 확인 여부
   */
  read: boolean;
  /**
   * 알림 아이콘 url
   */
  icon_image_url: string | null;
  /**
   * 알림 내용 이미지 url
   */
  image_url: string | null;
  /**
   * 알림 내용 이미지 url 목록
   */
  image_url_list: string[] | null;
  /**
   * 알림 표시 title
   */
  title: string | null;
  /**
   * 알림 표시 text
   */
  text: string;
  /**
   * Landing url
   */
  url: string;
  /**
   * 스토어 ID
   */
  shop_id: string | null;
  /**
   * 캠페인 ID
   */
  campaign_id: string | null;
  /**
   * 사이트 ID
   */
  site_id: string | null;
  /**
   * 알림 구독한 스토어 정보
   */
  shop_info: UserNotificationItem_shop_info | null;
  /**
   * 스토어 알림의 경우 스토어별 읽지 않은 알림 갯수
   */
  shop_unseen_count: number | null;
  /**
   * 버튼 등 알림에 부가적인 요소 정보
   */
  extra_info: UserNotificationItem_extra_info | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserPostboxItem
// ====================================================

export interface UserPostboxItem_shop_info {
  /**
   * 스토어명
   */
  shop_name: string | null;
  /**
   * 스토어 대표 이미지
   */
  shop_image: string | null;
}

export interface UserPostboxItem_extra_info_buttons {
  /**
   * 버튼 명
   */
  name: string | null;
  /**
   * 클릭 시 이동 url
   */
  link_url: string | null;
}

export interface UserPostboxItem_extra_info {
  buttons: UserPostboxItem_extra_info_buttons[] | null;
}

export interface UserPostboxItem {
  /**
   * 기본 키
   */
  id: string;
  /**
   * 해당하는 사용자(uuid or user_account_id)
   */
  uid: string;
  /**
   * 사용자 알림 유형
   */
  category: string;
  /**
   * 알림 생성일
   */
  date_created: CrTimestamp;
  /**
   * 알림 확인일
   */
  date_read: CrTimestamp | null;
  /**
   * 알림 확인 여부
   */
  read: boolean;
  /**
   * 알림 아이콘 url
   */
  icon_image_url: string | null;
  /**
   * 알림 내용 이미지 url
   */
  image_url: string | null;
  /**
   * 알림 내용 이미지 url 목록
   */
  image_url_list: string[] | null;
  /**
   * 알림 표시 title
   */
  title: string | null;
  /**
   * 알림 표시 text
   */
  text: string;
  /**
   * Landing url
   */
  url: string;
  /**
   * 스토어 ID
   */
  shop_id: string | null;
  /**
   * 캠페인 ID
   */
  campaign_id: string | null;
  /**
   * 사이트 ID
   */
  site_id: string | null;
  /**
   * 알림 구독한 스토어 정보
   */
  shop_info: UserPostboxItem_shop_info | null;
  /**
   * 스토어 알림의 경우 스토어별 읽지 않은 알림 갯수
   */
  shop_unseen_count: number | null;
  /**
   * 버튼 등 알림에 부가적인 요소 정보
   */
  extra_info: UserPostboxItem_extra_info | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * 광고성 푸시 동의 상태
 */
export enum AdNotiStatus {
  AGREE = "AGREE",
  DISAGREE = "DISAGREE",
}

export enum UserNotiStatus {
  AGREE = "AGREE",
  DISAGREE = "DISAGREE",
}

/**
 * 유저 알림 카테고리
 */
export enum UserNotificationCategory {
  BEAUTY = "BEAUTY",
  DAILY_MISSION = "DAILY_MISSION",
  EPICK = "EPICK",
  EXPIRED_USER_BENEFIT = "EXPIRED_USER_BENEFIT",
  INFORMATION = "INFORMATION",
  MEMBERSHIP = "MEMBERSHIP",
  NORMAL = "NORMAL",
  ORDER = "ORDER",
  PROMOTION_202406 = "PROMOTION_202406",
  RESTOCK = "RESTOCK",
  REVIEW = "REVIEW",
  SALE_TAB = "SALE_TAB",
  SAVED_PRODUCTS = "SAVED_PRODUCTS",
  SHOP = "SHOP",
  TALK_LOUNGE = "TALK_LOUNGE",
  WEATHER = "WEATHER",
  ZPAY = "ZPAY",
}

export interface AgreeUserNotificationInput {
  type: string;
  category: string;
  agreed: boolean;
}

export interface DeleteUserAccountShopNotificationListInput {
  shop_id_list: string[];
}

export interface DeleteUserNotificationInput {
  id: string[];
}

/**
 * 읽음 표시 입력
 */
export interface MarkUserNotificationAsReadInput {
  notification_id_list: string[];
  shop_id?: string | null;
}

/**
 * setUserAccountEmailReceptionAgreed의 입력
 */
export interface SetUserAccountEmailReceptionAgreedInput {
  agreed: boolean;
}

/**
 * setUserAccountNightlyAdNotiAgreedInput 의 입력
 */
export interface SetUserAccountNightlyAdNotiAgreedInput {
  agreed: boolean;
}

/**
 * setUserAccountSmsReceptionAgreed의 입력
 */
export interface SetUserAccountSmsReceptionAgreedInput {
  agreed: boolean;
}

/**
 * setUserNotificationPushAgreed의 입력
 */
export interface SetUserNotificationPushAgreedInput {
  category: UserNotificationCategory;
  agreed: boolean;
}

/**
 * updateUserAdNotiStatus의 입력
 */
export interface UpdateUserAdNotiStatusInput {
  uuid?: string | null;
  status: UserNotiStatus;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
