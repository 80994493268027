import { ReactNode, useEffect } from 'react';

import { likeMetaSubscribe } from './likeMetaSubscribe';
import { visiblitySubscribe } from './visiblitySubscribe';

export function LikeSubject({ children }: { children: ReactNode }) {
  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }
    const unsubscribeLikeMeta = likeMetaSubscribe();
    const unsubscribeVisiblity = visiblitySubscribe();
    return () => {
      unsubscribeLikeMeta();
      unsubscribeVisiblity();
    };
  }, []);
  return children as JSX.Element;
}
