import React from 'react';

export function BoxKITTOIcon({ width = 20, height = 20, ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 20 20' {...props}>
      <g id='BoxKITTO'>
        <path
          id='Vector'
          d='M15.5569 1.6665H4.44575C2.91162 1.6665 1.66797 2.91016 1.66797 4.44428V15.5554C1.66797 17.0895 2.91162 18.3332 4.44575 18.3332H15.5569C17.091 18.3332 18.3346 17.0895 18.3346 15.5554V4.44428C18.3346 2.91016 17.091 1.6665 15.5569 1.6665Z'
          fill='#2B3446'
        />
        <path
          id='Vector_2'
          d='M5.5 14.7585C5.50938 13.0519 5.51407 11.3491 5.51407 9.65226C5.51407 9.08787 5.51172 8.40939 5.50703 7.61439C5.50234 6.82061 5.5 6.02682 5.5 5.23547H9.27095C9.27095 5.87996 9.26861 6.63855 9.26392 7.51123C9.25923 8.38391 9.25689 9.22017 9.25689 10.0212C9.25689 11.2775 9.26158 12.8565 9.27095 14.7573L5.5 14.7585ZM15.4988 14.0059L11.8392 15C11.0879 13.6248 10.1454 11.9681 9.00955 10.0285V10.014C9.1971 9.66804 9.53704 9.02233 10.0294 8.07562C10.5217 7.13011 11.0328 6.1045 11.5649 5L15.1565 6.04503C14.6982 6.85095 14.0195 8.11567 13.1216 9.84039L13.1263 9.86224C14.0734 11.5372 14.8647 12.9172 15.5 14.0047L15.4988 14.0059Z'
          fill='#D1A0FF'
        />
      </g>
    </svg>
  );
}
