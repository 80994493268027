import ic_back_src from './ic_back.svg';
export const ic_back = ic_back_src;
import ic_comment_src from './ic_comment.png';
export const ic_comment = ic_comment_src;
import ic_coupon_src from './ic_coupon.png';
export const ic_coupon = ic_coupon_src;
import ic_delete_src from './ic_delete.svg';
export const ic_delete = ic_delete_src;
import ic_delete_disagled_src from './ic_delete_disabled.svg';
export const ic_delete_disagled = ic_delete_disagled_src;
import ic_empty_src from './ic_empty.svg';
export const ic_empty = ic_empty_src;
import ic_notification_src from './ic_notification.svg';
export const ic_notification = ic_notification_src;
import ic_point_src from './ic_point.png';
export const ic_point = ic_point_src;
import ic_select_all_src from './ic_select_all.svg';
export const ic_select_all = ic_select_all_src;
import ic_welcome_coupon_src from './ic_welcome_coupon.png';
export const ic_welcome_coupon = ic_welcome_coupon_src;
import ic_zigzag_src from './ic_zigzag.png';
export const ic_zigzag = ic_zigzag_src;
export { default as EpickIcon } from './epickIcon';
