/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */
import { UxGoodsCardItemForVerticalProductCard } from '../../vertical-product-card/types';
import { GridContainerProps } from '../Factory';

export function extractProps<D extends UxGoodsCardItemForVerticalProductCard, P extends {}>(
  props: GridContainerProps<D, P>,
) {
  const commonPropKeys = [
    'defaultColumnCount',
    'defaultSize',
    'columnCount',
    'columnStyle',
    'banner',
    'items',
    'onEndReached',
  ] as const; // TODO: 나중에 nextjs 버전 업으로 인해 typescript satisfies를 쓸 수 있게 되면 다음과 같이 변경 `satisfies Array<keyof typeof props>`

  const commonProps = {} as any;
  const otherProps = {} as any;

  for (const key in props) {
    if (commonPropKeys.includes(key as (typeof commonPropKeys)[number])) {
      commonProps[key] = props[key as keyof typeof props];
    } else {
      otherProps[key] = props[key as keyof typeof props];
    }
  }

  return {
    commonProps,
    otherProps,
  } as {
    commonProps: Pick<typeof props, (typeof commonPropKeys)[number]>;
    otherProps: P;
  };
}
