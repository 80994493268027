/* eslint-disable @typescript-eslint/ban-types */
import { forwardRef, ReactNode, RefAttributes, useMemo } from 'react';
import { css } from '@emotion/react';

import { UxGoodsCardItemForVerticalProductCard } from '../vertical-product-card/types';
import * as cssVariables from './constants/cssVariables';
import { GridContainerCotextType } from './types';

interface ItemContentContainerProps<
  D extends UxGoodsCardItemForVerticalProductCard = UxGoodsCardItemForVerticalProductCard,
  P extends {} = {},
> {
  items: D[];
  parentIndex: number;
  context: GridContainerCotextType<D, P>;
  renderProductCard: (item: D, itemIndex: number, context: GridContainerCotextType<D, P>) => ReactNode;
}

export const ItemContentContainer = forwardRef<HTMLDivElement, ItemContentContainerProps>((props, ref) => {
  const { items, parentIndex, context, renderProductCard } = props;

  const { columnCount, banner } = context;

  // useMemo로 render 함수를 만들어서 처리하는게 안티패턴(?)인지 애매하지만 일단 여기서는 써도 될 것 같아서 적용했습니다.
  // 이슈가 있으면 별도 컴포넌트로 분리해서 banner, items, parentIndex를 props로 넘겨주는 방식으로 변경해야 할 것 같습니다.
  const renderBanner = useMemo(() => {
    return banner && banner(items, parentIndex);
  }, [banner, items, parentIndex]);

  const hasBanner = !!banner && !!renderBanner;

  return (
    <>
      <div css={rowCss} ref={ref}>
        {items.map((item, index) => {
          if (!item) return null;

          const itemIndex = parentIndex * columnCount + index;

          return (
            <div css={colCss} key={itemIndex}>
              {renderProductCard(item, itemIndex, context)}
            </div>
          );
        })}
      </div>
      {hasBanner && (
        <div css={rowCss}>
          <div css={bannerWrapperCss}>{renderBanner}</div>
        </div>
      )}
    </>
  );
}) as <D extends UxGoodsCardItemForVerticalProductCard, P extends {}>(
  props: ItemContentContainerProps<D, P> & RefAttributes<HTMLDivElement>,
) => JSX.Element;

const colCss = css`
  width: 100%;
  max-width: calc(100% / var(${cssVariables.column}));
  box-sizing: border-box;

  padding: 0 1px;
`;

const rowCss = css`
  .basic &,
  .content & {
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-right: -1px;
  }

  display: flex;
  margin-bottom: 24px;
`;

const bannerWrapperCss = css`
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box;
`;
