import { createContext, RefObject, useCallback, useContext, useRef } from 'react';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

import {
  createResizeObserverStore,
  ResizeObserverListenerRect,
  ResizeObserverStore,
} from './createResizeObserverStore';

export interface CreateResizeObserverContextOptions {}

export function createResizeObserverContext() {
  interface ResizeObserverContextType extends ResizeObserverStore {
    defaultWindowWidth: number;
  }

  interface ResizeObserverProviderProps {
    defaultWindowWidth: number;
    children: React.ReactNode;
  }

  const ResizeObserverContext = createContext<ResizeObserverContextType | null>(null);

  const Provider = (props: ResizeObserverProviderProps) => {
    const { defaultWindowWidth, ...restProps } = props;
    const storeRef = useRef<ResizeObserverStore | null>(null);

    if (!storeRef.current && typeof window !== 'undefined') {
      storeRef.current = createResizeObserverStore();
    }

    return (
      <ResizeObserverContext.Provider
        value={{ ...(storeRef.current as ResizeObserverStore), defaultWindowWidth }}
        {...restProps}
      />
    );
  };

  Provider.displayName = 'ResizeObserverProvider';

  return {
    Provider,
    useStore,
    useSelector,
  };

  function useStore() {
    const store = useContext(ResizeObserverContext);
    if (!store) {
      throw new Error('ResizeObserverContext is not provided');
    }
    return store;
  }

  function useSelector<S>(
    element: RefObject<HTMLElement>,
    selector: (rect: ResizeObserverListenerRect | null, windowWidth: number) => S,
    serverSelector?: (rect: ResizeObserverListenerRect | null, windowWidth: number) => S,
  ): ReturnType<typeof selector> {
    const store = useStore();
    const rectRef = useRef<ResizeObserverListenerRect | null>(null);

    const snapshot = useSyncExternalStore(
      useCallback(
        (onStoreChange) => {
          const emptyFn = () => {
            return () => {};
          };

          if (!element.current) {
            return emptyFn;
          }
          const unsubscribe = store.subscribe(element.current, (rect) => {
            rectRef.current = rect;
            onStoreChange();
          });
          return unsubscribe;
        },
        [element, store],
      ),
      useCallback(() => {
        return selector(rectRef.current, window.innerWidth);
      }, [selector]),
      useCallback(() => {
        if (!serverSelector) {
          return selector(rectRef.current, store.defaultWindowWidth);
        }
        return serverSelector(rectRef.current, store.defaultWindowWidth);
      }, [store, selector, serverSelector]),
    );

    return snapshot;
  }
}

export type ResizeObserverContext = ReturnType<typeof createResizeObserverContext>;
