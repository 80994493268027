import getConfig from 'next/config';

/**
 * 중복해서 불러오는 상황을 방지할 수 있도록, 동시에 1번만 로더 함수를 호출할 수 있도록 Promise를
 * 합쳐주는 함수를 생성합니다.
 * 기본적으로 한 번 로더 함수가 실행되고 반환된 Promise를 계속 기억해주는 기능을 가지고 있습니다.
 * 로더 함수가 resolve하고 나면, 해당 값은 계속해서 기억되어 사용됩니다.
 * 로더 함수가 reject하면, 기억된 Promise를 지우고 다음 번 함수를 호출할 때에는 새로 로더 함수를
 * 호출합니다.
 */
function createLoader<TArgs extends unknown[], TValue>(
  loadFunc: (...args: TArgs) => Promise<TValue>,
): (...args: TArgs) => Promise<TValue> {
  let ongoing_promise: Promise<TValue> | null = null;
  return (...args) => {
    if (ongoing_promise != null) {
      return ongoing_promise;
    }
    ongoing_promise = loadFunc(...args);
    ongoing_promise.catch(() => {
      ongoing_promise = null;
    });
    return ongoing_promise;
  };
}

/**
 * 카카오 API (Kakao)를 비동기로 불러와서 반환합니다. 이 함수의 실행이 끝난 뒤에는
 * window.Kakao를 사용할 수 있게 됩니다.
 *
 * @see https://developers.kakao.com/sdk/reference/js/release/Kakao.html
 */
export const getKakaoAPI = createLoader((): Promise<any> => {
  const { publicRuntimeConfig } = getConfig();
  const { kakao_app_key } = publicRuntimeConfig.config;

  const window_any = window as any;
  if (window_any.Kakao != null) {
    const Kakao = window_any.Kakao;
    if (!Kakao.isInitialized()) {
      Kakao.init(kakao_app_key);
    }
    return Promise.resolve(window_any.Kakao);
  }
  return new Promise((resolve, reject) => {
    const script_elem = document.createElement('script');
    script_elem.onerror = (e) => reject(e);
    script_elem.onload = () => {
      const Kakao = window_any.Kakao;
      if (Kakao == null) {
        reject(new Error('카카오 API를 불러올 수 없습니다.'));
      }
      Kakao.init(kakao_app_key);
      resolve(Kakao);
    };
    script_elem.crossOrigin = 'anonymous';
    script_elem.integrity = 'sha384-x+WG2i7pOR+oWb6O5GV5f1KN2Ko6N7PTGPS7UlasYWNxZMKQA63Cj/B2lbUmUfuC';
    script_elem.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.2.0/kakao.min.js';
    document.body.appendChild(script_elem);
  });
});
