export const brand_color = '#FF349C';

export const Static_Black = '#000000';
export const Static_White = '#FFFFFF';
export const Static_Purple = '#7463F2';

export const Gray_Light_030 = '#F5F6F8';
export const Gray_Light_050 = '#FAFAFB';
export const Gray_Light_100 = '#F5F6F8';
export const Gray_Light_150 = '#E8E9EB';
export const Gray_Light_200 = '#D2D5D6';
export const Gray_Light_300 = '#B8BFC2';
export const Gray_Light_400 = '#A1A9AD';
export const Gray_Light_500 = '#828A8F';
export const Gray_Light_600 = '#697175';
export const Gray_Light_700 = '#4F5558';
export const Gray_Light_800 = '#34383B';
export const Gray_Light_900 = '#242729';
export const Gray_Light_950 = '#121314';

export const Gray_Dark_030 = '#1A1A1A';
export const Gray_Dark_050 = '#1F2123';
export const Gray_Dark_100 = '#222526';
export const Gray_Dark_150 = '#2F3234';
export const Gray_Dark_200 = '#414547';
export const Gray_Dark_300 = '#585C5E';
export const Gray_Dark_400 = '#717678';
export const Gray_Dark_500 = '#888E90';
export const Gray_Dark_600 = '#A3A9AC';
export const Gray_Dark_700 = '#C7CACC';
export const Gray_Dark_800 = '#D9DCDE';
export const Gray_Dark_900 = '#F5F6F8';
export const Gray_Dark_950 = '#FAFAFB';

export const Pink_Light_050 = '#FEF6FD';
export const Pink_Light_100 = '#FFEBFC';
export const Pink_Light_150 = '#FFD1F7';
export const Pink_Light_200 = '#FFB2F3';
export const Pink_Light_300 = '#FF8AEB';
export const Pink_Light_400 = '#FA6EE3';
export const Pink_Light_500 = '#E560CF';
export const Pink_Light_600 = '#BF49AB';
export const Pink_Light_700 = '#913382';
export const Pink_Light_800 = '#6D2261';
export const Pink_Light_900 = '#4F1245';

export const Pink_Dark_050 = '#4A1341';
export const Pink_Dark_100 = '#65215A';
export const Pink_Dark_150 = '#853078';
export const Pink_Dark_200 = '#AF449D';
export const Pink_Dark_300 = '#D159BD';
export const Pink_Dark_400 = '#FA6EE3';
export const Pink_Dark_500 = '#F384E1';
export const Pink_Dark_600 = '#F3ABE8';
export const Pink_Dark_700 = '#F3C8EC';
export const Pink_Dark_800 = '#F3DBEF';
export const Pink_Dark_900 = '#F2E7F0';

export const Purple_Light_050 = '#F5F5FF';
export const Purple_Light_100 = '#E8E6FF';
export const Purple_Light_150 = '#D7D4FF';
export const Purple_Light_200 = '#C1BAFF';
export const Purple_Light_300 = '#A89FFF';
export const Purple_Light_400 = '#8F82FF';
export const Purple_Light_500 = '#7463F2';
export const Purple_Light_600 = '#604EDF';
export const Purple_Light_700 = '#4B3ABF';
export const Purple_Light_800 = '#392C99';
export const Purple_Light_900 = '#29206E';

export const Purple_Dark_050 = '#271F65';
export const Purple_Dark_100 = '#352A86';
export const Purple_Dark_150 = '#4436A8';
export const Purple_Dark_200 = '#5647C2';
export const Purple_Dark_300 = '#6759D2';
export const Purple_Dark_400 = '#8F82FF';
export const Purple_Dark_500 = '#A198F3';
export const Purple_Dark_600 = '#B8B2F3';
export const Purple_Dark_700 = '#CECAF3';
export const Purple_Dark_800 = '#DEDCF3';
export const Purple_Dark_900 = '#EAEAF3';

export const Beige_Light_100 = '#F1ECEA';

export const Red_Light_100 = '#FFCDCC';
export const Red_Light_200 = '#FF4D4D';
export const Red_Light_300 = '#C31E18';
export const Red_Light_400 = '#9C1510';

export const Red_Dark_100 = '#EDBFBE';
export const Red_Dark_200 = '#E84747';
export const Red_Dark_300 = '#B21E18';
export const Red_Dark_400 = '#8F1511';

export const Yellow_Light_100 = '#FFDCAD';
export const Yellow_Light_200 = '#FFC274';
export const Yellow_Light_300 = '#FFA93B';
export const Yellow_Light_400 = '#F5833D';

export const Yellow_Dark_100 = '#EDCCA2';
export const Yellow_Dark_200 = '#EDB56D';
export const Yellow_Dark_300 = '#ED9D38';
export const Yellow_Dark_400 = '#E37B3A';

export const Green_Light_100 = '#80D889';
export const Green_Light_200 = '#5AC366';
export const Green_Light_300 = '#44944E';
export const Green_Light_400 = '#36753D';

export const Green_Dark_100 = '#71BB78';
export const Green_Dark_200 = '#51AA5B';
export const Green_Dark_300 = '#3E8246';
export const Green_Dark_400 = '#326838';

export const Brown_Light_100 = '#F2CEAA';
export const Brown_Light_200 = '#DEA66F';
export const Brown_Light_300 = '#CC884B';
export const Brown_Light_400 = '#8F4E18';

export const Brown_Dark_100 = '#DDBC9B';
export const Brown_Dark_200 = '#CA9867';
export const Brown_Dark_300 = '#BA7D46';
export const Brown_Dark_400 = '#7D4618';

export const Lavender_Light_100 = '#EBC7FC';
export const Lavender_Light_200 = '#E1AEF9';
export const Lavender_Light_300 = '#CD99F9';
export const Lavender_Light_400 = '#B984F9';

export const Lavender_Dark_100 = '#CCADDA';
export const Lavender_Dark_200 = '#C398D7';
export const Lavender_Dark_300 = '#B286D7';
export const Lavender_Dark_400 = '#A174D7';

export const Sky_Blue_Light_100 = '#B6D6FA';
export const Sky_Blue_Light_200 = '#98C7FA';
export const Sky_Blue_Light_300 = '#80B7F4';
export const Sky_Blue_Light_400 = '#65A7F0';

export const Sky_Blue_Dark_100 = '#A4C5EB';
export const Sky_Blue_Dark_200 = '#8DB6E4';
export const Sky_Blue_Dark_300 = '#76A7DE';
export const Sky_Blue_Dark_400 = '#5A91D0';

const light_colors = {
  white: '#FFFFFF',
  black: '#000000',

  Zigzag_Black: '#000000',
  Zigzag_Pink: '#FF349C',
  Zigzag_Beige: '#F1ECEA',
  Static_Black,
  Static_White,
  Static_Purple,
  Kakao_Yellow: '#FEE500',

  Gray_030: Gray_Light_030,
  Gray_050: Gray_Light_050,
  Gray_100: Gray_Light_100,
  Gray_150: Gray_Light_150,
  Gray_200: Gray_Light_200,
  Gray_300: Gray_Light_300,
  Gray_400: Gray_Light_400,
  Gray_500: Gray_Light_500,
  Gray_600: Gray_Light_600,
  Gray_700: Gray_Light_700,
  Gray_800: Gray_Light_800,
  Gray_900: Gray_Light_900,
  Gray_950: Gray_Light_950,

  Pink_050: Pink_Light_050,
  Pink_100: Pink_Light_100,
  Pink_150: Pink_Light_150,
  Pink_200: Pink_Light_200,
  Pink_300: Pink_Light_300,
  Pink_400: Pink_Light_400,
  Pink_500: Pink_Light_500,
  Pink_600: Pink_Light_600,
  Pink_700: Pink_Light_700,
  Pink_800: Pink_Light_800,
  Pink_900: Pink_Light_900,

  Purple_050: Purple_Light_050,
  Purple_100: Purple_Light_100,
  Purple_150: Purple_Light_150,
  Purple_200: Purple_Light_200,
  Purple_300: Purple_Light_300,
  Purple_400: Purple_Light_400,
  Purple_500: Purple_Light_500,
  Purple_600: Purple_Light_600,
  Purple_700: Purple_Light_700,
  Purple_800: Purple_Light_800,
  Purple_900: Purple_Light_900,

  Red_100: Red_Light_100,
  Red_200: Red_Light_200,
  Red_300: Red_Light_300,
  Red_400: Red_Light_400,

  Yellow_100: Yellow_Light_100,
  Yellow_200: Yellow_Light_200,
  Yellow_300: Yellow_Light_300,
  Yellow_400: Yellow_Light_400,

  Green_100: Green_Light_100,
  Green_200: Green_Light_200,
  Green_300: Green_Light_300,
  Green_400: Green_Light_400,

  Brown_100: Brown_Light_100,
  Brown_200: Brown_Light_200,
  Brown_300: Brown_Light_300,
  Brown_400: Brown_Light_400,

  Lavender_100: Lavender_Light_100,
  Lavender_200: Lavender_Light_200,
  Lavender_300: Lavender_Light_300,
  Lavender_400: Lavender_Light_400,

  Sky_Blue_100: Sky_Blue_Light_100,
  Sky_Blue_200: Sky_Blue_Light_200,
  Sky_Blue_300: Sky_Blue_Light_300,
  Sky_Blue_400: Sky_Blue_Light_400,
} as const;

export const dark_colors = {
  white: '#000000',
  black: '#ffffff',

  Zigzag_Black: '#FFFFFF',
  Zigzag_Pink: '#FA6EE3',
  Zigzag_Beige: '#222526',
  Static_Black,
  Static_White,
  Static_Purple,
  Kakao_Yellow: '#FEE500',

  Gray_030: Gray_Dark_030,
  Gray_050: Gray_Dark_050,
  Gray_100: Gray_Dark_100,
  Gray_150: Gray_Dark_150,
  Gray_200: Gray_Dark_200,
  Gray_300: Gray_Dark_300,
  Gray_400: Gray_Dark_400,
  Gray_500: Gray_Dark_500,
  Gray_600: Gray_Dark_600,
  Gray_700: Gray_Dark_700,
  Gray_800: Gray_Dark_800,
  Gray_900: Gray_Dark_900,
  Gray_950: Gray_Dark_950,

  Pink_050: Pink_Dark_050,
  Pink_100: Pink_Dark_100,
  Pink_150: Pink_Dark_150,
  Pink_200: Pink_Dark_200,
  Pink_300: Pink_Dark_300,
  Pink_400: Pink_Dark_400,
  Pink_500: Pink_Dark_500,
  Pink_600: Pink_Dark_600,
  Pink_700: Pink_Dark_700,
  Pink_800: Pink_Dark_800,
  Pink_900: Pink_Dark_900,

  Purple_050: Purple_Dark_050,
  Purple_100: Purple_Dark_100,
  Purple_150: Purple_Dark_150,
  Purple_200: Purple_Dark_200,
  Purple_300: Purple_Dark_300,
  Purple_400: Purple_Dark_400,
  Purple_500: Purple_Dark_500,
  Purple_600: Purple_Dark_600,
  Purple_700: Purple_Dark_700,
  Purple_800: Purple_Dark_800,
  Purple_900: Purple_Dark_900,

  Red_100: Red_Dark_100,
  Red_200: Red_Dark_200,
  Red_300: Red_Dark_300,
  Red_400: Red_Dark_400,

  Yellow_100: Yellow_Dark_100,
  Yellow_200: Yellow_Dark_200,
  Yellow_300: Yellow_Dark_300,
  Yellow_400: Yellow_Dark_400,

  Green_100: Green_Dark_100,
  Green_200: Green_Dark_200,
  Green_300: Green_Dark_300,
  Green_400: Green_Dark_400,

  Brown_100: Brown_Dark_100,
  Brown_200: Brown_Dark_200,
  Brown_300: Brown_Dark_300,
  Brown_400: Brown_Dark_400,

  Lavender_100: Lavender_Dark_100,
  Lavender_200: Lavender_Dark_200,
  Lavender_300: Lavender_Dark_300,
  Lavender_400: Lavender_Dark_400,

  Sky_Blue_100: Sky_Blue_Dark_100,
  Sky_Blue_200: Sky_Blue_Dark_200,
  Sky_Blue_300: Sky_Blue_Dark_300,
  Sky_Blue_400: Sky_Blue_Dark_400,
} as const;

export const light_theme_colors = {
  ...light_colors,
  bg_base: light_colors.Gray_030,
  bg_surface: light_colors.white,
  bg_elevated: light_colors.white,

  pressed_dark: `${light_colors.black}05`,
  pressed_light: `${light_colors.white}10`,
} as const;

export const dark_theme_colors = {
  ...dark_colors,
  bg_base: dark_colors.white,
  bg_surface: dark_colors.Gray_030,
  bg_elevated: dark_colors.Gray_050,

  pressed_dark: `${dark_colors.black}10`,
  pressed_light: `${dark_colors.white}05`,
} as const;

export type Colors = typeof light_theme_colors | typeof dark_theme_colors;
