import React, { useMemo } from 'react';
import { IconCheckRegular32 } from '@croquiscom/zds';
import { vars } from '@croquiscom-pvt/zds/tokens';
import { css, SerializedStyles } from '@emotion/react';

import { Gray_Light_400, useBrandStyle } from '@common/styles';

import { IconCheckLargeNormal } from './svg/IconCheckLargeNormal';
import { IconCheckLargeSelected } from './svg/IconCheckLargeSelected';
import { IconCheckLine } from './svg/IconCheckLine';
import { IconCheckSmallNormal } from './svg/IconCheckSmallNormal';
import { IconCheckSmallSelected } from './svg/IconCheckSmallSelected';

export enum CheckType {
  SOLID = 'SOLID',
  SOLID_SMALL = 'SOLID_SMALL',
  LINE = 'LINE',
  REGULAR = 'REGULAR',
  LARGE = 'LARGE',
}

export interface CheckBoxProps extends React.HTMLAttributes<HTMLInputElement> {
  css?: SerializedStyles;
  check_type?: CheckType;
  checked?: boolean;
  disabled?: boolean;
  size?: number;
  color?: string;
  checkEvent?: (checked: boolean) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  children,
  className,
  css: custom_css,
  check_type = CheckType.SOLID,
  checked = false,
  disabled = false,
  size,
  color,
  checkEvent = () => undefined,
  ...otherProps
}) => {
  const { brand_color } = useBrandStyle();
  const checkbox_icon = useMemo(() => {
    switch (check_type) {
      case CheckType.SOLID:
        if (checked) {
          return <IconCheckLargeSelected color={color || brand_color.Brand_400} disabled={disabled} />;
        }
        return <IconCheckLargeNormal disabled={disabled} />;
      case CheckType.SOLID_SMALL:
        if (checked) {
          return <IconCheckSmallSelected color={color || brand_color.Brand_400} disabled={disabled} />;
        }
        return <IconCheckSmallNormal disabled={disabled} />;
      case CheckType.LINE:
        return <IconCheckLine size={size} color={color || brand_color.Brand_400} disabled={!checked || disabled} />;
      case CheckType.REGULAR:
        return (
          <IconCheckRegular32
            size={16}
            color={!checked || disabled ? vars.color.iconTertiary : brand_color.Brand_400}
          />
        );
      case CheckType.LARGE:
        return <IconCheckRegular32 size={size} color={!checked || disabled ? Gray_Light_400 : brand_color.Brand_400} />;
      default:
        return null;
    }
  }, [check_type, checked, disabled, size, color, brand_color.Brand_400]);

  const checkbox_input_size = useMemo(() => {
    if (size) {
      return css`
        width: ${size}px;
        height: ${size}px;
      `;
    }
    switch (check_type) {
      case CheckType.SOLID_SMALL:
      case CheckType.REGULAR:
        return css`
          width: 16px;
          height: 16px;
        `;
      default:
        return css`
          width: 24px;
          height: 24px;
        `;
    }
  }, [check_type, size]);

  return (
    <label
      className={className}
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;

        ${custom_css}
      `}
    >
      <input
        type='checkbox'
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          ${checkbox_input_size}
        `}
        checked={checked}
        disabled={disabled}
        onChange={(e) => checkEvent(e.currentTarget.checked)}
        {...otherProps}
      />
      {checkbox_icon}
      {children}
    </label>
  );
};
