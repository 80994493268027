import { useCallback } from 'react';

import { CurrencyMeta } from './graphql/currency_types.2';
import { generateDisplayPriceWithCurrencyFunc } from './currency_utils';

/**
 * 주어진 currency meta를 이용하여,
 * 원화 가격을 받아 통화별 심볼과 천단위 콤마를 추가해 string으로 반환하는 함수를 생성한다.
 * @param currency_meta 표기할 CurrencyMeta 정보
 */
export const useDisplayPriceWithCurrencyMeta = (currency_meta: CurrencyMeta) => {
  const displayPriceWithCurrency = useCallback(generateDisplayPriceWithCurrencyFunc(currency_meta), [currency_meta]);
  return { displayPriceWithCurrency };
};
