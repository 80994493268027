import { callZpayFunction } from '../../callZpayFunction';

// 브라우저간 메시지 전송
// 지원 브라우저 : Z결제 브라우저, Product Detail(내재화된 상품 상세)
// - window.postMessage() 와 동작 & 정책 동일
// - message : String 타입
// - targetOrigin : String 타입.
//   - * 인 경우 모든 웹뷰에 zpayHandler.onMessage 호출
//   - url 입력 시 scheme, host, port가 일치하는 웹뷰에만 zpay.onMessage 호출
export const sendPostMessage = <M>(message: M, targetOrigin = '*') => {
  const strMessage = JSON.stringify(message);
  callZpayFunction('postMessage')({ message: strMessage, targetOrigin });
};
