import { useMemo } from 'react';
import { PrimitiveAtom, useAtomValue } from 'jotai';
import { selectAtom, useUpdateAtom } from 'jotai/utils';

interface Config {
  catalog_product_id: string;
  selected_product_list: PrimitiveAtom<Set<string>>;
}

export const useThumbnailCheckbox = ({ catalog_product_id, selected_product_list }: Config) => {
  const is_selected_atom = useMemo(() => {
    return selectAtom(selected_product_list, (lookup) => lookup.has(catalog_product_id));
  }, [catalog_product_id, selected_product_list]);
  const select = useUpdateAtom(selected_product_list);
  const is_selected = useAtomValue(is_selected_atom);
  return { is_selected, select };
};
