/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { css } from '@emotion/react';
import { ClassNames } from '@emotion/react';

import { DEFAULT_COLUMN_COUNT, DEFAULT_SIZE } from '../grid-container/gridLayoutManager';
import { useCalculatedColumnCount } from '../grid-container/hooks/useCalculatedColumnCount';
import { useDividedItemsWithColumnCount } from '../grid-container/hooks/useDividedItemsWithColumnCount';
import { useSize } from '../grid-container/hooks/useSize';
import { getWrapperCss } from '../grid-container/utils/getWrapperCss';
import { UxGoodsCardItemForVerticalProductCard } from '../vertical-product-card/types';
import * as cssVariables from './constants/cssVariables';
import { extractProps } from './utils/extractProps';
import { ItemContentContainer, ItemContentContainerProps } from './ItemContentContainer';
import { VirtuosoContextType, VirtuosoGridContainerProps, VirtuosoPropsType } from './types';

export interface CreateVirtuosoGridContainerOptions<D extends UxGoodsCardItemForVerticalProductCard, P extends {}> {
  renderProductCard: ItemContentContainerProps<D, P>['renderProductCard'];
}

const MemoizedItemContentContainer = React.memo(ItemContentContainer) as <
  D extends UxGoodsCardItemForVerticalProductCard,
  P extends {},
>(
  props: ItemContentContainerProps<D, P>,
) => JSX.Element;

export function createVirtuosoGridContainer<P extends {}, D extends UxGoodsCardItemForVerticalProductCard>(
  options: CreateVirtuosoGridContainerOptions<D, P>,
) {
  const { renderProductCard } = options;

  const components: VirtuosoPropsType<D, P>['components'] = {
    Item: ({ item, context, ...props }) => <div css={itemWrapperCss} {...props} />,
  };

  const getComputeItemKey: VirtuosoPropsType<D, P>['computeItemKey'] = (index, item) => {
    let key = `${index}`;
    item.forEach((item) => {
      key += `-${item?.catalog_product_id}`;
    });
    return key;
  };

  const itemContent: VirtuosoPropsType<D, P>['itemContent'] = (parentIndex, items, context) => {
    return (
      <MemoizedItemContentContainer
        parentIndex={parentIndex}
        items={items}
        context={context}
        renderProductCard={renderProductCard}
      />
    );
  };

  const Container = (props: VirtuosoGridContainerProps<D, P>) => {
    const { commonProps, otherProps } = extractProps(props);
    const {
      defaultColumnCount = DEFAULT_COLUMN_COUNT,
      defaultSize = DEFAULT_SIZE,
      columnCount = 'auto',
      columnStyle = 'basic',
      items,
      banner,
      computeItemKey,
      onEndReached,
      ...restProps
    } = commonProps;

    const calculatedColumnCount = useCalculatedColumnCount({ columnCount, defaultColumnCount });
    const size = useSize({ columnStyle, calculatedColumnCount, defaultSize });
    const diviededItems = useDividedItemsWithColumnCount(items, calculatedColumnCount);

    const context: VirtuosoContextType<D, P> = {
      size,
      columnCount: calculatedColumnCount,
      columnStyle,
      banner,
      otherProps,
    };

    return (
      <ClassNames>
        {({ cx, css }) => {
          const wrapperCss = getWrapperCss(css, cssVariables, columnCount);
          return (
            <div className={cx([wrapperCss, columnStyle])}>
              <Virtuoso
                context={context}
                components={components}
                data={diviededItems}
                computeItemKey={computeItemKey ?? getComputeItemKey}
                itemContent={itemContent}
                endReached={onEndReached}
                {...restProps}
              />
            </div>
          );
        }}
      </ClassNames>
    );
  };

  Container.displayName = 'VirtuosoGridContainer';

  return Container;
}

const itemWrapperCss = css`
  display: flex;
  flex-wrap: wrap;
`;
