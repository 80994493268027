import { callZigzagFunction } from '../../callZigzagFunction';
import { zigzagHandler } from '../../handlers';

// 즐겨찾기 shop_id 목록
// v6.62.0 이상
export const gssl = () => {
  return new Promise<string[]>(resolve => {
    callZigzagFunction('gssl')();
    zigzagHandler('onSavedShopList', shop_id_list => {
      resolve(shop_id_list);
    });
  });
};
