import useBrowserEffect from 'hooks/useBrowserEffect';

import { isDomesticShippingUseCookie } from 'util/address_utils';
import { isZigzag } from 'util/device';

/**
 * 지그재그 국내 웹에서만 PageView 전송
 * fbq: Facebook Pixel
 */
export const useTrackingPageView = () => {
  useBrowserEffect(() => {
    try {
      if (!isZigzag() && window.fbq !== undefined && isDomesticShippingUseCookie()) {
        window.fbq('track', 'PageView');
      }
    } catch (error) {
      // ignore error
    }
  }, []);
};
