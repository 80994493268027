import { callZigzagFunction } from '../../callZigzagFunction';
import { zigzagHandler } from '../../handlers';

// 카카오링크 전송 가능여부 확인
export const ckla = () => {
  return new Promise<boolean>(resolve => {
    callZigzagFunction('ckla')();
    zigzagHandler('onKakaoLinkAvailable', kakaoLinkAvailable => {
      resolve(kakaoLinkAvailable);
    });
  });
};
