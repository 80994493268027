import { getRuntimeConfigValue } from '@common/next-runtime-config';

export enum BrowserId {
  service_terms = 'service',
  consent_privacy = 'consent_privacy',
  marketing_agreement = 'marketing_agreement',
  nightly_ad_notification = 'nightly_ad_notification',
  privacy_third_party = 'privacy_third_party',
  consent_privacy_self_certificate = 'consent_privacy_self_certificate',
  privacy_third_party_self_certificate = 'privacy_third_party_self_cretificate',
  consent_privacy_student_present_proof = 'consent_privacy_student_present_proof',
  privacy_third_party_student_present_proof = 'privacy_third_party_student_present_proof',
}

export const BrowserTitle: Record<BrowserId, string> = {
  [BrowserId.service_terms]: '서비스 이용약관',
  [BrowserId.consent_privacy]: '개인정보 수집이용',
  [BrowserId.marketing_agreement]: '홍보 및 마케팅 이용',
  [BrowserId.nightly_ad_notification]: '이용자 야간혜택 수신 알림 동의',
  [BrowserId.privacy_third_party]: '마케팅 개인정보 제3자 제공 동의',
  /** 본인인증 */
  [BrowserId.consent_privacy_self_certificate]: '개인정보 수집 및 이용 동의',
  [BrowserId.privacy_third_party_self_certificate]: '개인정보 제 3자 제공 동의',
  /** 톡학생증 */
  [BrowserId.consent_privacy_student_present_proof]: '개인정보 수집 및 이용 동의',
  [BrowserId.privacy_third_party_student_present_proof]: '개인정보 제 3자 제공 동의',
};

const app_url = getRuntimeConfigValue('appUrl') as string;
const isAlpha = app_url?.includes('alpha') || app_url?.includes('dev-');

export const getBrowserHref = (browserId: BrowserId) =>
  isAlpha
    ? `https://cf.res.s.alpha.zigzag.kr/zigzag/terms/user/ko/${browserId}.html`
    : `https://cf.res.s.zigzag.kr/zigzag/terms/user/ko/${browserId}.html`;
