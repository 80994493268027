import {
  Gray_Dark_030,
  Gray_Dark_050,
  Gray_Dark_100,
  Gray_Dark_150,
  Gray_Dark_200,
  Gray_Dark_300,
  Gray_Dark_400,
  Gray_Dark_500,
  Gray_Dark_600,
  Gray_Dark_700,
  Gray_Dark_800,
  Gray_Dark_900,
  Gray_Dark_950,
  Gray_Dark_Black,
  Gray_Light_050,
  Gray_Light_100,
  Gray_Light_150,
  Gray_Light_200,
  Gray_Light_300,
  Gray_Light_400,
  Gray_Light_500,
  Gray_Light_600,
  Gray_Light_700,
  Gray_Light_800,
  Gray_Light_900,
  Gray_Light_950,
  Gray_Light_Black,
  Gray_Light_White,
} from './colors';

export interface ThemeColorType {
  theme: 'light' | 'dark';
  white: string;
  black: string;
  Gray_Light_050: string;
  Gray_Light_100: string;
  Gray_Light_150: string;
  Gray_Light_200: string;
  Gray_Light_300: string;
  Gray_Light_400: string;
  Gray_Light_500: string;
  Gray_Light_600: string;
  Gray_Light_700: string;
  Gray_Light_800: string;
  Gray_Light_900: string;
  Gray_Light_950: string;
}

export const ThemeColorMap: Record<'light' | 'dark', ThemeColorType> = {
  ['light']: {
    theme: 'light',
    white: Gray_Light_White,
    black: Gray_Light_Black,
    Gray_Light_050: Gray_Light_050,
    Gray_Light_100: Gray_Light_100,
    Gray_Light_150: Gray_Light_150,
    Gray_Light_200: Gray_Light_200,
    Gray_Light_300: Gray_Light_300,
    Gray_Light_400: Gray_Light_400,
    Gray_Light_500: Gray_Light_500,
    Gray_Light_600: Gray_Light_600,
    Gray_Light_700: Gray_Light_700,
    Gray_Light_800: Gray_Light_800,
    Gray_Light_900: Gray_Light_900,
    Gray_Light_950: Gray_Light_950,
  },
  ['dark']: {
    theme: 'dark',
    white: Gray_Dark_030,
    black: Gray_Dark_Black,
    Gray_Light_050: Gray_Dark_050,
    Gray_Light_100: Gray_Dark_100,
    Gray_Light_150: Gray_Dark_150,
    Gray_Light_200: Gray_Dark_200,
    Gray_Light_300: Gray_Dark_300,
    Gray_Light_400: Gray_Dark_400,
    Gray_Light_500: Gray_Dark_500,
    Gray_Light_600: Gray_Dark_600,
    Gray_Light_700: Gray_Dark_700,
    Gray_Light_800: Gray_Dark_800,
    Gray_Light_900: Gray_Dark_900,
    Gray_Light_950: Gray_Dark_950,
  },
};
