import React, { ComponentType, createContext, useContext, useEffect, useState } from 'react';
import { Theme, useTheme } from '@emotion/react';

import { useZigzag } from '@common/device-manager';
import { SiteId } from '@common/site-manager';

import { BrandColorMap, BrandColorType } from '../brand-color';
import { ThemeColorMap, ThemeColorType } from '../theme-color';

interface BrandStyleContextProps {
  site_id: SiteId;
  brand_color: BrandColorType;
  theme_color: ThemeColorType;
}
const DEFAULT_VALUES: BrandStyleContextProps = {
  site_id: SiteId.ZIGZAG,
  brand_color: BrandColorMap[SiteId.ZIGZAG],
  theme_color: ThemeColorMap.light,
};
export const BrandStyleContext = createContext<BrandStyleContextProps>(DEFAULT_VALUES);
BrandStyleContext.displayName = 'BrandStyleContext';

export function useBrandStyle() {
  return useContext(BrandStyleContext);
}

export function withZigzagBrandStyle<P extends Record<string, any>>(WrappedComponent: ComponentType<P>) {
  return (props: P) => {
    return (
      <BrandStyleProvider site_id={SiteId.ZIGZAG}>
        <WrappedComponent {...(props as any)} />
      </BrandStyleProvider>
    );
  };
}

function getThemeColor(site_id: SiteId, is_app: boolean | null, theme: Theme) {
  if (site_id !== SiteId.ZIGZAG) {
    return ThemeColorMap.light;
  } else {
    if (!is_app) {
      return ThemeColorMap.light;
    }
    return ThemeColorMap[theme.name] ?? ThemeColorMap.light;
  }
}

export const BrandStyleProvider: React.FC<{
  site_id: SiteId;
}> = ({ site_id, children }) => {
  const theme = useTheme();
  const is_app = useZigzag();
  const [brand_color, setBrandColor] = useState(BrandColorMap[site_id] ?? BrandColorMap[SiteId.ZIGZAG]);
  const [theme_color, setThemeColor] = useState(getThemeColor(site_id, is_app, theme));

  useEffect(() => {
    setBrandColor(BrandColorMap[site_id] ?? BrandColorMap[SiteId.ZIGZAG]);
    setThemeColor(getThemeColor(site_id, is_app, theme));
  }, [is_app, site_id, theme]);

  return (
    <BrandStyleContext.Provider value={{ brand_color, site_id, theme_color }}>{children}</BrandStyleContext.Provider>
  );
};
