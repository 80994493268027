import React from 'react';
import { ThemeProvider as BaseThemeProvider } from '@emotion/react';

import { darkTheme } from './darkTheme';
import { lightTheme } from './lightTheme';
import { ZdsTheme } from './types';

interface ThemeProps {
  name?: ZdsTheme['name'];
  children: React.ReactNode;
}

export const ThemeProvider = ({ name, children }: ThemeProps) => {
  return <BaseThemeProvider theme={name === 'dark' ? darkTheme : lightTheme}>{children}</BaseThemeProvider>;
};
