import { useEffect, useState } from 'react';
import { ProductCardSize } from '@croquiscom-pvt/zds';

import { gridLayoutManager, GridLayoutManagerState } from '../gridLayoutManager';
import { ColumnCountTypeWithoutAuto, ColumnStyleType } from '../types';

export interface UseSizeOptions {
  columnStyle: ColumnStyleType;
  calculatedColumnCount: ColumnCountTypeWithoutAuto;
  defaultSize: ProductCardSize;
}

export function useSize(options: UseSizeOptions) {
  const { columnStyle, calculatedColumnCount, defaultSize } = options;

  const [size, setSize] = useState(defaultSize);

  useEffect(() => {
    const callback = (newProductCardSizeMap: GridLayoutManagerState['productCardSizeMap']) => {
      setSize(newProductCardSizeMap[columnStyle === 'content' ? 'v4Content' : 'v4Basic'][calculatedColumnCount]);
    };

    const unsubscribe = gridLayoutManager.subscribeProductCardSizeMap(callback);

    callback(gridLayoutManager.state.productCardSizeMap);

    return () => {
      unsubscribe();
    };
  }, [columnStyle, calculatedColumnCount]);

  return size;
}
