const base_url = 'http://real-alpha.zigzag.kr';

const Config = {
  baseDomain: 'real-alpha.zigzag.kr',
  apiBaseUrl: `${base_url}:3061/api/store`,
  apiConsumerBaseUrl: `${base_url}:3061/api/2`,
  appUrl: `${base_url}:3061`,
  payApiBaseUrl: `${base_url}:9132/api/user-pay`,
  maintenanceDataUrl: `${base_url}:3061/maintenance`,
};

console.log('>>> launch on real-alpha <<<');

export default Config;
