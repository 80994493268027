/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { isBrowser } from '@croquiscom/web2app';

import { Country } from '@common/i18n';

import { isDomesticShippingUseCookie } from 'util/address_utils';
import { isZigzag } from 'util/device';

type Ga4EventHandler = (type: string, event_name: string, params?: any) => void;

type Ga4UuidHandler = (data: Ga4Uuid) => void;

interface Ga4Uuid {
  uuid: string;
}

declare global {
  interface Window {
    gtag: Ga4EventHandler & Ga4UuidHandler;
    dataLayer: unknown[];
  }
}

type Ga4EventType =
  | 'login' // 사용자가 로그인할 때
  | 'search' // 사용자가 내 콘텐츠를 검색할 때
  | 'share' // 사용자가 콘텐츠를 공유할 때
  | 'sign_up' // 회원가입, 사용자가 가입하여 각 가입 방법의 인기도를 측정할 때
  | 'add_to_cart' // 사용자가 장바구니에 상품을 추가할 때
  | 'remove_from_cart' // 사용자가 장바구니에서 상품을 삭제할 때
  | 'add_payment_info' // 사용자가 결제 정보를 제출할 때 (구매 완료 페이지 진입 시점)
  | 'purchase' // 사용자가 구매를 완료할 때 (구매 완료 페이지 진입 시점)
  | 'view_item' // 사용자가 상품을 조회할 때(PDP 조회 시)
  | 'view_promotion' // 사용자가 프로모션을 조회할 때
  | 'first_purchase'; // 사용자가 첫 구매를 했을 때

export const trackGa4 = (event_name: Ga4EventType, params: object, country?: Country) => {
  const is_domestic_shipping = country === 'KR' || isDomesticShippingUseCookie();
  if (!isZigzag() && window.gtag !== undefined && is_domestic_shipping) {
    window.gtag('event', event_name, params);
  }
};

const setUuidToDataLayer = (uuid: string) => {
  window.dataLayer = window.dataLayer || [{ uuid: '' }];
  ((window.dataLayer?.[0] || { uuid: '' }) as Ga4Uuid).uuid = uuid;
};

export const removeGa4UserId = () => {
  if (!isZigzag() && window.dataLayer !== undefined) {
    localStorage.removeItem('uuid');
    setUuidToDataLayer('');
  }
};

export const setGa4UserId = (uuid: string) => {
  if (!isZigzag() && isBrowser()) {
    localStorage.setItem('uuid', uuid);
    // send
    setUuidToDataLayer(uuid);
  }
};
