import { gql_request, GqlRequestOptions } from '@common/api-request';

import * as types from './product-umd_types.2';

export function saveWebUmdProduct(variable: types.SaveWebUmdProductVariables, options?: GqlRequestOptions) {
  const query = 'mutation SaveWebUmdProduct($product_ids: UmdProductIdentifiersInput!, $folder_id: String, $folder_name: String) { web_saveUmdProduct(product_ids: $product_ids, folder_id: $folder_id, folder_name: $folder_name) { product { catalog_product_id } } }';
  return gql_request<types.SaveWebUmdProduct, types.SaveWebUmdProductVariables>('SaveWebUmdProduct', query, variable, { use_consumer: true, ...options });
}

export function removeUmdSavedProduct(variable: types.RemoveUmdSavedProductVariables, options?: GqlRequestOptions) {
  const query = 'mutation RemoveUmdSavedProduct($product_info: UmdProductInfoInput) { removeWebUmdSavedProduct(product_info: $product_info) }';
  return gql_request<types.RemoveUmdSavedProduct, types.RemoveUmdSavedProductVariables>('RemoveUmdSavedProduct', query, variable, { use_consumer: true, ...options });
}

export function getSavedProductIdList(variable: types.GetSavedProductIdListVariables, options?: GqlRequestOptions) {
  const query = 'query GetSavedProductIdList($id_list: [String!]!) { saved_product_id_list(id_list: $id_list) { catalog_product_id_list } }';
  return gql_request<types.GetSavedProductIdList, types.GetSavedProductIdListVariables>('GetSavedProductIdList', query, variable, { use_consumer: true, ...options });
}
