export interface ResizeObserverListenerRect {
  width: number;
  height: number;
}

export interface ResizeObserverListener {
  (rect: ResizeObserverListenerRect): void;
}

export function createResizeObserverStore() {
  const listenersMap = new WeakMap<Element, Array<ResizeObserverListener>>();

  const ro = new ResizeObserver((entries) => {
    function notify() {
      entries.forEach((entry) => {
        const listeners = listenersMap.get(entry.target) ?? [];

        const { width, height } = entry.contentRect;
        listeners.forEach((listener) => listener({ width, height }));
      });
    }
    requestAnimationFrame(notify);
  });

  const subscribe = (element: Element, listener: ResizeObserverListener) => {
    let listeners = listenersMap.get(element);
    if (!listeners) {
      listeners = [listener];
      listenersMap.set(element, listeners);
      ro.observe(element);
    } else {
      listeners.push(listener);
    }

    return () => {
      const listeners = listenersMap.get(element);
      if (listeners) {
        const index = listeners.indexOf(listener);
        if (index !== -1) {
          listeners.splice(index, 1);
        }
        if (listeners.length === 0) {
          ro.unobserve(element);
          listenersMap.delete(element);
        }
      }
    };
  };

  return {
    subscribe,
  };
}

export type ResizeObserverStore = ReturnType<typeof createResizeObserverStore>;
