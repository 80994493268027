import React from 'react';

export function BoxPostyIcon({ width = 20, height = 20, ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 20 20' {...props}>
      <g id='BoxPosty'>
        <path
          id='Vector'
          d='M15.5549 1.6665H4.44379C2.90967 1.6665 1.66602 2.91016 1.66602 4.44428V15.5554C1.66602 17.0895 2.90967 18.3332 4.44379 18.3332H15.5549C17.089 18.3332 18.3327 17.0895 18.3327 15.5554V4.44428C18.3327 2.91016 17.089 1.6665 15.5549 1.6665Z'
          fill='black'
        />
        <path
          id='Vector_2'
          d='M13.4748 6.90416C13.2886 6.56932 13.0291 6.28601 12.7178 6.07779C12.3698 5.8445 11.9859 5.67672 11.5831 5.58197C11.125 5.46829 10.6557 5.4128 10.185 5.4167H7.08398V14.5832H9.2726V10.6786H10.5586C10.957 10.6797 11.3537 10.6255 11.7385 10.5175C12.1029 10.4166 12.4476 10.2495 12.757 10.0237C13.0525 9.80732 13.299 9.52517 13.4787 9.19734C13.6652 8.84453 13.7589 8.44592 13.7501 8.04249C13.7578 7.64391 13.6626 7.25058 13.4748 6.90416ZM11.3708 8.74697C11.3161 8.9499 11.2261 9.14043 11.1053 9.3089C10.9823 9.47237 10.8257 9.60445 10.6471 9.69523C10.4436 9.79268 10.2218 9.84069 9.99823 9.83571H9.2726V6.32364H9.92543C10.1272 6.3238 10.3272 6.36302 10.5154 6.43933C10.6981 6.51601 10.863 6.63295 10.9991 6.78228C11.1483 6.9408 11.2648 7.13 11.3413 7.33801C11.4261 7.57566 11.4674 7.82798 11.4632 8.08174C11.4638 8.30705 11.4326 8.53122 11.3708 8.74697Z'
          fill='white'
        />
      </g>
    </svg>
  );
}
