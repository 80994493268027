import React, { ComponentProps, forwardRef } from 'react';
import type { ProductCardProps } from '@croquiscom-pvt/zds';
import { PrimitiveAtom } from 'jotai';

import { MetadataOneDayDelivery, MetadataOneDayDeliveryProps } from './components/MetadataOneDayDelivery';
import { MetadataPlpBadgeItems, MetadataPlpBadgeItemsProps } from './components/MetadataPlpBadgeItems';
import { MetadataPrice, MetadataPriceProps } from './components/MetadataPrice';
import { MetadataReviewWithFomo, MetadataReviewWithFomoProps } from './components/MetadataReviewWithFomo';
import { MetadataRoot } from './components/MetadataRoot';
import { MetadataTitle, MetadataTitleProps } from './components/MetadataTitle';
import { ProductCardRoot } from './components/ProductCardRoot';
import { Thumbnail, ThumbnailProps } from './components/Thumbnail';
import { ThumbnailCheckbox } from './components/ThumbnailCheckbox';
import { ThumbnailEmblem, ThumbnailEmblemProps } from './components/ThumbnailEmblem';
import { ThumbnailNudge, ThumbnailNudgeProps } from './components/ThumbnailNudge';
import { ThumbnailRank, ThumbnailRankProps } from './components/ThumbnailRank';

interface VerticalProductCardThumbnailProps
  extends Pick<ThumbnailProps, 'imageUrl' | 'webpImageUrl' | 'jpegImageUrl'>,
    Pick<ThumbnailRankProps, 'rank'>,
    Pick<ThumbnailEmblemProps, 'emblemBadgeList'>,
    Pick<ThumbnailNudgeProps, 'nudgeBadgeList'> {}

interface VerticalProductCardMetadataProps
  extends Pick<MetadataTitleProps, 'title'>,
    Pick<MetadataPriceProps, 'price' | 'salesStatus'>,
    Pick<MetadataOneDayDeliveryProps, 'oneDayDelivery'>,
    Pick<MetadataPlpBadgeItemsProps, 'plpBadgeList'>,
    Pick<MetadataReviewWithFomoProps, 'review' | 'fomo'> {}

export interface SelectableVerticalProductCardProps extends Pick<ProductCardProps, 'size' | 'soldOut'> {
  catalog_product_id: string;
  thumbnail: VerticalProductCardThumbnailProps;
  metadata: VerticalProductCardMetadataProps;
  metadata_padding?: boolean;
  /**
   * checkbox 상태 관리를 위한 atom
   */
  selected_product_list: PrimitiveAtom<Set<string>>;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onChange?: ComponentProps<typeof ThumbnailCheckbox>['onChange'];
}

export const SelectableVerticalProductCard = forwardRef<HTMLDivElement, SelectableVerticalProductCardProps>(
  (props, ref) => {
    const {
      size = 'medium',
      soldOut,
      catalog_product_id,
      thumbnail,
      metadata,
      metadata_padding = false,
      onClick,
      selected_product_list,
      onChange,
    } = props;

    return (
      <ProductCardRoot className='product-card' ref={ref} size={size} soldOut={soldOut} onClick={onClick}>
        <Thumbnail
          className='product-card-thumbnail'
          size={size}
          imageUrl={thumbnail.imageUrl}
          webpImageUrl={thumbnail.webpImageUrl}
          jpegImageUrl={thumbnail.jpegImageUrl}
        >
          <ThumbnailRank className='product-card-thumbnail-rank' rank={thumbnail.rank} />
          <ThumbnailNudge className='product-card-thumbnail-nudge' nudgeBadgeList={thumbnail.nudgeBadgeList} />
          <ThumbnailEmblem
            className='product-card-thumbnail-emblem'
            size={size}
            emblemBadgeList={thumbnail.emblemBadgeList}
          />
          <ThumbnailCheckbox
            catalog_product_id={catalog_product_id}
            selected_product_list={selected_product_list}
            onChange={onChange}
          />
        </Thumbnail>
        <MetadataRoot className='product-card-metadata' size={size} padding={metadata_padding}>
          <MetadataTitle className='product-card-metadata-title' size={size} title={metadata.title} />
          <MetadataPrice
            className='product-card-metadata-price'
            salesStatus={metadata.salesStatus}
            price={metadata.price}
          />
          <MetadataOneDayDelivery
            className='product-card-metadata-one-day-delivery'
            oneDayDelivery={metadata.oneDayDelivery}
          />
          <MetadataPlpBadgeItems
            className='product-card-metadata-plp-badge-items'
            size={size}
            plpBadgeList={metadata.plpBadgeList}
          />
          <MetadataReviewWithFomo
            className='product-card-metadata-review-with-fomo'
            size={size}
            review={metadata.review}
            fomo={metadata.fomo}
          />
        </MetadataRoot>
      </ProductCardRoot>
    );
  },
);
