import { ProductCard } from '@croquiscom-pvt/zds';

import { useMetadataTitle, UseMetadataTitleOptions } from '../hooks/useMetadataTitle';

export interface MetadataTitleProps extends UseMetadataTitleOptions {
  className?: string;
}

export const MetadataTitle = (props: MetadataTitleProps) => {
  const { className, size, soldOut, title } = props;
  const { metadataTitleProps } = useMetadataTitle({ size, soldOut, title });

  return (
    <ProductCard.MetadataItem className={className}>
      <ProductCard.MetadataTitle {...metadataTitleProps} />
    </ProductCard.MetadataItem>
  );
};
