import { css } from '@emotion/react';

import { buildCSSWithLength, CSSValueWithLength } from './buildCSSWithLength';

export interface SizeProps {
  width?: CSSValueWithLength;
  height?: CSSValueWithLength;
}

export function size({ width, height }: SizeProps) {
  return css`
    ${buildCSSWithLength('width', width)}
    ${buildCSSWithLength('height', height)}
  `;
}
