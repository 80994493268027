import { useEffect, useState } from 'react';
import { isBrowser } from '@croquiscom/web2app';

export function isMobile() {
  if (typeof navigator === 'undefined') {
    return false;
  }
  const user_agent = navigator.userAgent;
  return (
    user_agent.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
    ) != null || user_agent.match(/LG|SAMSUNG|Samsung/) != null
  );
}
export function isIE(userAgent?: string) {
  const ua = userAgent ?? window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return msie > 0 || trident > 0;
}

export const isZigzag = () => {
  if (isBrowser()) {
    const app_window = window as any;
    const HAS_ZIGZAG_PROPS = !!(app_window?.zigzag || app_window?.webkit?.messageHandlers?.zigzag);
    return HAS_ZIGZAG_PROPS || /(zigzag)/i.test(navigator.userAgent);
  } else {
    return false;
  }
};

export const isKakaotalk = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  const user_agent = navigator.userAgent;
  if (isBrowser()) {
    return /KAKAOTALK/i.test(user_agent);
  } else {
    return false;
  }
};

export const isKakaotalkChannel = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  const user_agent = navigator.userAgent;
  if (isBrowser()) {
    return /ch-home/i.test(user_agent);
  } else {
    return false;
  }
};

export const useZigzag = (defaultValue?: boolean) => {
  const [is_app, setIsApp] = useState<boolean | null>(defaultValue || null);

  useEffect(() => {
    const app_window = window as any;
    const HAS_ZIGZAG_PROPS = !!(app_window?.zigzag || app_window?.webkit?.messageHandlers?.zigzag);
    setIsApp(HAS_ZIGZAG_PROPS || /(zigzag)/i.test(navigator.userAgent));
  }, []);

  return is_app;
};
