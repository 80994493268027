import debounce from 'lodash/debounce';
import findLastIndex from 'lodash/findLastIndex';

import { getSavedProductIdList } from '../graphql/product-umd.2';
import { LikeMeta, likeMetaStore, likeStatusStore } from '../store/LikeStore';

type LikeMetaValue = LikeMeta[keyof LikeMeta] & { id: string };

type GroupMetaInfo = {
  group: Record<number, { inView: boolean; meta: LikeMetaValue[] }>;
  inViewIds: string[];
  inViewCheck: Set<string>;
  calledInViewIds: Set<string>;
};

type GroupEntries = [
  string,
  {
    inView: boolean;
    meta: LikeMetaValue[];
  },
][];

const LIMIT = 100;

export function likeMetaSubscribe() {
  let abortController: AbortController = new AbortController();
  function handleSubscribe(checkIds: LikeMeta): void {
    if (process.env.NODE_ENV === 'development') {
      validLikeMeta(checkIds);
    }
    abortController.abort();
    abortController = new AbortController();

    const groupInfo = buildGroupInfo(checkIds);
    if (shouldAbort(groupInfo)) {
      return;
    }

    const groupEntries = Object.entries(groupInfo.group);
    if (groupEntries.length < 1) {
      return;
    }

    sortGroup(groupEntries);

    const { maxOrderIndex, minOrderIndex, firstGroup, firstInViewGroupIndex } = getFirstGroupInfo(
      groupEntries,
      groupInfo,
    );

    setFirstGroupItem(firstGroup, minOrderIndex, maxOrderIndex, groupInfo);
    setRestGroupItem(groupEntries, firstInViewGroupIndex, groupInfo);

    const windowIds = groupInfo.inViewIds;
    windowIds.length > 0 &&
      getSavedProductIdList({ id_list: windowIds }, { signal: abortController.signal })
        .then((response) => {
          const responseIdSet = new Set<string>();

          if (response.data.saved_product_id_list?.catalog_product_id_list) {
            for (const curr of response.data.saved_product_id_list.catalog_product_id_list) {
              responseIdSet.add(curr);
            }
          }

          const newLikeStatus: Record<string, boolean> = {};
          for (const id of windowIds) {
            newLikeStatus[id] = responseIdSet?.has(id) ?? false;
            checkIds[id].has = false;
          }
          likeStatusStore.setState(newLikeStatus);
        })
        .catch((reason) => {
          if (reason instanceof DOMException && reason.message === 'Aborted') {
            return;
          }
          console.error(reason);
        });
  }
  const debounceHandle = debounce(handleSubscribe, 160, { leading: false, trailing: true });
  const unsubscribe = likeMetaStore.subscribe(debounceHandle);
  return () => {
    unsubscribe?.();
  };
}

function buildGroupInfo(likeMeta: LikeMeta) {
  const group: GroupMetaInfo['group'] = {};
  const inViewIds: GroupMetaInfo['inViewIds'] = [];
  const inViewCheck: GroupMetaInfo['inViewCheck'] = new Set();
  const calledInViewIds: GroupMetaInfo['calledInViewIds'] = new Set();

  for (const id in likeMeta) {
    const meta = likeMeta[id];

    // 현재 아이템이 화면에 보이는 상태이고, 'has' 플래그가 설정되어 있으면 inViewIds와 inViewCheck에 추가
    if (meta.inView && meta.has) {
      if (meta.has) {
        inViewIds.push(id);
        inViewCheck.add(id);
      } else {
        calledInViewIds.add(id);
      }
    }

    // 그룹 정보가 없으면 초기화
    if (!group[meta.groupOrder]) {
      group[meta.groupOrder] = { inView: false, meta: [] };
    }

    // 메타 정보를 해당 그룹에 추가
    group[meta.groupOrder].meta.push(meta);

    // 해당 그룹의 inView 상태 업데이트
    group[meta.groupOrder].inView = group[meta.groupOrder].inView || meta.inView;
  }

  return {
    group,
    inViewIds,
    inViewCheck,
    calledInViewIds,
  };
}

function shouldAbort(groupInfo: GroupMetaInfo): boolean {
  return groupInfo.inViewCheck.size === 0 && groupInfo.calledInViewIds.size > 0;
}

function validLikeMeta(likeMeta: LikeMeta) {
  let everyInit = true;
  const errorIds: unknown[] = [];
  for (const id in likeMeta) {
    const item = likeMeta[id];
    if (typeof item.order !== 'number' || typeof item.groupOrder !== 'number') {
      everyInit = false;
      errorIds.push(item);
    }
  }
  if (!everyInit) {
    throw new Error(
      `initLikeIds로 초기화가 되지 않았습니다.\n import { useInitLikedProducts } from '@widgets/like-product'; 로  id 값들을 초기화 해주세요.\n초기화되지 않은 id들: ${JSON.stringify(
        errorIds,
      )}`,
    );
  }
}

function sortGroup(groupEntries: [string, { inView: boolean; meta: LikeMetaValue[] }][]) {
  groupEntries.forEach((value) =>
    value?.[1].meta.sort((a, b) => {
      return a.order - b.order;
    }),
  );
}

function getFirstGroupInfo(groupEntries: GroupEntries, groupInfo: GroupMetaInfo) {
  const firstInViewGroupIndex = Math.max(
    groupEntries.findIndex(([, groupMeta]) => groupMeta.inView),
    0,
  );
  const VALUE_INDEX = 1;
  const { meta: firstGroup } = groupEntries[firstInViewGroupIndex][VALUE_INDEX];
  const minOrderIndex = firstGroup.findIndex((value) => value.id === groupInfo.inViewIds[0]);
  const maxOrderIndex = findLastIndex(
    firstGroup,
    (value) => value.id === groupInfo.inViewIds[groupInfo.inViewIds.length - 1],
  );

  return {
    minOrderIndex,
    maxOrderIndex,
    firstGroup,
    firstInViewGroupIndex,
  };
}

function addIdsFromGroup(item: LikeMetaValue, groupInfo: GroupMetaInfo) {
  if (item && !groupInfo.inViewCheck.has(item.id) && item.has) {
    groupInfo.inViewIds.push(item.id);
  }
}

function setFirstGroupItem(
  firstGroup: LikeMetaValue[],
  minOrderIndex: number,
  maxOrderIndex: number,
  groupInfo: GroupMetaInfo,
) {
  for (let i = 1; i < firstGroup.length; i++) {
    addIdsFromGroup(firstGroup[maxOrderIndex + i], groupInfo);
    addIdsFromGroup(firstGroup[minOrderIndex - i], groupInfo);
    if (groupInfo.inViewIds.length >= LIMIT) {
      break;
    }
  }
}

function setGroupItem(
  groupItem: {
    inView: boolean;
    meta: LikeMetaValue[];
  },
  groupInfo: GroupMetaInfo,
) {
  if (groupItem) {
    for (const curr of groupItem.meta) {
      addIdsFromGroup(curr, groupInfo);
      if (groupInfo.inViewIds.length >= LIMIT) {
        break;
      }
    }
  }
}

function setRestGroupItem(groupEntries: GroupEntries, firstInViewGroupIndex: number, groupInfo: GroupMetaInfo) {
  for (let i = 1; i < groupEntries.length; i++) {
    const nextGroupItem = groupEntries[firstInViewGroupIndex + i]?.[1];
    setGroupItem(nextGroupItem, groupInfo);
    const prevGroupItem = groupEntries[firstInViewGroupIndex - i]?.[1];
    setGroupItem(prevGroupItem, groupInfo);
    if (groupInfo.inViewIds.length >= LIMIT) {
      break;
    }
  }
}
