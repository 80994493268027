import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors, Typography } from '@common/styles';

export interface FormItemProps {
  label?: ReactNode;
  /** @default false */
  error?: string;
  children?: ReactNode;
  className?: string;
}

const label_style = {
  error: css`
    color: ${colors.Red_Light_200};
  `,
  active: css`
    color: ${colors.Gray_Light_500};
  `,
};

export function FormItem({ label, error, children, className }: FormItemProps) {
  return (
    <Container className={className}>
      {label && (
        <Label css={error ? label_style.error : label_style.active} className={Typography.BODY_13_SEMIBOLD}>
          {error ? error : label}
        </Label>
      )}
      {children}
    </Container>
  );
}

const Container = styled.div`
  margin: 8px 0;
`;

const Label = styled.label`
  display: inline-block;
  margin: 0 0 4px 8px;
`;
