import React, { forwardRef, MutableRefObject, Ref, useCallback } from 'react';

import { UxGoodsCardItemForVerticalProductCard } from '../../vertical-product-card/types';
import { VerticalProductCard } from '../../vertical-product-card/VerticalProductCard';
import {
  useVerticalProductCardWithItem,
  UseVerticalProductCardWithItemOptions,
} from './hooks/useVerticalProductCardWithItem';

export interface VerticalProductCardWithItemBaseProps<T extends UxGoodsCardItemForVerticalProductCard> {
  item: T;
  onImpression?: (item: T) => void;
  onClick?: (item: T, event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, item: T) => void;
}

export interface VerticalProductCardWithItemProps<T extends UxGoodsCardItemForVerticalProductCard>
  extends VerticalProductCardWithItemBaseProps<T>,
    Pick<
      UseVerticalProductCardWithItemOptions<T>,
      'size' | 'productNameLineClamp' | 'metadataPadding' | 'href' | 'renderProductCardLink'
    > {}

export const VerticalProductCardWithItem = forwardRef<
  HTMLDivElement,
  VerticalProductCardWithItemProps<UxGoodsCardItemForVerticalProductCard>
>((props, ref) => {
  const {
    size,
    productNameLineClamp,
    metadataPadding,
    href,
    renderProductCardLink,
    item,
    onImpression,
    onClick,
    onLikeClick,
  } = props;

  const verticalProductCardProps = useVerticalProductCardWithItem<(typeof props)['item']>(item, {
    size,
    productNameLineClamp,
    metadataPadding,
    href,
    renderProductCardLink,
    onImpression,
    onClick,
    onLikeClick,
  });

  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      if (verticalProductCardProps.ref && typeof verticalProductCardProps.ref === 'function') {
        verticalProductCardProps.ref(node);
      }
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        // Reference: https://github.com/wojtekmaj/merge-refs/blob/76d5d82e3fe3b3f07559dc36e2270389fcec5a40/src/index.ts#L31
        (ref as MutableRefObject<HTMLDivElement | null>).current = node;
      }
    },
    [ref, verticalProductCardProps],
  );

  return <VerticalProductCard {...verticalProductCardProps} ref={setRefs} />;
}) as <T extends UxGoodsCardItemForVerticalProductCard>(
  props: VerticalProductCardWithItemProps<T> & { ref?: Ref<HTMLDivElement> },
) => JSX.Element;
