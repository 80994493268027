import * as Sentry from '@sentry/nextjs';
import isArray from 'lodash/fp/isArray';
import pipe from 'lodash/fp/pipe';

import { isBrowser } from '../../utils/conditions';
import { devLogger } from '../../utils/devLogger';
import { injectZigzagHandler } from '../handlers/inject';

const handleArgs =
  (functionname: string) =>
  <A>(args?: A) => {
    const iosParam = `js2ios://${JSON.stringify({ functionname, ...(!!args && { args }) })}`;
    const aosParam = !args
      ? []
      : isArray(Object.values(args)[0])
      ? [JSON.stringify(Object.values(args)[0])]
      : Object.values(args);
    return {
      iosParam,
      aosParam: functionname === 'sc' ? [args] : aosParam,
    };
  };

export const callZigzagFunction = (functionname: string) =>
  pipe(injectZigzagHandler, handleArgs(functionname), devLogger('zigzag', functionname), ({ iosParam, aosParam }) => {
    if (!isBrowser()) {
      return console.warn('Should invoke callZigzagFunction in client');
    }

    const _window: any = window;
    const inIOS = _window.webkit && _window.webkit.messageHandlers && _window.webkit.messageHandlers.zigzag;
    const inAndroid = _window.zigzag && _window.zigzag[functionname];

    try {
      if (inIOS) {
        _window.webkit.messageHandlers.zigzag.postMessage(iosParam);
      }
      if (inAndroid) {
        _window.zigzag[functionname](...aosParam);
      }
    } catch (error) {
      Sentry.withScope((scope: Sentry.Scope) => {
        scope.setContext('CallZigzagFunction', {
          functionName: functionname,
          iosParam,
          aosParam,
        });
        Sentry.captureException(error);
      });

      throw error;
    }
  });
