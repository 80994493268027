import { FomoProps } from '../../components/Fomo';

export type ReturnMetadataFomoFunction<T extends undefined | null | Pick<FomoProps, 'text' | 'iconImageUrl'>> =
  T extends FomoProps
    ? { hasFomo: true; metadataFomoProps: Pick<FomoProps, 'text' | 'iconImageUrl'> }
    : { hasFomo: false; metadataFomoProps: Pick<FomoProps, 'text' | 'iconImageUrl'> };

export interface UseMetadataFomoOptions {
  fomo?: Pick<FomoProps, 'text' | 'iconImageUrl'> | null;
}

export function useMetadataFomo<T extends UseMetadataFomoOptions, R = ReturnMetadataFomoFunction<T['fomo']>>(
  options: T,
): R {
  const { fomo } = options;
  const hasFomo = fomo !== undefined && fomo !== null;

  return {
    hasFomo,
    metadataFomoProps: hasFomo ? fomo : undefined,
  } as R;
}
