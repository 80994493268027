import { MessageKeyType, message } from './messege';

export const MESSAGE_QUERY_NAME = 'message';

export function isMessageKey(queryValue: string | string[] | undefined): queryValue is MessageKeyType {
  return typeof queryValue === 'string' && queryValue in message;
}

/**
 *
 * @param path url(http://example.com/home?a=1)이 아니라 path(/home?a=1)입니다.
 * @returns path
 */
export function removeMessageQueryFromPath(path: string) {
  const url = new URL(path, 'http://example.com');
  const searchParams = new URLSearchParams(url.search);
  searchParams.delete('message');
  url.search = searchParams.toString();
  const updatedAsPath = url.pathname + url.search + url.hash;
  return updatedAsPath;
}

/**
 *
 * @param path url(http://example.com/home?a=1)이 아니라 path(/home?a=1)입니다.
 * @returns path
 */
export function appendMessageQueryFromPath(path: string, messageKey: MessageKeyType) {
  const urlObject = new URL(path, 'http://example.com');
  const searchParams = new URLSearchParams(urlObject.search);
  searchParams.append(MESSAGE_QUERY_NAME, messageKey);
  urlObject.search = searchParams.toString();
  const updatedUrl = urlObject.pathname + urlObject.search + urlObject.hash;
  return updatedUrl;
}
