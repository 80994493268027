import { useState } from 'react';

import { useBrowserEffect } from '@common/hooks';

export const useBrowserTheme = () => {
  const [theme, setTheme] = useState('');

  useBrowserEffect(() => {
    const html = document.querySelector('html')!;
    const theme = html.dataset.theme || 'light';
    setTheme(theme);
  }, []);

  return {
    theme,
    setTheme,
  };
};
