import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { AuthSessionStorage } from '@domains/auth-common';

export const useClearAuthInfo = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRoute = (url: string) => {
      if (url.includes('auth') === false) {
        AuthSessionStorage.clearAllData();
      }
    };

    router.events.on('routeChangeStart', handleRoute);
    return () => {
      router.events.off('routeChangeStart', handleRoute);
    };
  }, [router]);
};
