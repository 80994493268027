import React from 'react';

export const CautionroundIcon = ({
  width = 32,
  height = 32,
  color = '#000000',
  ...props
}: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} color={color} fill='none' viewBox='0 0 32 32' {...props}>
    <circle cx='16' cy='16' r='10.25' stroke='currentColor' stroke-width='1.5' />
    <path d='M16 10V18' stroke='currentColor' stroke-width='1.5' />
    <path d='M16 20V22' stroke='currentColor' stroke-width='1.5' />
  </svg>
);
