import { Gray_Light_150, Gray_Light_200 } from '@common/styles';

export const IconCheckSmallNormal = ({
  width = 16,
  height = 16,
  color = Gray_Light_200,
  disable_color = Gray_Light_150,
  disabled,
  ...props
}: JSX.IntrinsicElements['svg'] & {
  disable_color?: string;
  disabled?: boolean;
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    color={disabled ? disable_color : color}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z'
      fill='currentColor'
    />
  </svg>
);
