import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { callDeepLink, callZigzagFunction } from '@croquiscom/web2app';
import {
  Gray_Dark_030,
  Gray_Dark_900,
  Gray_Dark_950,
  Gray_Light_150,
  Gray_Light_900,
  Gray_Light_950,
  IconArrowLeftBold32,
  IconSettingBold32,
  Pink_Light_400,
} from '@croquiscom/zds';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useUBL } from '@common/log-manager';
import { Gray_Light_White, safeAreaInsetTop, Typography } from '@common/styles';
import { hexToRGBA } from '@common/utils';

interface Props extends React.HTMLProps<HTMLDivElement> {
  title: string;
  paddingTop?: number;
  hasSettingIcon?: boolean;
  is_editing?: boolean;
  onClickEndEdit?: () => void;
  onClickStartEdit?: () => void;
  onClickBack?: () => void;
}

export const NotificationHeader: React.FC<Props> = ({
  title,
  paddingTop = 0,
  hasSettingIcon = false,
  is_editing,
  onClickEndEdit,
  onClickStartEdit,
  onClickBack,
  children,
}) => {
  const [isMenuShown, setIsMenuShown] = useState(false);
  const { t } = useTranslation('noti');
  const sendUBL = useUBL();

  const theme = useTheme();
  const is_dark_theme = theme.name === 'dark';
  const theme_color = is_dark_theme ? Gray_Dark_900 : Gray_Light_900;
  const theme_icon_color = is_dark_theme ? Gray_Dark_950 : Gray_Light_950;

  const handleClickBack = () => {
    if (!onClickBack) {
      callZigzagFunction('back')();
    } else {
      onClickBack();
    }
  };

  const onClickMoveSettings = () => {
    const url = '/notification/settings';
    sendUBL({
      category: 'pageview',
      navigation: 'simple_browser',
      navigation_sub: { page_url: `${window.location.origin}${url}` },
    });
    setIsMenuShown(false);
    callDeepLink(url)();
  };

  return (
    <Container is_dark_theme={is_dark_theme}>
      <Header paddingTop={paddingTop} is_dark_theme={is_dark_theme}>
        <Bar>
          {!is_editing && <BackIcon color={theme_icon_color} onClick={handleClickBack} />}
          <Title color={theme_color} className={Typography.BODY_17_BOLD}>
            {title}
          </Title>
          {hasSettingIcon && !is_editing && (
            <SettingIcon color={theme_icon_color} onClick={() => setIsMenuShown((prev) => !prev)} />
          )}
          {is_editing && (
            <EditComplete className={Typography.BODY_13_BOLD} onClick={onClickEndEdit}>
              {t('완료')}
            </EditComplete>
          )}
        </Bar>
        {isMenuShown && (
          <>
            <Dropdown>
              <li className={Typography.BODY_14_SEMIBOLD} onClick={onClickMoveSettings}>
                {t('설정')}
              </li>
              <li
                className={Typography.BODY_14_SEMIBOLD}
                onClick={() => {
                  onClickStartEdit?.();
                  setIsMenuShown(false);
                }}
              >
                {t('편집')}
              </li>
            </Dropdown>
            <DropdownBackground onClick={() => setIsMenuShown(false)} />
          </>
        )}
      </Header>
      {children}
    </Container>
  );
};

const Container = styled.div<{ is_dark_theme?: boolean }>`
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${({ is_dark_theme }) => (is_dark_theme ? Gray_Dark_030 : Gray_Light_White)};
  padding-bottom: 12px;
`;

const Header = styled.header<{ paddingTop: number; is_dark_theme: boolean }>`
  background-color: ${({ is_dark_theme }) => (is_dark_theme ? Gray_Dark_030 : Gray_Light_White)};
  ${({ paddingTop }) => safeAreaInsetTop(`${paddingTop}px`)}
`;

const Bar = styled.div`
  height: 44px;
  position: relative;
  text-align: center;
`;

const Title = styled.h1<{ color: string }>`
  line-height: 44px;
  vertical-align: middle;
  letter-spacing: -0.3px;
  color: ${({ color }) => color};
`;

const BackIcon = styled(IconArrowLeftBold32)`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`;

const SettingIcon = styled(IconSettingBold32)`
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 3;
  transform: translateY(-50%);
`;

const Dropdown = styled.ul`
  width: auto;
  min-width: 102px;
  height: auto;
  background: ${Gray_Light_White};
  border-radius: 8px;
  box-shadow: 0px 4px 16px ${hexToRGBA('#000000', 0.1)};
  position: absolute;
  right: 16px;
  z-index: 3;
  transform: translateY(2px);

  li {
    padding: 14px 16px;
    text-align: left;
    &:not(:last-of-type) {
      border-bottom: 1px solid ${Gray_Light_150};
    }
  }
`;

const DropdownBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 2;
`;

const EditComplete = styled.button`
  background: ${Pink_Light_400};
  border-radius: 16px;
  color: ${Gray_Light_White};
  position: absolute;
  top: 6px;
  right: 14px;
  padding: 0 12px;
  width: auto;
  min-width: 47px;
  height: 32px;
  line-height: 32px;
`;
