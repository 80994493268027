import {
  VerticalProductCardWithItem,
  VerticalProductCardWithItemProps,
} from '../components/vertical-product-card-with-item/VerticalProductCardWithItem';
import { UxGoodsCardItemForVerticalProductCard } from '../vertical-product-card/types';
import { createVirtuosoGridContainer } from '../virtuoso-grid-container/Factory';

interface ContainerOtherProps
  extends Pick<VerticalProductCardWithItemProps<UxGoodsCardItemForVerticalProductCard>, 'productNameLineClamp'> {
  onImpression?: (item: UxGoodsCardItemForVerticalProductCard, index: number) => void;
  onClick?: (item: UxGoodsCardItemForVerticalProductCard, index: number, event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, item: UxGoodsCardItemForVerticalProductCard, index: number) => void;
}

export const VerticalProductCardVirtuosoGridContainer = createVirtuosoGridContainer<
  ContainerOtherProps,
  UxGoodsCardItemForVerticalProductCard
>({
  renderProductCard: (item, itemIndex, context) => {
    const { size, columnStyle, otherProps } = context;

    const { productNameLineClamp, onImpression, onClick, onLikeClick } = otherProps;

    return (
      <VerticalProductCardWithItem
        size={size}
        productNameLineClamp={productNameLineClamp}
        metadataPadding={columnStyle === 'basic'}
        item={item}
        onImpression={(item) => onImpression?.(item, itemIndex)}
        onClick={(item, event) => onClick?.(item, itemIndex, event)}
        onLikeClick={(isLiked, item) => onLikeClick?.(isLiked, item, itemIndex)}
      />
    );
  },
});

VerticalProductCardVirtuosoGridContainer.displayName = 'VerticalProductCardVirtuosoGridContainer';

export type VerticalProductCardVirtuosoGridContainerProps = React.ComponentProps<
  typeof VerticalProductCardVirtuosoGridContainer
>;
