import { useUBL } from '@common/log-manager';

export type AuthNavigationType =
  | 'my_page'
  | 'delete_account'
  | 'login_email'
  | 'incorrect_account_info'
  | 'second_authentication_password'
  | 'second_authentication_email'
  | 'second_authentication'
  | 'tel_authorization'
  | 'tel_authorization_no_result'
  | 'duplicate_number_email';

export type AuthNavigationSubType =
  | 'delete_account_guide'
  | 'delete_account_reason'
  | 'delete_account_final'
  | 'no_password_set'
  | '5_to_9'
  | '10'
  | 'login_tel_number';

export const useAuthUBL = (navigation: AuthNavigationType) => {
  const tracker = useUBL();
  const previewLog = (navigation_sub?: { page_id: AuthNavigationSubType }, data?: { is_opened: boolean }) => {
    tracker({ category: 'pageview', navigation, navigation_sub, data });
  };

  const clickLog = (object_id: string, navigation_sub?: { page_id: AuthNavigationSubType }) => {
    tracker({ category: 'click', navigation, object_type: 'button', object_id, navigation_sub });
  };

  return { previewLog, clickLog };
};
