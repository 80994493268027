import { useCallback } from 'react';
import { CurrencyMeta } from './graphql/currency_types.2';
import { generateExchangeCurrencyFunc } from './currency_utils';

/**
 * 주어진 currency meta를 이용하여,
 * 원화 가격을 받아 환전한 금액(Number)을 리턴하는 함수를 생성한다.
 * @param currency_meta 표기할 CurrencyMeta 정보
 */
export const useExchangeCurrency = (currency_meta: CurrencyMeta) => {
  const exchangeCurrency = useCallback(generateExchangeCurrencyFunc(currency_meta), [currency_meta]);
  return { exchangeCurrency };
};
