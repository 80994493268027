import { ProductCardMetadataBadgeItemsItemsProps, useTheme } from '@croquiscom-pvt/zds';

export interface BadgeItemProps extends Omit<ProductCardMetadataBadgeItemsItemsProps, 'className'> {
  className?: string;
  darkImageUrl: string | null;
  imageUrl: string | null;
}

export const BadgeItem = (props: BadgeItemProps) => {
  const { className, darkImageUrl, imageUrl } = props;

  const { colorScheme } = useTheme();

  if (colorScheme === 'system') {
    if (!imageUrl) {
      return null;
    }

    return (
      <picture>
        {darkImageUrl && <source srcSet={darkImageUrl} media='(prefers-color-scheme: dark)' />}
        <img className={className} src={imageUrl} alt='' />
      </picture>
    );
  }

  if (colorScheme === 'dark') {
    const src = darkImageUrl ?? imageUrl;
    if (!src) {
      return null;
    }
    return <img className={className} src={src} alt='' />;
  }

  if (!imageUrl) {
    return null;
  }

  return <img className={className} src={imageUrl} alt='' />;
};
