import { createListContainer, ListContainerProps } from '@croquiscom-pvt/zds';

import {
  HorizontalProductCardWithItem,
  HorizontalProductCardWithItemProps,
} from '../components/horizontal-product-card-with-item/HorizontalProductCardWithItem';
import { UxGoodsCardItemForHorizontalProductCard } from '../horizontal-product-card/types';

interface ContainerOtherProps<T>
  extends Pick<
    HorizontalProductCardWithItemProps,
    'likeButtonType' | 'thumbnailWidth' | 'thumbnailRatio' | 'metadataAlign' | 'productNameLineClamp'
  > {
  onImpression?: (item: T, index: number) => void;
  onClick?: (item: T, index: number, event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, item: T, index: number) => void;
}

interface HorizontalProductCardListContainerType {
  (
    props: ListContainerProps<
      UxGoodsCardItemForHorizontalProductCard,
      ContainerOtherProps<UxGoodsCardItemForHorizontalProductCard>
    >,
  ): JSX.Element;
  <T>(props: ListContainerProps<T, ContainerOtherProps<T>>): JSX.Element;
  displayName?: string;
}

export const HorizontalProductCardListContainer = createListContainer<
  ContainerOtherProps<UxGoodsCardItemForHorizontalProductCard>,
  UxGoodsCardItemForHorizontalProductCard
>({
  renderItem: (item, index, context) => {
    const { otherProps } = context;

    const {
      likeButtonType,
      thumbnailWidth,
      thumbnailRatio,
      metadataAlign,
      productNameLineClamp,
      onImpression,
      onClick,
      onLikeClick,
    } = otherProps;

    return (
      <HorizontalProductCardWithItem
        likeButtonType={likeButtonType}
        thumbnailWidth={thumbnailWidth}
        thumbnailRatio={thumbnailRatio}
        metadataAlign={metadataAlign}
        productNameLineClamp={productNameLineClamp}
        item={item}
        onImpression={(item) => onImpression?.(item, index)}
        onClick={(item, event) => onClick?.(item, index, event)}
        onLikeClick={(isLiked, item) => onLikeClick?.(isLiked, item, index)}
      />
    );
  },
}) as HorizontalProductCardListContainerType;

HorizontalProductCardListContainer.displayName = 'HorizontalProductCardListContainer';

export type HorizontalProductCardListContainerProps = React.ComponentProps<typeof HorizontalProductCardListContainer>;
