/* eslint-disable jsx-a11y/alt-text */
import { ImgHTMLAttributes } from 'react';
import { useTheme } from '@croquiscom-pvt/zds';
import { css, SerializedStyles } from '@emotion/react';

import { productCardReviewDarkIcon, productCardReviewLightIcon } from '../constants';

const imageCss = css`
  display: block;
  width: 12px;
  height: 12px;
  user-select: none;
`;

const commonImgProps = {
  css: imageCss,
  draggable: 'false',
  alt: '',
} as ImgHTMLAttributes<HTMLImageElement> & { css: SerializedStyles };

export const MetadataReviewIcon = () => {
  const { colorScheme } = useTheme();

  if (colorScheme === 'system') {
    return (
      <picture>
        <source srcSet={productCardReviewDarkIcon} media='(prefers-color-scheme: dark)' />
        <img {...commonImgProps} src={productCardReviewLightIcon} />
      </picture>
    );
  }

  if (colorScheme === 'dark') {
    return <img {...commonImgProps} src={productCardReviewDarkIcon} />;
  }

  return <img {...commonImgProps} src={productCardReviewLightIcon} />;
};
