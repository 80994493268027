import { createContext, FC, useContext } from 'react';

import { CurrencyMeta } from './graphql/currency_types.2';
import { CurrencyUtils, DEFAULT_CURRENCY_META } from './currency_utils';
import { UserLocale } from './locale_types';
import { useDisplayPriceWithCurrencyMeta } from './useDisplayPriceWithCurrencyMeta';
import { useExchangeCurrency } from './useExchangeCurrency';

/**
 * @public
 */
export const DEFAULT_USER_LOCALE: UserLocale = {
  language: 'ko',
  country: 'KR',
  currency: 'KRW',
};

/**
 * @public
 */
export const DEFAULT_GLOBAL_USER_LOCALE: UserLocale = {
  language: 'ja',
  country: 'JP',
  currency: 'JPY',
};

/**
 * @public
 */
export const DEFAULT_CURRENCY_UTILS: CurrencyUtils = {
  currency_meta: DEFAULT_CURRENCY_META,
  displayPriceWithCurrency: () => '',
  exchangeCurrency: () => 0,
};

const I18nContext = createContext<UserLocale & CurrencyUtils>({
  ...DEFAULT_USER_LOCALE,
  ...DEFAULT_CURRENCY_UTILS,
});
I18nContext.displayName = 'I18nContext';

/**
 * @public
 * const { language, country, currency, currency_meta, displayPriceWithCurrency } = useUserLocale();
 */
export function useUserLocale() {
  return useContext(I18nContext);
}

/**
 * @public
 */
export const I18nProvider: FC<{
  user_locale: UserLocale;
  currency_meta: CurrencyMeta;
}> = ({ user_locale, currency_meta, children }) => {
  const { displayPriceWithCurrency } = useDisplayPriceWithCurrencyMeta(currency_meta);
  const { exchangeCurrency } = useExchangeCurrency(currency_meta);

  return (
    <I18nContext.Provider value={{ ...user_locale, currency_meta, displayPriceWithCurrency, exchangeCurrency }}>
      {children}
    </I18nContext.Provider>
  );
};
