import type { ProductCardProps, ProductCardThumbnailEmblemProps } from '@croquiscom-pvt/zds';

import { BadgeItem } from '../../components/BadgeItem';
import { BadgeListItemProps } from '../types';
import { covertBadgeItemPropsBySize } from '../utils';

export type ReturnThumbnailEmblemFunction<T extends undefined | BadgeListItemProps[]> = T extends BadgeListItemProps[]
  ? { hasThumbnailEmblem: true; thumbnailEmblemProps: ProductCardThumbnailEmblemProps }
  : { hasThumbnailEmblem: false; thumbnailEmblemProps: undefined };

export interface UseThumbnailEmblemOptions extends Pick<ProductCardProps, 'size'> {
  emblemBadgeList?: BadgeListItemProps[];
}

export function useThumbnailEmblem<
  T extends UseThumbnailEmblemOptions,
  R = ReturnThumbnailEmblemFunction<T['emblemBadgeList']>,
>(options: T): R {
  const { size, emblemBadgeList } = options;

  const thumbnailEmblemProps: ProductCardThumbnailEmblemProps = {
    items:
      emblemBadgeList?.map((item) => {
        return <BadgeItem {...covertBadgeItemPropsBySize(item, size)} />;
      }) ?? [],
  };

  const hasThumbnailEmblem = emblemBadgeList && emblemBadgeList?.length > 0;

  return {
    hasThumbnailEmblem,
    thumbnailEmblemProps,
  } as R;
}
