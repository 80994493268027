const _process: any = process;

export const isDev = () => {
  if (_process.env.NODE_ENV) {
    return _process.env.NODE_ENV === 'development';
  }
  return !_process.env.ON_DOCKER;
};

export const isBrowser = () => {
  return typeof window !== 'undefined';
};

export const isAndroid = (userAgent = navigator.userAgent) => {
  return Boolean(userAgent && /(android)/i.test(userAgent));
};

export const isIOS = (userAgent = navigator.userAgent) => {
  return Boolean(userAgent && /iPhone|iPad|iPod|\bOS X\b/gi.test(userAgent));
};

export const isChrome = (userAgent = navigator.userAgent) => {
  return Boolean(userAgent && /(Chrome)/i.test(userAgent));
};
