export const PRODUCT_CARD_CAROUSEL_ROOT_CLASS_NAME = 'product__card__carousel';
/** 지그재그 서비스 최대 너비값 (600px) */
export const MAX_WIDTH = 600;
/** 지그재그 서비스 최소 너비값 (320px) */
export const MIN_WIDTH = 320;

export const NAVIGATION_SIZE = 32;

/*
    ZDS 4.0 상품카드 캐러셀 디자인 가이드 스펙
    Figma: https://www.figma.com/design/e0eYHVK0dP5NcqKKTR0cxk/ZDS-3.5.0?node-id=69311-148800&t=c49tlK34zDM9yw0K-4
*/
export const SLIDE_ITEM_MIN_WIDTH_2_12 = 142;
export const SLIDE_ITEM_MAX_WIDTH_2_12 = 202;
export const SLIDE_ITEM_MIN_WIDTH_2_5 = 120;
export const SLIDE_ITEM_MAX_WIDTH_2_5 = 172;
export const SLIDE_ITEM_MIN_WIDTH_3_14 = 96;
export const SLIDE_ITEM_MAX_WIDTH_3_14 = 172;

export const cssVariables = {
  navigationOffset: '--product-card-carousel-navigation-offset',
  slidesOffsetBefore: '--product-card-carousel-slides-offset-before',
};
