import styled from '@emotion/styled';

const Svg = styled('svg')<{ color?: string }>`
  color: ${({ color, theme }) => (!color ? theme.colors.Gray_950 : color)};

  circle,
  ellipse,
  foreignObject,
  image,
  line,
  path,
  polygon,
  polyline,
  rect,
  text,
  textPath,
  tspan,
  use {
    vector-effect: non-scaling-stroke;
  }
`;

// 기존 코드 대응을 위해 둠
// eslint-disable-next-line import/no-default-export
export default Svg;
