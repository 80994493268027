import { FC } from 'react';
import { ProductCard } from '@croquiscom-pvt/zds';
import { vars } from '@croquiscom-pvt/zds/tokens';
import { PrimitiveAtom } from 'jotai';

import { useThumbnailCheckbox } from '../hooks/useThumbnailCheckbox';

interface Props {
  catalog_product_id: string;
  onChange?: (is_selected: boolean) => void;
  selected_product_list: PrimitiveAtom<Set<string>>;
}

export const ThumbnailCheckbox: FC<Props> = ({ catalog_product_id, selected_product_list, onChange }) => {
  const { is_selected, select } = useThumbnailCheckbox({ catalog_product_id, selected_product_list });

  const handleSelectChange = (is_selected: boolean) => {
    select((prev_selected_product_list) => {
      const next_selected_product_list = new Set(prev_selected_product_list);
      is_selected
        ? next_selected_product_list.add(catalog_product_id)
        : next_selected_product_list.delete(catalog_product_id);
      return next_selected_product_list;
    });
    onChange?.(is_selected);
  };

  return (
    <>
      {is_selected && <ProductCard.ThumbnailDimmed backgroundColor={vars.color.onImageBlackOpacity30} />}
      <ProductCard.ThumbnailCheckbox ariaLabel='상품 선택' checked={is_selected} onChange={handleSelectChange} />
    </>
  );
};
