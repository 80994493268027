export const MessageKey = {
  availablePostLogin: 'availablePostLogin',
  activateInactivateUserAccount: 'activateInactivateUserAccount',
} as const;

export type MessageKeyType = keyof typeof MessageKey;

export const message: Record<MessageKeyType, { key: string; value: string }> = {
  [MessageKey.availablePostLogin]: {
    key: MessageKey.availablePostLogin,
    value: '로그인 후 사용이 가능해요.',
  },
  [MessageKey.activateInactivateUserAccount]: {
    key: MessageKey.activateInactivateUserAccount,
    value: '회원님의 계정 활성화가 완료되었습니다!\n해당 계정으로 로그인해주세요.',
  },
};
