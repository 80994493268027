import { Gray_Light_150, Gray_Light_200 } from '@common/styles';

export const IconCheckLargeNormal = ({
  width = 24,
  height = 24,
  color = Gray_Light_200,
  disable_color = Gray_Light_150,
  disabled,
  ...props
}: JSX.IntrinsicElements['svg'] & {
  disable_color?: string;
  disabled?: boolean;
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    color={disabled ? disable_color : color}
    {...props}
  >
    <path
      d='M12 2C17.5197 2 22 6.48032 22 12C22 17.5197 17.5197 22 12 22C6.48032 22 2 17.5197 2 12C2 6.48032 6.48032 2 12 2Z'
      stroke='currentColor'
      strokeWidth='2'
    />
  </svg>
);
