import React, { forwardRef, ReactNode } from 'react';
import { IconArrowLeftBold, Text } from '@croquiscom-pvt/zds';
import styled from '@emotion/styled';

import { white } from '@common/styles';

export interface NavigationProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: ReactNode;
  right?: ReactNode;
  onBack?: () => void;
}

export const Navigation = forwardRef<HTMLDivElement, NavigationProps>(
  ({ right, title, onBack, ...container_props }, ref) => {
    return (
      <Container ref={ref} {...container_props}>
        <LeftItem>
          <Button aria-label='뒤로 가기' onClick={onBack}>
            <IconArrowLeftBold size={24} />
          </Button>
        </LeftItem>
        <CenterItem variant='Body_17_Bold' as='div'>
          {title}
        </CenterItem>
        <RightItem>{right}</RightItem>
      </Container>
    );
  },
);

const Container = styled.nav`
  height: 44px;
  background: ${white};
  display: flex;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
`;

const LeftItem = styled.div`
  flex: 1;
`;

const CenterItem = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  flex: 2;
`;

const RightItem = styled.div`
  padding-right: 4px;
  text-align: right;
  flex: 1;
`;

const Button = styled.button`
  display: flex;
`;
