interface Message {
  REQUIRED: string;
  VALIDATE: string;
  MIN_LENGTH?: string;
  EXPIRED?: string;
  EXPIRED_PIN_NUMBER?: string;
  UNEXPECTED_CHARACTER?: string;
}
type MessageType = Record<string, Message>;

export const VALIDATION_MESSAGE: MessageType = {
  EMAIL: {
    REQUIRED: '이메일을 입력해 주세요.',
    VALIDATE: '이메일 형식이 올바르지 않아요.',
  },
  TEL: {
    REQUIRED: '휴대폰 번호를 입력해 주세요.',
    VALIDATE: '휴대폰 번호 형식이 올바르지 않아요.',
  },
  PIN_NUMBER: {
    REQUIRED: '올바른 인증번호를 입력해 주세요.',
    VALIDATE: '올바른 인증번호를 입력해 주세요.',
    EXPIRED: '인증 번호 입력 유효 시간이 지났어요. 다시 인증해주세요.',
    EXPIRED_PIN_NUMBER: '기존 인증번호가 유효하지 않아요. 다시 인증해 주세요.',
  },
  NEW_PASSWORD: {
    REQUIRED: '새 비밀번호를 입력해 주세요.',
    MIN_LENGTH: '8자리 이상 입력해 주세요.',
    VALIDATE: '영문, 숫자, 특수문자를 모두 포함해주세요.',
    UNEXPECTED_CHARACTER: '사용할 수 없는 문자가 있어요.',
  },
  NEW_PASSWORD_CONFIRM: {
    REQUIRED: '새 비밀번호를 재입력해 주세요.',
    MIN_LENGTH: '8자리 이상 입력해 주세요.',
    VALIDATE: '재입력한 비밀번호가 일치하지 않아요.',
  },
  PASSWORD: {
    REQUIRED: '비밀번호를 입력해 주세요.',
    MIN_LENGTH: '8자리 이상 입력해 주세요.',
    VALIDATE: '영문, 숫자, 특수문자를 모두 포함해주세요.',
    UNEXPECTED_CHARACTER: '사용할 수 없는 문자가 있어요.',
  },
};
