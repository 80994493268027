import { CSSProperties } from 'react';
import type { ProductCardThumbnailProps } from '@croquiscom-pvt/zds';

import { ThumbnailRatioType } from '../types';

export interface UseThumbnailOptions {
  webpImageUrl: string | null;
  imageUrl: string | null;
  /**
   * 113이하의 사이즈만 사용할 수 있습니다.
   * 그 외 사이즈를 사용할 경우 플랫폼 디자인팀과 선 논의가 필요하며, 논의 후 사용하는 코드에 해당 히스토리를 파악할 수 있게 주석을 남겨주세요.
   */
  width: number;
  /**
   * 썸네일 이미지의 비율을 지정합니다.
   * `vertical`의 경우 1:1.2 비율의 이미지가 표시되며, `square`의 경우 1:1 비율의 이미지가 표시됩니다.
   * @default vertcial
   */
  ratio?: ThumbnailRatioType;
}

export function useThumbnail(options: UseThumbnailOptions) {
  const { imageUrl, webpImageUrl, width, ratio: ratioFromOptions = 'vertical' } = options;

  const ratio = ratioFromOptions === 'vertical' ? 1 / 1.2 : 1;
  const marginRight = width >= 100 ? 16 : 12;
  const src = webpImageUrl ? webpImageUrl : imageUrl ? imageUrl : '';
  const style: CSSProperties = {
    width,
    marginRight,
    flexShrink: 0,
  };

  const thumbnailProps: ProductCardThumbnailProps = {
    src,
    ratio,
    style,
  };

  return {
    thumbnailProps,
  };
}
