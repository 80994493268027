export const white = '#FFFFFF';
export const black = '#000000';

export const Gray_Light_White = '#FFFFFF';
export const Gray_Light_Black = '#000000';

export const Gray_Dark_White = '#000000';
export const Gray_Dark_Black = '#FFFFFF';

export const Gray_Light_030 = '#F5F6F8';
export const Gray_Light_050 = '#FAFAFB';
export const Gray_Light_100 = '#F5F6F8';
export const Gray_Light_150 = '#E8E9EB';
export const Gray_Light_200 = '#D2D5D6';
export const Gray_Light_300 = '#B8BFC2';
export const Gray_Light_400 = '#A1A9AD';
export const Gray_Light_500 = '#828A8F';
export const Gray_Light_600 = '#697175';
export const Gray_Light_700 = '#4F5558';
export const Gray_Light_800 = '#34383B';
export const Gray_Light_900 = '#242729';
export const Gray_Light_950 = '#121314';

export const Gray_Dark_030 = '#1A1A1A';
export const Gray_Dark_050 = '#1F2123';
export const Gray_Dark_100 = '#222526';
export const Gray_Dark_150 = '#2F3234';
export const Gray_Dark_200 = '#414547';
export const Gray_Dark_300 = '#585C5E';
export const Gray_Dark_400 = '#717678';
export const Gray_Dark_500 = '#888E90';
export const Gray_Dark_600 = '#A3A9AC';
export const Gray_Dark_700 = '#C7CACC';
export const Gray_Dark_800 = '#D9DCDE';
export const Gray_Dark_900 = '#F5F6F8';
export const Gray_Dark_950 = '#FAFAFB';

export const Pink_Light_050 = '#FEF6FD';
export const Pink_Light_100 = '#FFEBFC';
export const Pink_Light_150 = '#FFD1F7';
export const Pink_Light_200 = '#FFB2F3';
export const Pink_Light_300 = '#FF8AEB';
export const Pink_Light_400 = '#FA6EE3';
export const Pink_Light_500 = '#E560CF';
export const Pink_Light_600 = '#BF49AB';
export const Pink_Light_700 = '#913382';
export const Pink_Light_800 = '#6D2261';
export const Pink_Light_900 = '#4F1245';

export const Pink_Dark_050 = '#4A1341';
export const Pink_Dark_100 = '#65215A';
export const Pink_Dark_150 = '#853078';
export const Pink_Dark_200 = '#AF449D';
export const Pink_Dark_300 = '#D159BD';
export const Pink_Dark_400 = '#FA6EE3';
export const Pink_Dark_500 = '#F384E1';
export const Pink_Dark_600 = '#F3ABE8';
export const Pink_Dark_700 = '#F3C8EC';
export const Pink_Dark_800 = '#F3DBEF';
export const Pink_Dark_900 = '#F2E7F0';

export const Purple_Light_050 = '#F5F5FF';
export const Purple_Light_100 = '#E8E6FF';
export const Purple_Light_150 = '#D7D4FF';
export const Purple_Light_200 = '#C1BAFF';
export const Purple_Light_300 = '#A89FFF';
export const Purple_Light_400 = '#8F82FF';
export const Purple_Light_500 = '#7463F2';
export const Purple_Light_600 = '#604EDF';
export const Purple_Light_700 = '#4B3ABF';
export const Purple_Light_800 = '#392C99';
export const Purple_Light_900 = '#29206E';

export const Purple_Dark_050 = '#271F65';
export const Purple_Dark_100 = '#352A86';
export const Purple_Dark_150 = '#4436A8';
export const Purple_Dark_200 = '#5647C2';
export const Purple_Dark_300 = '#6759D2';
export const Purple_Dark_400 = '#8F82FF';
export const Purple_Dark_500 = '#A198F3';
export const Purple_Dark_600 = '#B8B2F3';
export const Purple_Dark_700 = '#CECAF3';
export const Purple_Dark_800 = '#DEDCF3';
export const Purple_Dark_900 = '#EAEAF3';

export const Red_Light_100 = '#FFCDCC';
export const Red_Light_200 = '#FF4D4D';
export const Red_Light_300 = '#C31E18';
export const Red_Light_400 = '#9C1510';

export const Red_Dark_100 = '#EDBFBE';
export const Red_Dark_200 = '#E84747';
export const Red_Dark_300 = '#B21E18';
export const Red_Dark_400 = '#8F1511';

export const Yellow_Light_100 = '#FFDCAD';
export const Yellow_Light_200 = '#FFC274';
export const Yellow_Light_300 = '#FFA93B';
export const Yellow_Light_400 = '#F5833D';

export const Yellow_Dark_100 = '#EDCCA2';
export const Yellow_Dark_200 = '#EDB56D';
export const Yellow_Dark_300 = '#ED9D38';
export const Yellow_Dark_400 = '#E37B3A';

export const Green_Light_100 = '#80D889';
export const Green_Light_200 = '#5AC366';
export const Green_Light_300 = '#44944E';
export const Green_Light_400 = '#36753D';

export const Green_Dark_100 = '#71BB78';
export const Green_Dark_200 = '#51AA5B';
export const Green_Dark_300 = '#3E8246';
export const Green_Dark_400 = '#326838';

export const Brown_Light_100 = '#F2CEAA';
export const Brown_Light_200 = '#DEA66F';
export const Brown_Light_300 = '#CC884B';
export const Brown_Light_400 = '#8F4E18';

export const Brown_Dark_100 = '#DDBC9B';
export const Brown_Dark_200 = '#CA9867';
export const Brown_Dark_300 = '#BA7D46';
export const Brown_Dark_400 = '#7D4618';

export const Lavender_Light_100 = '#EBC7FC';
export const Lavender_Light_200 = '#E1AEF9';
export const Lavender_Light_300 = '#CD99F9';
export const Lavender_Light_400 = '#B984F9';

export const Lavender_Dark_100 = '#CCADDA';
export const Lavender_Dark_200 = '#C398D7';
export const Lavender_Dark_300 = '#B286D7';
export const Lavender_Dark_400 = '#A174D7';

export const Sky_Blue_Light_100 = '#B6D6FA';
export const Sky_Blue_Light_200 = '#98C7FA';
export const Sky_Blue_Light_300 = '#80B7F4';
export const Sky_Blue_Light_400 = '#65A7F0';

export const Sky_Blue_Dark_100 = '#A4C5EB';
export const Sky_Blue_Dark_200 = '#8DB6E4';
export const Sky_Blue_Dark_300 = '#76A7DE';
export const Sky_Blue_Dark_400 = '#5A91D0';

export const brand_color = Pink_Light_400;
