import { NAVIGATION_SIZE } from '../constants';
import { ProductCardCarouselSlidesPerView } from '../types';

export function calculateTopStyle(options: {
  itemMaxWidth: number;
  itemMinWidth: number;
  /**
   * 상품 카드 썸네일 비율 (예: 1:1, 1:1.2 등등...)
   * @default 1.2
   */
  itemRatio?: number;
  slidesPerView: ProductCardCarouselSlidesPerView;
  spaceBetween: number;
  slidesOffsetBefore: number;
  /** @default 32 */
  navigationSize?: number;
}) {
  const {
    itemMaxWidth,
    itemMinWidth,
    itemRatio = 1.2,
    spaceBetween,
    slidesPerView,
    slidesOffsetBefore,
    navigationSize = NAVIGATION_SIZE,
  } = options;

  const totalSpacing = spaceBetween * (slidesPerView - 1);
  const totalOffset = totalSpacing + slidesOffsetBefore;
  const spacingPerSlide = totalOffset / slidesPerView;
  const totalMinWidthPerSlide = spacingPerSlide + itemMinWidth;
  const totalMaxWidthPerSlide = spacingPerSlide + itemMaxWidth;
  const totalSlidesMinWidth = totalMinWidthPerSlide * slidesPerView;
  const totalSlidesMaxWidth = totalMaxWidthPerSlide * slidesPerView;

  const itemMinHeight = itemMinWidth * itemRatio;
  const itemMaxHeight = itemMaxWidth * itemRatio;

  const itemMinNavTop = itemMinHeight / 2 + navigationSize / 2;
  const itemMaxNavTop = itemMaxHeight / 2 + navigationSize / 2;

  const vw = (itemMaxHeight / totalSlidesMaxWidth) * 100;

  const vwForTop = vw / 2;

  const topCSSValue = `clamp(${itemMinNavTop}px, calc(${vwForTop}vw + ${slidesOffsetBefore}px), ${itemMaxNavTop}px)`;
  const cssValue = `top: ${topCSSValue};
    @supports not (top: ${topCSSValue}) {
      top: ${itemMinNavTop}px;
      @media screen and (min-width: ${totalSlidesMinWidth}px) {
        top: calc(${vwForTop}vw + ${slidesOffsetBefore}px);
      }
      @media screen and (min-width: ${totalSlidesMaxWidth}px) {
        top: ${itemMaxNavTop}px;
      }
    }`;

  return {
    itemMinNavTop,
    itemMaxNavTop,
    vwForTop,
    totalSlidesMinWidth,
    totalSlidesMaxWidth,
    topCSSValue,
    cssValue,
  };
}
