import { RefObject, useCallback, useRef } from 'react';
import { ProductCardSize } from '@croquiscom-pvt/zds';

import { useResizeObserverSelector } from '../../resize-observer/store';
import { useProductCardCarouselContext } from '../Carousel';
import { carouselLayoutManager } from '../carouselLayoutManager';

export interface VerticalProductCardSizerProps {
  /** @default medium */
  defaultSize?: ProductCardSize;
  children: (props: { ref: RefObject<HTMLDivElement>; size: ProductCardSize }) => JSX.Element;
}

export const VerticalProductCardSizer = (props: VerticalProductCardSizerProps) => {
  const { defaultSize = 'medium', children } = props;

  const ref = useRef<HTMLDivElement>(null);

  const { slidesPerView } = useProductCardCarouselContext();
  const size = useResizeObserverSelector<ProductCardSize>(
    ref,
    useCallback(
      (rect, windowWidth) => {
        const MEDIUM_SIZE_THUMBNAIL_MIN_WIDTH = 158;

        if (!rect) {
          return carouselLayoutManager.estimateWidthBySlides(windowWidth, slidesPerView) >=
            MEDIUM_SIZE_THUMBNAIL_MIN_WIDTH
            ? 'medium'
            : 'small';
        }

        const { width } = rect;

        return width >= MEDIUM_SIZE_THUMBNAIL_MIN_WIDTH ? 'medium' : 'small';
      },
      [slidesPerView],
    ),
    useCallback(() => {
      return defaultSize;
    }, [defaultSize]),
  );

  return children({ ref, size });
};
