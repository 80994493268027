import React, { forwardRef } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { BrandColorType, useBrandStyle } from '@common/styles';

export type WaveDotsProps = React.HTMLAttributes<HTMLDivElement>;

export const WaveDots = forwardRef<HTMLDivElement, WaveDotsProps>((props, ref) => {
  const { brand_color } = useBrandStyle();

  return (
    <Balls ref={ref} {...props}>
      <Ball brand_color={brand_color} />
      <Ball brand_color={brand_color} />
      <Ball brand_color={brand_color} />
      <Ball brand_color={brand_color} />
    </Balls>
  );
});

const loadingAnimation = keyframes`
  from {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(7px);
  }
  75% {
    transform: translateY(-7px);
  }
  to {
    transform: translateY(0px);
  }
`;

const Balls = styled.div`
  width: 44px;
  height: 5px;
  margin: 0 auto;
  text-align: center;
`;

const Ball = styled.div<{ brand_color: BrandColorType }>`
  width: 11.37%;
  height: 100%;
  border-radius: 50%;
  vertical-align: top;
  display: inline-block;
  background-color: ${({ brand_color }) => brand_color.Brand_400};
  animation: ${loadingAnimation} 400ms infinite;
  &:nth-of-type(1) {
    animation-delay: 0ms;
  }
  &:nth-of-type(2) {
    animation-delay: 80ms;
  }
  &:nth-of-type(3) {
    animation-delay: 160ms;
  }
  &:nth-of-type(4) {
    animation-delay: 240ms;
  }
  &:not(:last-child) {
    margin-right: 8px;
  }
`;
