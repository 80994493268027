import React from 'react';

export const HeartIcon = ({ width = 32, height = 32, color = '#000000', ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} color={color} fill='none' viewBox='0 0 32 32' {...props}>
    <path
      d='M20.5455 7C23.5579 7 26 9.51875 26 12.6258C26 14.0322 25.2703 15.4387 25.2703 15.4387C23.2459 19.9302 16 25 16 25C16 25 8.75413 19.9302 6.72972 15.4387C6.72972 15.4387 6 14.0322 6 12.6258C6 9.51875 8.44208 7 11.4545 7C13.3518 7 15.0228 7.99905 16 9.51492C16.9772 7.99905 18.6482 7 20.5455 7Z'
      stroke='currentColor'
      stroke-width='1.5'
    />
  </svg>
);
