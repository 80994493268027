import { callZigzagFunction } from '../../callZigzagFunction';
import { zigzagHandler } from '../../handlers';
import { SavedProduct, SavedProductStatus } from '../../types';

// 내 상품 여부 확인 요청
export const csp = (goodsList: SavedProduct[]) => {
  return new Promise<SavedProductStatus[]>((resolve) => {
    callZigzagFunction('csp')({ goodsList });
    zigzagHandler('onSavedProductStatus', (savedProductStatusList) => {
      resolve(savedProductStatusList);
    });
  });
};
