export const getPurPoseType = (type?: string) => {
  switch (type) {
    case 'FindPassword':
      return 'FIND_PASSWORD';
    case 'FindAccount':
      return 'FIND_ACCOUNT';
    case 'Login2Fa':
      return 'LOGIN_2FA';
    default:
      return 'ACCOUNT';
  }
};
