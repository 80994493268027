import type { ProductCardSize } from '@croquiscom-pvt/zds';
import debounce from 'lodash/debounce';

import { ColumnCountTypeWithoutAuto } from './types';

export interface GridLayoutManagerOptions {
  marginLeft: number;
  marginRight: number;
  spacing: number;
}

interface GridLayoutManagerStateProductCardSizeMapItem {
  2: ProductCardSize;
  3: ProductCardSize;
}

export interface GridLayoutManagerState {
  columnCount: ColumnCountTypeWithoutAuto;
  productCardSizeMap: {
    v4Basic: GridLayoutManagerStateProductCardSizeMapItem;
    v4Content: GridLayoutManagerStateProductCardSizeMapItem;
  };
}

type UnsubscribeFn = () => void;

type SetColumnCountFn = (newColumnCount: GridLayoutManagerState['columnCount']) => void;
type SetProductCardSizeMapFn = (newProductCardSizeMap: GridLayoutManagerState['productCardSizeMap']) => void;

type SubscribeColumnCountCallback = SetColumnCountFn;
type SubscribeProductCardSizeMapCallback = SetProductCardSizeMapFn;

export const DEFAULT_COLUMN_COUNT: ColumnCountTypeWithoutAuto = 2;
export const DEFAULT_SIZE: ProductCardSize = 'medium';

// 지그재그 서비스 최대 너비값 (600px)
const MAX_WIDTH = 600;
// 웹 반응형 너비값 (450px) (columnCount를 auto로 사용할 경우, 450px 이하일 때 2개의 column, 이상일 때 3개의 column으로 나눕니다.)
const WEB_RESPONSIVE_WIDTH = 450;
const MEDIUM_SIZE_THUMBNAIL_MIN_WIDTH = 158;

const DEFAULT_STATE: GridLayoutManagerState = {
  columnCount: DEFAULT_COLUMN_COUNT,
  productCardSizeMap: {
    v4Basic: {
      2: DEFAULT_SIZE,
      3: DEFAULT_SIZE,
    },
    v4Content: {
      2: DEFAULT_SIZE,
      3: DEFAULT_SIZE,
    },
  },
};

export function createGridLayoutManager() {
  const subscribers = new Set<SubscribeProductCardSizeMapCallback>();
  const columnCountSubscribers = new Set<SubscribeColumnCountCallback>();

  const state: GridLayoutManagerState = DEFAULT_STATE;

  const getProductCardSizeMapByWindowInnerWidth = (
    innerWidth: number,
  ): GridLayoutManagerState['productCardSizeMap'] => {
    const width = getMinWidth(innerWidth);

    const getProductCardSize = (type: 'v4Basic' | 'v4Content'): GridLayoutManagerStateProductCardSizeMapItem => {
      let margin: number;
      let spacing: number;
      const thumbnailMinWidth = MEDIUM_SIZE_THUMBNAIL_MIN_WIDTH;

      if (type === 'v4Basic') {
        margin = 0;
        spacing = 2;
      } else {
        margin = 16;
        spacing = 2;
      }

      // 2개의 column일 때, 3개의 column일 때의 상품 카드 사이즈를 계산합니다.
      return {
        2: (width - margin - margin - spacing) / 2 >= thumbnailMinWidth ? 'medium' : 'small',
        3: (width - margin - margin - spacing * 2) / 3 >= thumbnailMinWidth ? 'medium' : 'small',
      };
    };

    return {
      v4Basic: getProductCardSize('v4Basic'),
      v4Content: getProductCardSize('v4Content'),
    };
  };

  const setColumnCount: SetColumnCountFn = (newColumnCount) => {
    state.columnCount = newColumnCount;
  };

  const setProductCardSizeMap: SetProductCardSizeMapFn = (newProductCardSizeMap) => {
    state.productCardSizeMap = newProductCardSizeMap;
  };

  const init = () => {
    setProductCardSizeMap(getProductCardSizeMapByWindowInnerWidth(window.innerWidth));
    setColumnCount(getColumnCountByWindowInnerWidth(window.innerWidth));
  };

  const subscribeProductCardSizeMap = (callback: SubscribeProductCardSizeMapCallback) => {
    const handleResize = debounce(() => {
      const newProductCardSizeMap = getProductCardSizeMapByWindowInnerWidth(window.innerWidth);
      setProductCardSizeMap(newProductCardSizeMap);
      subscribers.forEach((cb) => cb(newProductCardSizeMap));
    }, 300);

    if (subscribers.size === 0) {
      window.addEventListener('resize', handleResize);
    }

    subscribers.add(callback);

    const unsubscribe: UnsubscribeFn = () => {
      subscribers.delete(callback);

      if (subscribers.size === 0) {
        window.removeEventListener('resize', handleResize);
      }
    };

    return unsubscribe;
  };

  const subscribeColumnCount = (callback: SubscribeColumnCountCallback) => {
    const matchMedia = window.matchMedia(`(min-width: ${WEB_RESPONSIVE_WIDTH}px)`);

    const changeHandler = (e: MediaQueryListEvent) => {
      const columnCount = getColumnCountByMatches(e.matches);
      columnCountSubscribers.forEach((cb) => cb(columnCount));
    };

    if (columnCountSubscribers.size < 1) {
      attachMediaListener(matchMedia, changeHandler);
    }

    columnCountSubscribers.add(callback);

    const unsubscribe: UnsubscribeFn = () => {
      columnCountSubscribers.delete(callback);
      if (columnCountSubscribers.size < 1) {
        detachMediaListener(matchMedia, changeHandler);
      }
    };

    return unsubscribe;
  };

  return {
    init,
    state,
    subscribeProductCardSizeMap,
    subscribeColumnCount,
  };
}

export const gridLayoutManager = createGridLayoutManager();

function getMinWidth(innerWidth: number) {
  return Math.min(innerWidth, MAX_WIDTH);
}

export function getColumnCountByWindowInnerWidth(innerWidth: number): ColumnCountTypeWithoutAuto {
  const width = getMinWidth(innerWidth);
  return width > WEB_RESPONSIVE_WIDTH ? 3 : 2;
}

function getColumnCountByMatches(matches: boolean): ColumnCountTypeWithoutAuto {
  return matches ? 3 : 2;
}

// https://stackoverflow.com/questions/56466261/matchmedia-addlistener-marked-as-deprecated-addeventlistener-equivalent
// 사파리 14버전(2020년 릴리즈)부터는 matchMedia에서 addEventListener를 사용할 수 있으나, 이전 버전에서는 addListener를 사용해야 하므로 try catch로 분기처리를 해주었습니다.
function attachMediaListener(query: MediaQueryList, callback: (event: MediaQueryListEvent) => void) {
  try {
    query.addEventListener('change', callback);
  } catch (e) {
    query.addListener(callback);
  }
}

// https://stackoverflow.com/questions/56466261/matchmedia-addlistener-marked-as-deprecated-addeventlistener-equivalent
// 사파리 14버전(2020년 릴리즈)부터는 matchMedia에서 addEventListener를 사용할 수 있으나, 이전 버전에서는 addListener를 사용해야 하므로 try catch로 분기처리를 해주었습니다.
function detachMediaListener(query: MediaQueryList, callback: (event: MediaQueryListEvent) => void) {
  try {
    query.removeEventListener('change', callback);
  } catch (e) {
    query.removeListener(callback);
  }
}
