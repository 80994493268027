import isArray from 'lodash/fp/isArray';
import isPlainObject from 'lodash/fp/isPlainObject';
import join from 'lodash/fp/join';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import toPairs from 'lodash/fp/toPairs';

export const toQueryString = pipe(
  toPairs,
  map(([key, v]) => {
    const value = isArray(v) || isPlainObject(v) ? JSON.stringify(v) : v;
    return join('=')([key, value]);
  }),
  join('&'),
);
