import { useMemo } from 'react';

import { ColumnCountTypeWithoutAuto } from '../types';

export function useDividedItemsWithColumnCount<D>(items: D[], columnCount: ColumnCountTypeWithoutAuto | null) {
  const diviededItems = useMemo(() => {
    if (columnCount === null) {
      return [];
    }

    const result: D[][] = [];
    for (let i = 0; i < items.length; i += columnCount) {
      const row: D[] = [];
      for (let j = 0; j < columnCount; j++) {
        row.push(items[i + j]);
      }
      result.push(row);
    }
    return result;
  }, [columnCount, items]);

  return diviededItems;
}
