import { forwardRef, ReactNode } from 'react';
import { ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';

export interface ProductCardRootProps extends Pick<ProductCardProps, 'size' | 'soldOut'> {
  children: ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const productCardCss = css`
  position: relative;
`;

export const ProductCardRoot = forwardRef<HTMLDivElement, ProductCardRootProps>((props, ref) => {
  const { className, size, soldOut, onClick, children } = props;

  return (
    <ProductCard className={className} css={productCardCss} ref={ref} size={size} soldOut={soldOut} onClick={onClick}>
      {children}
    </ProductCard>
  );
});
