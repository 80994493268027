import Head from 'next/head';

import { getConfig } from '@common/config';

const config = getConfig();

export const PreloadFont = () => {
  return (
    <Head>
      <link
        rel='preload'
        href={`${config.resUrl}/fonts/Pretendard/Pretendard-Regular.subset.woff2`}
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        href={`${config.resUrl}/fonts/Pretendard/Pretendard-Medium.subset.woff2`}
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        href={`${config.resUrl}/fonts/Pretendard/Pretendard-SemiBold.subset.woff2`}
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        href={`${config.resUrl}/fonts/Pretendard/Pretendard-Bold.subset.woff2`}
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />
    </Head>
  );
};
