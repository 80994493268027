import React, { createContext } from 'react';

export interface AppConfigContextValue {
  isZigzagApp: boolean;
  zigzagUuid: string | null;
}

const defaultValue: AppConfigContextValue = {
  isZigzagApp: false,
  zigzagUuid: null,
};

const AppConfigContext = createContext<AppConfigContextValue>(defaultValue);

export interface AppConfigProviderProps extends Partial<AppConfigContextValue> {
  children: React.ReactChild;
}

export const AppConfigProvider = (props: AppConfigProviderProps) => {
  const { isZigzagApp = defaultValue.isZigzagApp, zigzagUuid = defaultValue.zigzagUuid, children } = props;

  return <AppConfigContext.Provider value={{ isZigzagApp, zigzagUuid }}>{children}</AppConfigContext.Provider>;
};

export function useAppConfig() {
  return React.useContext(AppConfigContext);
}
