import { Gray_Light_150, Pink_Light_400 } from '@common/styles';

export const IconCheckSmallSelected = ({
  width = 16,
  height = 16,
  color = Pink_Light_400,
  disable_color = Gray_Light_150,
  disabled,
  ...props
}: JSX.IntrinsicElements['svg'] & {
  disable_color?: string;
  disabled?: boolean;
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    color={disabled ? disable_color : color}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8ZM12.4761 6.03039L11.4155 4.96973L6.78204 9.60315L4.03033 6.85144L2.96967 7.9121L6.78204 11.7245L12.4761 6.03039Z'
      fill='currentColor'
    />
  </svg>
);
