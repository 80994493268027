import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import replace from 'lodash/fp/replace';
import split from 'lodash/fp/split';
import toNumber from 'lodash/fp/toNumber';

export const getAppVersion = (userAgent = navigator?.userAgent) => {
  if (typeof window !== 'undefined' && (window as any).zigzagAppVersion) {
    return (window as any).zigzagAppVersion as string;
  }
  const exp_match = userAgent?.match(/ZigZag\/\D*(\d+(\.\d+)+)/);
  if (exp_match) {
    return exp_match[1];
  }
  return;
};

const toBeParts = pipe(split('.'), map(pipe(replace(/\D+/g, ''), toNumber)));

const compareVersion = (v1: string, v2: string) => {
  const v1_parts = toBeParts(v1);
  const v2_parts = toBeParts(v2);

  if (v1_parts.length !== v2_parts.length) {
    return false;
  }
  for (let i = 0; i < v2_parts.length; i++) {
    if (v1_parts[i] === v2_parts[i]) {
      continue;
    } else if (v1_parts[i] > v2_parts[i]) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const compareWithAppVersion = (version: string, userAgent = navigator?.userAgent) => {
  const app_version = getAppVersion(userAgent);
  if (!app_version) {
    return false;
  }
  return compareVersion(app_version, version);
};
