import { forwardRef, ReactNode } from 'react';
import { ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';

export interface ProductCardRootProps extends Pick<ProductCardProps, 'size' | 'soldOut'> {
  children: ReactNode;
  className?: string;
}

const productCardCss = css`
  position: relative;
  display: flex;
`;

export const ProductCardRoot = forwardRef<HTMLDivElement, ProductCardRootProps>((props, ref) => {
  const { size, soldOut, children, className } = props;

  return (
    <ProductCard css={productCardCss} className={className} ref={ref} size={size} soldOut={soldOut}>
      {children}
    </ProductCard>
  );
});
