import isArray from 'lodash/isArray';

import { isDev } from '../conditions';
import { toQueryString } from '../router';

interface LoggerParams {
  iosParam: any;
  aosParam: any;
}

type EventType = 'zigzag' | 'zpay' | 'kakaostyle-mkt' | 'ksUmd';

export const devLogger =
  (eventType: EventType, functionName: string, eventName?: string) =>
  <P extends LoggerParams>(params: P) => {
    if (isDev()) {
      const { iosParam, aosParam } = params;
      console.groupCollapsed(`${eventType} - ${functionName} ${eventName ?? ''}`);
      if (eventType === 'ksUmd') {
        console.info('%cIOS', 'color: #147EFB', iosParam);
        console.info('%cAOS', 'color: #32DE84', ...aosParam);
      } else {
        console.info('%cIOS', 'color: #147EFB', iosParam);
        console.info('%cAOS', 'color: #32DE84', ...(isArray(aosParam) ? aosParam : [aosParam]));
      }
      console.groupEnd();
    }
    return params;
  };

export const devLoggerDeepLink =
  (path: string) =>
  (query: object = {}) => {
    if (isDev()) {
      const queryString = toQueryString(query);
      const deeplinkUrl = !queryString ? `zigzag://${path}` : `zigzag://${path}?${queryString}`;
      console.groupCollapsed(`deeplink - ${deeplinkUrl}`);
      console.info('%cpath', 'color: #E5C07B', path);
      console.info('%cquery', 'color: #61AFEF', query);
      console.groupEnd();
    }
    return query;
  };
