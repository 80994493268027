import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Gray_Light_100,
  Gray_Light_150,
  Gray_Light_300,
  Gray_Light_400,
  Pink_Light_400,
  Typography,
  white,
} from '@common/styles';

import { cx } from '@emotion/css';

export const TEXTAREA_MODE = {
  'normal': 'normal',
  'save': 'save',
  'save-view-edit': 'save-view-edit',
};

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  changeEvent?: (value: string) => void;
  onSave?: () => Promise<boolean>;
  mode?: keyof typeof TEXTAREA_MODE;
  labelCss?: SerializedStyles;
};

function textLengthReducer(state: any, action: any) {
  const maxLength = action.maxLength;
  if (maxLength) {
    const textarea = action.textareaRef.current;
    if (textarea) {
      const overLength = textarea.value.length - maxLength;
      if (0 < overLength) {
        if (overLength === 1) {
          const selection = textarea.selectionStart - 1;
          textarea.value = state.prevValue;
          textarea.setSelectionRange(selection, selection);
        } else {
          textarea.value = textarea.value.slice(0, maxLength);
        }
      }
      return {
        prevValue: textarea.value,
        textLength: textarea.value.length,
      };
    }
  }
  return {};
}

export const TextArea = ({
  children,
  className,
  maxLength,
  changeEvent,
  onSave,
  onFocus,
  onClick,
  mode = 'normal',
  labelCss: label_custom_css,
  ...otherProps
}: TextAreaProps) => {
  const { t } = useTranslation('common');

  const textareaRef: React.RefObject<HTMLTextAreaElement> = useRef(null);
  const [textLengthState, textLengthDispatch] = useReducer(textLengthReducer, {
    prevValue: '',
    textLength: 0,
  });
  const [isFocus, setIsFocus] = useState(false);

  const [isRequested, setIsRequested] = useState(false);

  const textLengthStatus = `${textLengthState.textLength}/${maxLength}`;
  const hasMaxLength = !!maxLength;

  const onFocusTextArea: React.FocusEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      onFocus?.(event);
      if (mode === 'save-view-edit' && !isRequested) {
        setIsFocus(true);
      }
    },
    [isRequested, mode, onFocus],
  );

  const onClickTextArea: React.MouseEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      onClick?.(event);
      if (mode === 'save-view-edit') {
        setIsFocus(true);
      }
    },
    [mode, onClick],
  );

  const textarea = textareaRef.current;

  const handleOnSave: React.MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    setIsRequested(true);

    try {
      await onSave?.();
    } finally {
      setIsRequested(false);
      setIsFocus(false);
      textarea?.blur();
    }
  }, [onSave, textarea]);

  const onInput = () =>
    textLengthDispatch({
      maxLength,
      textareaRef,
    });

  const onChange = () => {
    if (changeEvent) {
      changeEvent(textarea ? (maxLength ? textarea.value.slice(0, maxLength) : textarea.value) : '');
    }
  };
  useEffect(onInput, [maxLength, otherProps.value]);

  const showBottom = useMemo(() => {
    const normalWithText = mode === 'normal' && hasMaxLength;
    const saveMode = mode === 'save';
    const saveViewEditModeAndFocused = mode === 'save-view-edit' && isFocus && !isRequested;

    return normalWithText || saveMode || saveViewEditModeAndFocused;
  }, [mode, hasMaxLength, isFocus, isRequested]);

  return (
    <Label
      maxLength={maxLength}
      showBottom={showBottom}
      css={css`
        ${label_custom_css}
      `}
    >
      <Textarea
        ref={textareaRef}
        className={cx(Typography.BODY_15_MEDIUM, className)}
        onInput={onInput}
        onChange={onChange}
        showBottom={showBottom}
        onFocus={onFocusTextArea}
        onClick={onClickTextArea}
        {...otherProps}
      >
        {children}
      </Textarea>
      {mode === 'normal' && hasMaxLength && (
        <BottomLayout hasMaxLength={hasMaxLength} mode={mode}>
          <Counter className={Typography.CAPTION_12_SEMIBOLD}>{textLengthStatus}</Counter>
        </BottomLayout>
      )}
      {(mode === 'save' || (mode === 'save-view-edit' && isFocus && !isRequested)) && (
        <BottomLayout hasMaxLength={hasMaxLength} mode={mode}>
          {hasMaxLength && <Counter className={Typography.CAPTION_12_SEMIBOLD}>{textLengthStatus}</Counter>}
          <SaveButton
            className={Typography.BODY_14_SEMIBOLD}
            onClick={handleOnSave}
            disabled={textarea?.value.length === 0}
          >
            {t('저장')}
          </SaveButton>
        </BottomLayout>
      )}
    </Label>
  );
};

const Label = styled.label<{ maxLength: number | undefined; showBottom: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  padding: 12px;
  width: 100%;
  height: ${({ showBottom }) => (!showBottom ? '88px' : '122px')};
  border-radius: 8px;
  border: 1px solid ${Gray_Light_150};
`;

const Textarea = styled.textarea<{ showBottom: boolean }>`
  box-sizing: border-box;
  height: 100%;
  line-height: 1.35;
  resize: none;
  &::placeholder {
    color: ${Gray_Light_300};
  }
`;

const Counter = styled.div`
  color: ${Gray_Light_300};
  line-height: 1.235;
`;

const BottomLayout = styled.div<{ hasMaxLength: boolean; mode: keyof typeof TEXTAREA_MODE }>`
  display: flex;
  justify-content: ${({ hasMaxLength, mode }) =>
    (mode === 'save' && !hasMaxLength) || (mode === 'normal' && hasMaxLength) ? 'end' : 'space-between'};
  margin-top: 8px;
  padding-top: 8px;
  border-top: solid 1px ${Gray_Light_100};
`;

const SaveButton = styled.button`
  color: ${Pink_Light_400};
  cursor: pointer;

  :disabled {
    color: ${Gray_Light_400};
    background: ${white};
    cursor: default;
  }
`;
