import React from 'react';

export const CouponIcon = ({ width = 32, height = 32, color = '#000000', ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width={width} height={height} color={color} fill='none' viewBox='0 0 32 32' {...props}>
    <path
      d='M6 13.25H5.75V7.75H26.25V13.25H26C25.2561 13.25 24.5749 13.6144 24.0947 14.0947C23.6144 14.5749 23.25 15.2561 23.25 16C23.25 16.7439 23.6144 17.4251 24.0947 17.9053C24.5749 18.3856 25.2561 18.75 26 18.75H26.25V24.25H5.75V18.75H6C6.74392 18.75 7.42507 18.3856 7.90533 17.9053C8.38559 17.4251 8.75 16.7439 8.75 16C8.75 15.2561 8.38559 14.5749 7.90533 14.0947C7.42507 13.6144 6.74392 13.25 6 13.25Z'
      stroke='currentColor'
      stroke-width='1.5'
    />
    <path d='M19 13L13 19' stroke='currentColor' stroke-width='1.5' />
    <circle cx='13.5' cy='13.5' r='1.5' fill='currentColor' />
    <circle cx='18.5' cy='18.5' r='1.5' fill='currentColor' />
  </svg>
);
