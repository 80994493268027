import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconCheckRegular32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={1.5} d='m8 15 6 6L24 9' />
    </Svg>
  );
};
export default SvgIconCheckRegular32;
