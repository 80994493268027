import { ProductCard } from '@croquiscom-pvt/zds';

import { useMetadataPlpBadgeItems, UseMetadataPlpBadgeItemsOptions } from '../hooks/useMetadataPlpBadgeItems';

export interface MetadataPlpBadgeItemsProps extends UseMetadataPlpBadgeItemsOptions {
  className?: string;
}

export const MetadataPlpBadgeItems = (props: MetadataPlpBadgeItemsProps) => {
  const { className, size, plpBadgeList } = props;

  const { hasPlpBadgeItems, metadataPlpBadgeItemsProps } = useMetadataPlpBadgeItems({ size, plpBadgeList });

  if (!hasPlpBadgeItems) {
    return null;
  }

  return (
    <ProductCard.MetadataItem className={className} marginTop={size === 'small' ? 6 : 8}>
      <ProductCard.MetadataBadgeItems itemSize={getPLPBadgeItemsItemSizeBySize(size)} {...metadataPlpBadgeItemsProps} />
    </ProductCard.MetadataItem>
  );
};

export function getPLPBadgeItemsItemSizeBySize(size?: MetadataPlpBadgeItemsProps['size']) {
  return size === 'small' ? '16px' : '20px';
}
