import Head from 'next/head';

export function Preconnect() {
  return (
    <Head>
      <link rel='preconnect' href='https://www.zigzag.kr' />
      <link rel='preconnect' href='https://cf.shop.s.zigzag.kr' />
      <link rel='preconnect' href='https://cf.res.s.zigzag.kr' />
      <link rel='preconnect' href='https://cf.image-farm.s.zigzag.kr' />
      <link rel='preconnect' href='https://cf.product-image.s.zigzag.kr' />
      <link rel='preconnect' href='https://content.zigzag.kr' />
    </Head>
  );
}
