import getNextConfig from 'next/config';

import { getRuntimeConfigValue } from '@common/next-runtime-config';
import { SiteId } from '@common/site-manager';

export const getConfig = () => {
  try {
    const nextConfig = getNextConfig();

    if (!nextConfig) {
      return process.env;
    }

    return nextConfig.publicRuntimeConfig.config;
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getConfigValue = (key: keyof BaseConfigType, site_id?: SiteId) => {
  try {
    const nextConfig = getNextConfig();

    if (!nextConfig) {
      return process.env[key];
    }

    return getRuntimeConfigValue(key, site_id);
  } catch (e) {
    console.log(e);
    return '';
  }
};
