/* eslint-disable */
type CrTimestamp = number;
type CrJson = any;
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PriceWithCurrencyPart
// ====================================================

export interface PriceWithCurrencyPart {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: UxPaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 소수점 올림한 금액
   */
  price_without_decimal: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrencyMeta
// ====================================================

export interface CurrencyMeta {
  /**
   * 통화 정보. Accept-Language 헤더로 들어온 값을 분석해서 이에 맞는 통화로 변환한다.
   */
  currency: PaymentCurrency;
  /**
   * 소수점 자리수
   */
  decimal: number;
  /**
   * 원화에 곱하는 값 (원화 x converted_rate = display 통화)
   */
  converted_rate: number;
  /**
   * 화면에 표시할 통화(원, $, yen)
   */
  display_currency: string;
  /**
   * 통화 앞에 오는지 여부
   */
  is_currency_prefix: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * 환율 enum
 */
export enum PaymentCurrency {
  AUD = "AUD",
  CAD = "CAD",
  CNY = "CNY",
  EUR = "EUR",
  GBP = "GBP",
  JPY = "JPY",
  KRW = "KRW",
  NZD = "NZD",
  USD = "USD",
}

export enum UxPaymentCurrency {
  CAD = "CAD",
  JPY = "JPY",
  KRW = "KRW",
  USD = "USD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
