import { ProductCardCarouselSlidesPerView } from './types';

export function getSlidesPerViewClassNameMap(prefix: string) {
  const classNameMap = {
    '2.12': prefix + '-2-12',
    '2.5': prefix + '-2-5',
    '3.14': prefix + '-3-14',
  } as Record<ProductCardCarouselSlidesPerView, string>;

  return classNameMap;
}
