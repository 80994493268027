export const APP_DEFAULT_SEO = {
  title: '지그재그 스토어',
  description: '4,000만 여성이 선택한 올인원 쇼핑 앱 지그재그 - 제가 알아서 살게요.',
  site_name: '지그재그 스토어',
  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    title: '지그재그 스토어',
    description: '4,000만 여성이 선택한 올인원 쇼핑 앱 지그재그 - 제가 알아서 살게요.',
    images: [
      {
        url: 'https://cf.image-farm.s.zigzag.kr/original/cms/2024/04/18/202404180541294769_017342.png',
        width: 800,
        height: 400,
        alt: 'zigzag',
        type: 'image/png',
      },
    ],
  },
};
