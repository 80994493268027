import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconCautionRegular32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path stroke='currentColor' strokeWidth={1.5} d='M16 10v8M16 20v2' />
    </Svg>
  );
};
export default SvgIconCautionRegular32;
