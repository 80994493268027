import { HStack, ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';

import { useMetadataFomo, UseMetadataFomoOptions } from '../hooks/useMetadataFomo';
import { useMetadataReview, UseMetadataReviewOptions } from '../hooks/useMetadataReview';
import { Fomo } from './Fomo';

export interface MetadataReviewWithFomoProps
  extends UseMetadataReviewOptions,
    UseMetadataFomoOptions,
    Pick<ProductCardProps, 'size'> {
  className?: string;
}

export const MetadataReviewWithFomo = (props: MetadataReviewWithFomoProps) => {
  const { className, size, review, fomo } = props;

  const { hasReview, metadataReviewProps } = useMetadataReview({ review });
  const { hasFomo, metadataFomoProps } = useMetadataFomo({ fomo });

  if (!hasReview && !hasFomo) {
    return null;
  }

  return (
    <ProductCard.MetadataItem className={className} marginTop={4}>
      <HStack spacing='2px' blockAlign='center'>
        {hasReview && <ProductCard.MetadataReview {...metadataReviewProps} />}
        {hasFomo && <Fomo size={size} {...metadataFomoProps} />}
      </HStack>
    </ProductCard.MetadataItem>
  );
};
