export enum Typography {
  // Pretendard
  HEAD_28_BOLD = 'HEAD_28 BOLD',
  HEAD_28_SEMIBOLD = 'HEAD_28 SEMIBOLD',
  HEAD_28_MEDIUM = 'HEAD_28 MEDIUM',
  HEAD_28_REGULAR = 'HEAD_28 REGULAR',

  HEAD_24_BOLD = 'HEAD_24 BOLD',
  HEAD_24_SEMIBOLD = 'HEAD_24 SEMIBOLD',
  HEAD_24_MEDIUM = 'HEAD_24 MEDIUM',
  HEAD_24_REGULAR = 'HEAD_24 REGULAR',

  HEAD_22_BOLD = 'HEAD_22 BOLD',
  HEAD_22_SEMIBOLD = 'HEAD_22 SEMIBOLD',
  HEAD_22_MEDIUM = 'HEAD_22 MEDIUM',
  HEAD_22_REGULAR = 'HEAD_22 REGULAR',

  HEAD_20_BOLD = 'HEAD_20 BOLD',
  HEAD_20_SEMIBOLD = 'HEAD_20 SEMIBOLD',
  HEAD_20_MEDIUM = 'HEAD_20 MEDIUM',
  HEAD_20_REGULAR = 'HEAD_20 REGULAR',

  BODY_18_BOLD = 'BODY_18 BOLD',
  BODY_18_SEMIBOLD = 'BODY_18 SEMIBOLD',
  BODY_18_MEDIUM = 'BODY_18 MEDIUM',
  BODY_18_REGULAR = 'BODY_18 REGULAR',

  BODY_17_BOLD = 'BODY_17 BOLD',
  BODY_17_SEMIBOLD = 'BODY_17 SEMIBOLD',
  BODY_17_MEDIUM = 'BODY_17 MEDIUM',
  BODY_17_REGULAR = 'BODY_17 REGULAR',

  BODY_16_BOLD = 'BODY_16 BOLD',
  BODY_16_SEMIBOLD = 'BODY_16 SEMIBOLD',
  BODY_16_MEDIUM = 'BODY_16 MEDIUM',
  BODY_16_REGULAR = 'BODY_16 REGULAR',

  BODY_15_BOLD = 'BODY_15 BOLD',
  BODY_15_SEMIBOLD = 'BODY_15 SEMIBOLD',
  BODY_15_MEDIUM = 'BODY_15 MEDIUM',
  BODY_15_REGULAR = 'BODY_15 REGULAR',

  BODY_14_BOLD = 'BODY_14 BOLD',
  BODY_14_SEMIBOLD = 'BODY_14 SEMIBOLD',
  BODY_14_MEDIUM = 'BODY_14 MEDIUM',
  BODY_14_REGULAR = 'BODY_14 REGULAR',

  BODY_13_BOLD = 'BODY_13 BOLD',
  BODY_13_SEMIBOLD = 'BODY_13 SEMIBOLD',
  BODY_13_MEDIUM = 'BODY_13 MEDIUM',
  BODY_13_REGULAR = 'BODY_13 REGULAR',

  CAPTION_12_BOLD = 'CAPTION_12 BOLD',
  CAPTION_12_SEMIBOLD = 'CAPTION_12 SEMIBOLD',
  CAPTION_12_MEDIUM = 'CAPTION_12 MEDIUM',
  CAPTION_12_REGULAR = 'CAPTION_12 REGULAR',

  CAPTION_11_BOLD = 'CAPTION_11 BOLD',
  CAPTION_11_SEMIBOLD = 'CAPTION_11 SEMIBOLD',
  CAPTION_11_MEDIUM = 'CAPTION_11 MEDIUM',
  CAPTION_11_REGULAR = 'CAPTION_11 REGULAR',

  CAPTION_10_BOLD = 'CAPTION_10 BOLD',
  CAPTION_10_SEMIBOLD = 'CAPTION_10 SEMIBOLD',
  CAPTION_10_MEDIUM = 'CAPTION_10 MEDIUM',
  CAPTION_10_REGULAR = 'CAPTION_10 REGULAR',

  CAPTION_9_BOLD = 'CAPTION_9 BOLD',
  CAPTION_9_SEMIBOLD = 'CAPTION_9 SEMIBOLD',
  CAPTION_9_MEDIUM = 'CAPTION_9 MEDIUM',
  CAPTION_9_REGULAR = 'CAPTION_9 REGULAR',
}
