import 'isomorphic-unfetch';

import { isBrowser, toQueryString } from '@croquiscom/web2app';
import * as Sentry from '@sentry/nextjs';
import { splitCookiesString } from 'set-cookie-parser';

import { getConfigValue } from '@common/config';
import { getSiteIdFromHost, SiteId } from '@common/site-manager';

import { checkIsUnknownOrInApp } from '../utils/checkIsUnknownOrInApp';
import { filterZigzagSid } from '../utils/filterZigzagSid';
import { gotoLogin } from '../utils/gotoLogin';
import { mergeCookie } from '../utils/mergeCookie';

import { IncomingHttpHeaders, IncomingMessage } from 'http';

interface IIncomingMessage extends IncomingMessage {}
export type RequestContext = Partial<IIncomingMessage> & {
  cookies?: Partial<{
    [key: string]: string;
  }>;
  headers: IncomingHttpHeaders;
  res?: { setHeader?: (key: string, value: string[]) => void };
};

export interface GqlRequestOptions {
  appendFormdata?: (form_data: FormData) => void;
  context?: RequestContext;
  show_alert?: boolean;
  ignore_error?: ApiErrors;
  use_consumer?: boolean;
  signal?: AbortSignal;
}

export async function gql_request<R, V = unknown>(
  operation_name: string,
  query: string,
  variables?: V,
  options: GqlRequestOptions = {},
) {
  const { appendFormdata, context, show_alert = true, ignore_error, use_consumer, signal } = options;
  let data: object = { query, variables };
  if (appendFormdata != null) {
    const form_data = new FormData();
    form_data.append('operations', JSON.stringify(data));
    appendFormdata(form_data);
    data = form_data;
  }
  const headers: IncomingHttpHeaders = context
    ? {
        'cookie': context.headers.cookie,
        'user-agent': context.headers['user-agent'],
      }
    : {};

  if (context?.headers['x-forwarded-for'] || context?.headers['Remote_Addr']) {
    headers['x-forwarded-for'] = context.headers['x-forwarded-for'] || context.headers['Remote_Addr'];
  }

  if (context?.headers['authorization']) {
    headers['authorization'] = context.headers['authorization'];
  }

  const site_id = getSiteIdFromHost(context);

  const result = await request(
    {
      method: 'POST',
      headers,
      url: `/graphql/${operation_name}`,
      data,
      res: {
        setHeader(key, value) {
          if (context?.res?.setHeader) {
            context.res.setHeader(key, value);
          }
          if (key === 'set-cookie' && context) {
            context.headers.cookie = mergeCookie(context.headers.cookie, value);
          }
        },
      },
    },
    { show_alert, ignore_error, use_consumer, signal, site_id },
  );

  return result as { data: R; errors?: ErrorObject[] };
}

export interface RequestParams<Data = unknown> {
  headers?: object;
  method?: string;
  url: string;
  data?: Data;
  res: {
    setHeader: (key: string, value: string[]) => void;
  };
}

export interface RequestOptions {
  show_alert?: boolean;
  ignore_error?: ApiErrors;
  use_consumer?: boolean;
  signal?: AbortSignal;
  site_id?: SiteId;
}

function createRequestUrl(url: string, use_consumer: boolean, site_id?: SiteId) {
  const base_domain = getConfigValue('baseDomain', site_id);
  const api_consumer_base_url = getConfigValue('apiConsumerBaseUrl', site_id);
  const api_base_url = getConfigValue('apiBaseUrl', site_id);
  const request_url = `${use_consumer ? api_consumer_base_url : api_base_url}/${url.replace(/^\//, '')}`;

  if (typeof window === 'undefined') {
    return request_url;
  }

  const is_proxyman = process.env['NEXT_PUBLIC_PROXYMAN'] === 'true';

  if (is_proxyman) {
    return request_url.replace(`http://${base_domain}:3061/`, '/');
  }

  return request_url;
}

const IS_DEVELOPMENT = (process.env['STAGE'] || process.env.NODE_ENV).includes('dev');

const createAbortSignal = () => {
  if (!IS_DEVELOPMENT) {
    return null;
  }

  if (typeof AbortSignal === 'undefined') {
    return null;
  }

  if (typeof window !== 'undefined') {
    return null;
  }

  return AbortSignal.timeout(3_000);
};

export async function request(params: RequestParams, option: RequestOptions) {
  const { show_alert = true, ignore_error, use_consumer = false, site_id = SiteId.ZIGZAG } = option;

  let url = createRequestUrl(params.url, use_consumer, site_id);

  const signal = option?.signal ?? createAbortSignal();

  const request_init: RequestInit = {
    method: params.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...params.headers,
    },
    credentials: 'include',
    signal,
  };

  if (params.data) {
    if (request_init.method === 'GET') {
      url += `?${toQueryString(params.data as object)}`;
    } else {
      request_init.body = JSON.stringify(params.data);
    }
  }

  const response = await fetch(url, request_init);

  if (response.status === 200) {
    const result = await response.json();
    const set_cookie = response.headers.get('set-cookie');
    if (set_cookie) {
      const params_header = params.headers as IncomingHttpHeaders | undefined;
      const lower_case_url = url.toLowerCase();

      if (lower_case_url.includes('checkout.kakaostyle.com')) {
        // 통합체크아웃에서는 /login일 경우에만 set-cookie
        if (lower_case_url.includes('/login')) {
          params.res.setHeader('set-cookie', splitCookiesString(set_cookie));
        }
      } else {
        // zigzag
        if (params_header?.['user-agent'] && checkIsUnknownOrInApp(params_header['user-agent'])) {
          // user agent가 누락되어 amazon cloudfront가 되거나 inapp 인 경우 connect.sid 필터처리
          const modified_cookie_array = filterZigzagSid(splitCookiesString(set_cookie));
          params.res.setHeader('set-cookie', splitCookiesString(modified_cookie_array));
        } else {
          params.res.setHeader('set-cookie', splitCookiesString(set_cookie));
        }
      }
    }
    if (result.errors && result.errors.length > 0 && (!ignore_error || result.errors[0].message !== ignore_error)) {
      await handleError(result.errors[0], show_alert, url);
    }
    return result;
  } else {
    const error = await response.json();
    await handleError(error, show_alert, url);
  }
}

export enum ApiErrors {
  NOT_LOGGED_IN = 'not logged in',
}

export enum ApiErrorCodes {
  INSUFFICIENT_PRODUCT_QUANTITY = 'insufficient_product_quantity',
  ORDER_ITEM_PRODUCT_ITEM_SOLDOUT = 'order_item_product_item_soldout',
  CHECKOUT_HAS_INVALID_PRODUCT = 'checkout_has_invalid_product',
  CHECKOUT_HAS_INVALID_AMOUNT = 'checkout_has_invalid_amount',
  CHECKOUT_INVALID_TOTAL_DISCOUNT_AMOUNT = 'checkout_invalid_total_discount_amount',
  INVALID_ARGUMENTS = 'invalid_arguments',
  EXCEEDED_PRODUCT_QUANTITY = 'exceeded_product_quantity',
  CHECKOUT_UPDATE_FAILED = 'checkout_update_failed',
  ORDER_FAILED_BY_ADDITIONAL_PRODUCT = 'order_failed_by_additional_product',
  INVALID_PROMOTION_ORDER_AMOUNT = 'invalid_promotion_order_amount',
  ORDER_SHEET_HAS_INVALID_PROMOTION = 'order_sheet_has_invalid_promotion',
  FIRST_ORDER_PROMOTION_NOT_RUNNING = 'first_order_promotion_not_running',
  INVALID_PROMOTION_USER_CONDITION = 'invalid_promotion_user_condition',
  RAFFLE_PROMOTION_NOT_RUNNING = 'raffle_promotion_not_running',
  RAFFLE_EXCEEDED_PRODUCT_QUANTITY = 'raffle_exceeded_product_quantity',
  ORDER_USE_COUPON_FAILED = 'order_use_coupon_failed',
  EXHAUSTED_LIMITED_ORDER_COUPON = 'exhausted_limited_order_coupon',
  INVALID_ZONLY_SHIPPING_TYPE_EXCEPTION = 'invalid_zonly_shipping_type_exception',
  FIRST_ORDER_EXCEEDED_PRODUCT_QUANTITY = 'first_order_exceeded_product_quantity',
  ORDER_PROMOTION_EXCEEDED_PRODUCT_QUANTITY = 'order_promotion_exceeded_product_quantity',
  ACCESS_TOKEN_EXPIRED = 'access_token_expired',
  ACCESS_TOKEN_NOT_FOUND = 'access_token_not_found',
}

export interface ErrorObject {
  message: string;
  locations?: object[];
  path?: string[];
  extensions?: {
    code: string;
    description: string;
    ignorable: boolean;
  };
  description?: string;
}

async function handleError(
  unknownError: ErrorObject | { errors: ErrorObject[] },
  show_alert: boolean,
  request_url: string,
) {
  let errorObj: ErrorObject;

  if ('errors' in unknownError) {
    errorObj = unknownError.errors[0];
  } else {
    errorObj = unknownError;
  }

  const error_object = {
    message: errorObj.message,
    extensions: errorObj.extensions ?? {},
    description: (errorObj.description ?? errorObj.extensions?.description ?? errorObj.message) || '',
  };

  // TODO: 상위 계층에서 처리하도록 수정
  if (error_object.message === ApiErrors.NOT_LOGGED_IN && isBrowser()) {
    /**
     * NOTE)
     * 고의적으로 넣은 로깅
     * https://croquis.slack.com/archives/C02UB4PMWFL/p1700206549175119?thread_ts=1700205019.233659&cid=C02UB4PMWFL
     * https://croquis.slack.com/archives/C02UB4PMWFL/p1700225524416959?thread_ts=1700219038.132889&cid=C02UB4PMWFL
     */
    if (window.location.href.includes('/events/')) {
      try {
        const errorType = ApiErrors.NOT_LOGGED_IN.toUpperCase();

        Sentry.captureException(`events/[uuid]: ${errorType} Action`, {
          tags: {
            page: '기획전',
            type: errorType,
            request_api_url: request_url,
          },
        });
      } catch (e) {
        // nothing todo
      }
    }
    gotoLogin({ redirect_url: window.location.href });
    return;
  }

  if (
    errorObj.extensions?.code === ApiErrorCodes.ACCESS_TOKEN_EXPIRED ||
    errorObj.extensions?.code === ApiErrorCodes.ACCESS_TOKEN_NOT_FOUND
  ) {
    // 각 사이트에서 토큰 발급받을수있게 처리된 이후 redirect path 추가
    if (isBrowser()) {
      window.location.assign(`/redirect/login`);
    }
  }

  if (show_alert && isBrowser()) {
    alert(error_object.description || '알 수 없는 오류가 발생했습니다. 고객센터로 문의해주세요');
  }

  throw error_object;
}
