import { Gray_Light_200, Pink_Light_400 } from '@common/styles';

export const IconCheckLine = ({
  size = 24,
  color = Pink_Light_400,
  disable_color = Gray_Light_200,
  disabled,
  ...props
}: JSX.IntrinsicElements['svg'] & {
  size?: number;
  disable_color?: string;
  disabled?: boolean;
}) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    color={disabled ? disable_color : color}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 8.5C0 12.9183 3.58172 16.5 8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5ZM12.4761 6.53039L11.4155 5.46973L6.78204 10.1032L4.03033 7.35144L2.96967 8.4121L6.78204 12.2245L12.4761 6.53039Z'
      fill='currentColor'
    />
  </svg>
);
