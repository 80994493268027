import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { Typography } from '@common/styles';

export interface InputTimerProps {
  expire_time: number;
  onExpire?: () => void;
}

export const InputTimer: React.FC<InputTimerProps> = ({ expire_time, onExpire }) => {
  const [remaining_time, setRemainingTime] = useState<number>(Math.max(expire_time - Date.now(), 0));

  useEffect(() => {
    let interval: NodeJS.Timeout;
    const current_time = Date.now();
    if (current_time < expire_time) {
      interval = setInterval(() => {
        const current_time = Date.now();
        if (current_time >= expire_time) {
          setRemainingTime(0);
          clearInterval(interval);
          onExpire?.();
          return;
        }
        setRemainingTime(expire_time - current_time);
      }, 600);
    } else {
      setRemainingTime(0);
    }
    return () => {
      clearInterval(interval);
    };
  }, [expire_time, onExpire]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return <Container className={Typography.BODY_13_SEMIBOLD}>{formatTime(remaining_time)}</Container>;
};

const Container = styled.div`
  color: currentColor;
`;
