import { useLikeMetaSubscribe } from '../component/LikeObserver';
import { useLikeStatusSelector } from '../hooks/useLikeStatusSelector';

/**
 * @description <LikeObserver/>를 사용하길 추천드립니다.
 * @param catalogProductId
 * @returns
 */
export function useLikeObserve(catalogProductId: string) {
  const isLiked = useLikeStatusSelector(catalogProductId);
  const _ref = useLikeMetaSubscribe(catalogProductId);
  return { isLiked, ref: _ref };
}
