export const isMobile = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  const user_agent = navigator.userAgent;
  return (
    user_agent.match(
      /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
    ) != null || user_agent.match(/LG|SAMSUNG|Samsung/) != null
  );
};
