import { BadgeListItemProps } from '../types';

export type ReturnThumbnailNudgeFunction<T extends undefined | BadgeListItemProps[]> = T extends BadgeListItemProps[]
  ? { hasThumbnailNudge: true; thumbnailNudgeProps: { src: string; alt: string } }
  : { hasThumbnailNudge: false; thumbnailNudgeProps: undefined };

export interface UseThumbnailNudgeOptions {
  nudgeBadgeList?: BadgeListItemProps[];
}

export function useThumbnailNudge<
  T extends UseThumbnailNudgeOptions,
  R = ReturnThumbnailNudgeFunction<T['nudgeBadgeList']>,
>(options: T): R {
  const { nudgeBadgeList } = options;

  const thumbnailNudgeProps = {
    src: nudgeBadgeList?.[0] ? nudgeBadgeList?.[0].imageUrl : '',
    alt: '',
  };

  const hasThumbnailNudge = nudgeBadgeList && nudgeBadgeList?.length > 0;

  return {
    hasThumbnailNudge,
    thumbnailNudgeProps,
  } as R;
}
