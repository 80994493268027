import { useTranslation } from 'react-i18next';
import type { ProductCardMetadataPriceProps } from '@croquiscom-pvt/zds';

import { BadgeItem } from '../../components/BadgeItem';
import { BadgeListItemProps, UxGoodsCardItemForHorizontalProductCard } from '../types';
import { covertBadgeItemPropsBySize } from '../utils';

export interface UseMetadataPriceOptions {
  price: {
    /** 최대 금액 */
    maxPrice: number;
    /** 최종 금액 */
    finalPrice: number;
    /** 원 표시 */
    priceUnit?: string | null;

    discountPercent: number | null;
    discountInfo: {
      imageUrl: string | null;
      title: string | null;
      color: string | null;
    } | null;
  };

  emblemItems?: BadgeListItemProps[];

  /**
   * 상품의 판매 상태
   * - `SOLD_OUT`: "품절" 텍스트가 노출 됨
   * - `CLOSED`: "판매 종료" 텍스트가 노출 됨
   * - 나머지 값은 일반 판매 상태로 간주
   */
  salesStatus?: UxGoodsCardItemForHorizontalProductCard['sales_status'];
}

export function useMetadataPrice(options: UseMetadataPriceOptions): {
  metadataPriceProps: ProductCardMetadataPriceProps;
} {
  const { price, emblemItems: emblemItemsFromOptions, salesStatus } = options;

  const { t } = useTranslation('cart');

  const emblemItems =
    emblemItemsFromOptions?.map((item, index) => {
      return <BadgeItem key={index} {...covertBadgeItemPropsBySize(item, 'medium')} />;
    }) ?? [];

  const metadataPriceProps: ProductCardMetadataPriceProps = {
    // '판매 종료'의 경우 i18n 내용이 없으므로 직접 텍스트를 넣어줌
    soldOutText: salesStatus === 'CLOSED' ? '판매 종료' : t('품절'),
    finalPrice: price.finalPrice,
    discountPercent: price.discountPercent ?? undefined,
    priceUnit: price.priceUnit ?? undefined,
    emblemItems: emblemItems.length > 0 ? emblemItems : undefined,
  };

  return {
    metadataPriceProps,
  };
}
