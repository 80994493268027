import React from 'react';

import { HorizontalProductCard } from '../../horizontal-product-card/HorizontalProductCard';
import { UxGoodsCardItemForHorizontalProductCard } from '../../horizontal-product-card/types';
import {
  useHorizontalProductCardWithItem,
  UseHorizontalProductCardWithItemOptions,
} from './hooks/useHorizontalProductCardWithItem';

export interface HorizontalProductCardWithItemBaseProps<T extends UxGoodsCardItemForHorizontalProductCard> {
  item: T;
  onImpression?: (item: T) => void;
  onClick?: (item: T, event: React.MouseEvent<HTMLElement>) => void;
  onLikeClick?: (isLiked: boolean, item: T) => void;
}

export interface HorizontalProductCardWithItemProps<
  T extends UxGoodsCardItemForHorizontalProductCard = UxGoodsCardItemForHorizontalProductCard,
> extends HorizontalProductCardWithItemBaseProps<T>,
    Pick<
      UseHorizontalProductCardWithItemOptions<T>,
      'likeButtonType' | 'thumbnailWidth' | 'thumbnailRatio' | 'metadataAlign' | 'productNameLineClamp'
    > {}

export const HorizontalProductCardWithItem = <T extends UxGoodsCardItemForHorizontalProductCard>(
  props: HorizontalProductCardWithItemProps<T>,
) => {
  const {
    likeButtonType,
    thumbnailWidth,
    thumbnailRatio,
    metadataAlign,
    productNameLineClamp,
    item,
    onImpression,
    onClick,
    onLikeClick,
  } = props;

  const horizontalProductCardProps = useHorizontalProductCardWithItem<T>(item, {
    likeButtonType,
    thumbnailWidth,
    thumbnailRatio,
    metadataAlign,
    productNameLineClamp,
    onImpression,
    onClick,
    onLikeClick,
  });

  return <HorizontalProductCard {...horizontalProductCardProps} />;
};
