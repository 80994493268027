export interface UseVisibleOfBlocksOptions {
  thumbnailWidth: number;
  likeButtonType: 'thumbnail' | 'metadata';
}
export function useVisibleOfBlocks(options: UseVisibleOfBlocksOptions) {
  const { thumbnailWidth, likeButtonType } = options;

  const isVisibleThumbnailNudge = thumbnailWidth >= 100;
  const isVisibleThumbnailEmblem = thumbnailWidth >= 100;

  const isVisibleThumbnailLikeButton = likeButtonType === 'thumbnail' && thumbnailWidth >= 80;
  const isVisibleMetadataLikeButton = likeButtonType === 'metadata' || !isVisibleThumbnailLikeButton;

  return {
    isVisibleThumbnailLikeButton,
    isVisibleMetadataLikeButton,
    isVisibleThumbnailNudge,
    isVisibleThumbnailEmblem,
  };
}
