import { callZpayFunction } from '../../callZpayFunction';

// 로그인 성공 후 특정 화면으로 이동
// redirectUrl=[encoded url] 은 딥링크 형식을 따라야 합니다
// 로그인 → 씨리얼핏 쇼핑몰 상세 redirect_url=zigzag%3A%2F%2F%2Fshops%2Fcrealfit
// 로그인 → 인앱브라우저 redirect_url=https%3A%2F%2Fcrealfit.com%2F

export const loginWithRedirectUrl = (redirectUrl: string) => {
  const url = encodeURIComponent(redirectUrl);
  callZpayFunction('loginWithRedirectUrl')({ url });
};
