/**
 * @example
 * hexToRGB('#FF0000', 0.5); // rgba(255, 0, 0, 1)
 * hexToRGB('#FF0000'); // rgba(255, 0, 0)
 */
export function hexToRGBA(hex: string, alpha_value?: number): string {
  const red = parseInt(hex.slice(1, 3), 16);
  const green = parseInt(hex.slice(3, 5), 16);
  const blue = parseInt(hex.slice(5, 7), 16);
  const alpha = typeof alpha_value === 'undefined' ? '' : `, ${alpha_value}`;
  const rgb = `${red}, ${green}, ${blue}`;
  return `rgba(${rgb.concat(alpha)})`;
}

export function colorWithAlpha(color: string, alpha: number) {
  if (color.slice(0, 4) === 'rgba') {
    return color;
  }
  if (color.slice(0, 1) === '#') {
    return hexToRGBA(color, alpha);
  }
  if (color.slice(0, 3) === 'rgb') {
    return color.slice(0, color.length - 1) + `, ${alpha})`;
  }
  throw new Error('올바른 컬러 문자가 아닙니다.');
}

/**
 * ARGB 값을 RGBA 값으로 변환하는 함수
 * @param argb - ARGB 형식의 색상 값 (예: #0DA4A4B7)
 * @returns RGBA 형식의 색상 값 (예: #A4A4B70D)
 */
export function argbToRgba(argb: string): string {
  // ARGB 값에서 #을 제거하고 16진수로 변환
  const hex = argb.replace('#', '');
  const is_exist_alpha = hex.length === 8;
  if (!is_exist_alpha) {
    return argb;
  }

  // ARGB 값에서 각 색상 요소 추출
  const a = hex.substring(0, 2);
  const r = hex.substring(2, 4);
  const g = hex.substring(4, 6);
  const b = hex.substring(6, 8);

  // RGBA 형식으로 변환
  return `#${r}${g}${b}${a}`;
}
