import { createContext, ReactNode, useContext } from 'react';
import { CurrencyMeta, PaymentCurrency } from 'api2/types';

import { UserLocale } from '@common/i18n';

export interface PageProps {
  user_agent: string;
  user_locale: UserLocale;
  currency_meta: CurrencyMeta;
  is_pdp: boolean;
  ZIGZAGUUID: string;
  children?: ReactNode;
  [key: string]: unknown;
}

export const AppPropsContext = createContext<PageProps>({
  currency_meta: {
    converted_rate: 0,
    currency: PaymentCurrency.KRW,
    decimal: 0,
    display_currency: '',
    is_currency_prefix: false,
  },
  is_pdp: false,
  user_agent: '',
  user_locale: { country: 'KR', currency: 'KRW', language: 'ko' },
  ZIGZAGUUID: '',
});
AppPropsContext.displayName = 'AppPropsContext';

export const useAppPropsContext = () => useContext(AppPropsContext);
