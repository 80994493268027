import { ProductCard } from '@croquiscom-pvt/zds';

import { useThumbnailRank, UseThumbnailRankOptions } from '../hooks/useThumbnailRank';

export interface ThumbnailRankProps extends UseThumbnailRankOptions {
  className?: string;
}

export const ThumbnailRank = (props: ThumbnailRankProps) => {
  const { className, rank } = props;
  const { hasRank, thumbnailRankProps } = useThumbnailRank({ rank });

  if (!hasRank) {
    return null;
  }

  return <ProductCard.ThumbnailRank className={className} {...thumbnailRankProps} />;
};
