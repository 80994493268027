import HttpBackend from 'i18next-http-backend/cjs';
import type { UserConfig } from 'next-i18next';

import { i18NextOption } from '@common/i18n';

const isBrowser = typeof window !== 'undefined';
const isDev = process.env.NODE_ENV === 'development';

/** @public */
export const i18NextOptionForWebsite: UserConfig = {
  ...i18NextOption,
  backend: {
    backendOptions: [
      {
        expirationTime: isDev ? 0 : 60 * 60 * 1000,
        loadPath: (locales: string[], namespaces: string[]) => {
          const [locale] = locales;
          const [ns] = namespaces;
          if (process.env.LOCALE_HASH) {
            return `/locales/${locale}/${camelToKebab(ns)}.${process.env.LOCALE_HASH}.json`;
          }
          return `/locales/${locale}/${camelToKebab(ns)}.json`;
        },
      },
      {},
    ],
    backends: isBrowser ? [HttpBackend] : [],
  },
};

function camelToKebab(camelCase: string) {
  return camelCase.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
