import React from 'react';
import { ClassNames } from '@emotion/react';

import { HorizontalProductCardProps } from '../HorizontalProductCard';
import { useHorizontalProductCardContext } from '../HorizontalProductCardContext';
import { UxGoodsCardItemForHorizontalProductCard } from '../types';

/**
 * 상품카드 클릭 시 이동하는 상세 페이지 링크를 생성하기 위해 전달 되어야 하는 아이템
 * PDP 담당자와 싱크 후 필요한 값만 추가
 */
export interface ProductCardLinkBaseItemProps {
  browsingType: UxGoodsCardItemForHorizontalProductCard['browsing_type'];
  shopId: string;
  shopProductNo: string | null;
  productUrl: string | null;
}

export interface ProductCardLinkItemProps
  extends ProductCardLinkBaseItemProps,
    Pick<HorizontalProductCardProps, 'soldOut' | 'productId' | 'thumbnail' | 'metadata'> {}

export interface RenderProductCardLinkProps {
  /**
   * 상품카드 전체를 감싸는 스타일 클래스를 제공
   * ```css
   * position: absolute;
   * left: 0;
   * width: 100%;
   * height: 100%;
   * opacity: 0;
   * z-index: 1;
   * ```
   */
  className: string;
  /** Context 혹은 `ProductCardLinkProps`에서 반환된 `href` 값 */
  href?: string;
  item: ProductCardLinkItemProps;
}

export interface ProductCardLinkBaseProps {
  /**
   * 상품 카드 클릭 시 진입할 상품 상세 페이지 주소
   *
   * href가 없을 경우 HorizontalProductCardContext href를 사용합니다.
   *
   * 만약 HorizontalProductCardContext href도 없는 경우 href가 없는 a 태그를 렌더링합니다.
   */
  href?: ((item: ProductCardLinkItemProps) => string | undefined) | string | undefined;
  /**
   * 상품 카드 클릭 시 진입할 상품 상세 페이지 주소를 렌더링하는 함수
   *
   * renderProductCardLink가 없을 경우 HorizontalProductCardContext renderProductCardLink를 사용합니다.
   *
   * 만약 HorizontalProductCardContext renderProductCardLink도 없는 경우 기본 a태그를 렌더링합니다.
   *
   * ex) Next.js Link 처리 시 사용
   */
  renderProductCardLink?: (props: RenderProductCardLinkProps) => React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export interface ProductCardLinkProps extends ProductCardLinkBaseProps {
  className?: string;
  item: ProductCardLinkItemProps;
}

export const ProductCardLink = (props: ProductCardLinkProps) => {
  const {
    className,
    href: hrefFromProps,
    renderProductCardLink: renderProductCardLinkFromProps,
    item,
    onClick,
  } = props;

  const { href: hrefFromContext, renderProductCardLink: renderProductCardLinkFromContext } =
    useHorizontalProductCardContext();

  let href: string | undefined = undefined;

  if (typeof hrefFromProps === 'function') {
    href = hrefFromProps(item);
  } else if (typeof hrefFromProps === 'string') {
    href = hrefFromProps;
  } else if (typeof hrefFromContext === 'function') {
    href = hrefFromContext(item);
  } else if (typeof hrefFromContext === 'string') {
    href = hrefFromContext;
  }

  return (
    <ClassNames>
      {({ cx, css }) => {
        const linkOverlayCss = css`
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 1;
        `;
        const linkProps: RenderProductCardLinkProps = {
          className: cx(linkOverlayCss, className),
          href,
          item,
        };
        if (typeof renderProductCardLinkFromProps === 'function') {
          return renderProductCardLinkFromProps(linkProps);
        }

        if (typeof renderProductCardLinkFromContext === 'function') {
          return renderProductCardLinkFromContext(linkProps);
        }

        return <a className={linkProps.className} href={linkProps.href} children={undefined} onClick={onClick} />;
      }}
    </ClassNames>
  );
};

export function getItemPropsByHorizontalProductCardProps(item: HorizontalProductCardProps): ProductCardLinkItemProps {
  const { soldOut, browsingType, productId, shopId, shopProductNo, productUrl, thumbnail, metadata } = item;

  return {
    soldOut,
    browsingType,
    productId,
    shopId,
    shopProductNo,
    productUrl,
    thumbnail,
    metadata,
  };
}
