import { callZpayFunction } from '../../callZpayFunction';

// 내 상품 저장/장바구니 담기 > 연관상품 바텀싯
// 지원 브라우저 : PDP, Z결제 브라우저,
// - scene : PRODUCT_DETAIL
// - action : CART or LIKE
// - productNo : (nullable) shopMainDomain$shopProductNo 형식
// - catalogProductId : (nullable) 카탈로그 ID
// - imageUrl : `CART` 액션인 경우 필수
// - buttonTitle : 버튼 타이틀
// - buttonAction : 버튼 탭 액션에 따른 javascript(ex. window.hanlder.go(‘tab’, ‘recommend’);) buttonAction App에서 처리 없이, 버튼이 눌렸을때 바로 호출됨
//   - 바텀싯 상단에 상품 사진 노출
export interface ZpayRelatedItem {
  scene: string;
  action: string;
  productNo: string | null;
  catalogProductId: string | null;
  imageUrl?: string;
  buttonTitle?: string;
  buttonAction?: string;
}

export const relatedItemList = (zpayRelatedItem: ZpayRelatedItem) => {
  callZpayFunction('relatedItemList')(zpayRelatedItem);
};
