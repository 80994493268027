import { useEffect, useState } from 'react';

import { getColumnCountByWindowInnerWidth, gridLayoutManager } from '../gridLayoutManager';
import { ColumnCountType, ColumnCountTypeWithoutAuto } from '../types';

export interface UseCalculatedColumnCountOptions {
  columnCount: ColumnCountType;
  defaultColumnCount: ColumnCountTypeWithoutAuto;
}

export function useCalculatedColumnCount(options: UseCalculatedColumnCountOptions) {
  const { columnCount: columnCountFromOptions, defaultColumnCount } = options;

  const [columnCount, setColumnCount] = useState(defaultColumnCount);

  useEffect(() => {
    if (columnCountFromOptions !== 'auto') {
      return;
    }

    const callback = (columnCount: ColumnCountTypeWithoutAuto) => {
      setColumnCount(columnCount);
    };

    const unsubscribeColumnCount = gridLayoutManager.subscribeColumnCount(callback);

    // matchMedia의 경우 초기값을 가져오지 못하므로, innerWidth로 초기값을 설정해줍니다.
    callback(getColumnCountByWindowInnerWidth(window.innerWidth));

    return () => {
      unsubscribeColumnCount();
    };
  }, [columnCountFromOptions]);

  const calculatedColumnCount = columnCountFromOptions === 'auto' ? columnCount : columnCountFromOptions;

  return calculatedColumnCount;
}
