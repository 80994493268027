import React, { HTMLAttributes, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import lottie from 'lottie-web';

export const LoadingDotBlack: React.FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const { className, ...restProps } = props;

  const lottieRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lottieRef.current) {
      lottie.loadAnimation({
        container: lottieRef.current,
        autoplay: true,
        loop: true,
        animationData: require('./loading_dot_black.json'),
      });
    }
  }, []);

  return <Container className={className} ref={lottieRef} {...restProps} />;
};

const Container = styled.div`
  width: 72px;
  height: 72px;
  vertical-align: top;
  display: inline-block;
`;
