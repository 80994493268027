import { devtools } from 'zustand/middleware';
import { createStore, StateCreator } from 'zustand/vanilla';

const likeStatusCreator: StateCreator<
  Record<string, boolean | null>,
  [],
  [],
  Record<string, boolean | null>
> = () => ({});
export const likeStatusStore = createStore(devtools(likeStatusCreator, { name: 'likeStatusStore' }));

export type LikeMeta = Record<
  string,
  {
    has: boolean;
    inView: boolean;
    order: number;
    groupOrder: number;
    id: string;
  }
>;

const likeMetaCreator: StateCreator<LikeMeta, [], [], LikeMeta> = () => ({});
export const likeMetaStore = createStore(devtools(likeMetaCreator, { name: 'checkIdsStore' }));
