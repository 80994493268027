import React from 'react';
import styled from '@emotion/styled';

import { LoadingDot } from '@common/overlay-manager';

export const Loading: React.FC = () => {
  return (
    <Container>
      <LoadingDot />
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;
