import { createContext, ReactNode, useContext } from 'react';

interface LikeProductConfigValue {
  loginUrl: () => string;
}

const ConfigContext = createContext<LikeProductConfigValue>({ loginUrl: () => '' });

export const useLikeProductConfig = () => useContext(ConfigContext);

export function LikeProductProvider(props: LikeProductConfigValue & { children: ReactNode }) {
  const { children, ...restProps } = props;
  return <ConfigContext.Provider value={restProps}>{children}</ConfigContext.Provider>;
}
