import { BaseConfigType } from './default';
import ProductionConfig from './production';

const Config: BaseConfigType = {
  ...ProductionConfig,
  assetPrefix: `https://beta.zigzag.kr/resources/${process.env.ARCH}/${process.env.IMAGE_TAG}`,
  sentry_dsn: 'https://00ef6e4ca50e4f2aad0eebbf2d69d10c@o314573.ingest.sentry.io/6036870',
  sentry_org: 'zigzag-alpha',
  baseDomain: 'beta.zigzag.kr',
  apiBaseUrl: 'https://api.beta.zigzag.kr/api/store',
  apiConsumerBaseUrl: 'https://api.beta.zigzag.kr/api/2',
  simplePayPassword:
    'https://api.beta.zigzag.kr/api/security-keypad/static/js/simple_pay/simple_pay_password_beta.js?t=20231218',
  appUrl: 'https://beta.zigzag.kr',
  redirectAppUrl: 'https://store.beta.zigzag.kr',
  contentUrl: 'https://content.beta.zigzag.kr',
  resUrl: 'https://cf.res.s.zigzag.kr',
  business_juso_api_key: 'U01TX0FVVEgyMDIyMTEwNzEzMDEzMjExMzE5Mjc=',
  zigzag_postcode_url: 'https://store.beta.zigzag.kr/postcode',
  braze_api_key: '3347f2c9-3ccf-4994-a196-3eaaa8b46288',
  ga4_id: '',
  display_google_ads_id: '',
  search_google_ads_id: '',
  amplitude_id: '',
  apps_flyer_pba_id: '',
  airbridge_app: 'zigzagbeta',
  airbridge_web_token: 'a7bdc36fb65d4de18b313ddb49c2c3a6',
  kakao_pixel_id: '',
  facebook_pixel_id: '',
  naver_account_id: '',
  appier_id: '',
  appier_track_id: '',
};

export default Config;
