import { isBrowser, isDev } from '../../utils/conditions';
import {
  BookmarkStatusMap,
  DeviceNotificationStatus,
  ProductReviewListFilter,
  SavedProductStatus,
  UserAccount,
} from '../types';

export interface ZigzagHandler {
  onBookmarkStatus: (bookmarkStatusMap: BookmarkStatusMap) => void;
  onSavedProductStatus: (savedProductStatusList: SavedProductStatus[]) => void;
  onKakaoLinkAvailable: (kakaoLinkAvailable: boolean) => void;
  onUserAccount: (userAccount: UserAccount) => void;
  onAppNotificationStatus: (status: DeviceNotificationStatus) => void;
  onStatusbarHeight: (statusbarHeight: number) => void;
  onSavedShopList: (shopIdList: string[]) => void;
  onProductReviewListFilter: (productReviewListFilter: ProductReviewListFilter) => void;
  onSavedMyGoods: (activeIndex: number) => void;
  onRecentSearches: (keywords: string[]) => void;
  onIsInstalledKakaoBank: (installed: { result: boolean }) => void;
  onOpenBeautyIngredient: (actionInfo: { type: string; index: number }) => void;
}

export const zigzagHandler = <T extends keyof ZigzagHandler, H extends ZigzagHandler>(name: T, callback: H[T]) => {
  if (!isBrowser()) {
    return console.warn(`Should invoke zigzagHandler.${name} in client`);
  }

  const _window: any = window;
  const zigzagHandler: H = _window.zigzagHandler;
  if (!zigzagHandler && !isDev()) {
    return console.warn('Should call injectZigzagHandler before zigzagHandler');
  }

  zigzagHandler[name] = callback;
};
