import { callZigzagFunction } from '../../callZigzagFunction';
import { zigzagHandler } from '../../handlers';
import { BookmarkStatusMap } from '../../types';

// 쇼핑몰 즐겨찾기 여부 확인 요청
export const cbk = (...shopMainDomains: string[]) => {
  return new Promise<BookmarkStatusMap>((resolve) => {
    callZigzagFunction('cbk')({ shopMainDomains });
    zigzagHandler('onBookmarkStatus', (maps) => {
      resolve(maps);
    });
  });
};
