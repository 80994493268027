export const web_path = {
  root: '/',
  home: '/home',
  search: '/search',
  auth: {
    root: '/auth',
    find_email: '/auth/find-email',
    find_email_result: '/auth/find-email-result',
    find_account_by_tel: '/auth/find-account-by-tel',
    find_account_by_authentication: '/auth/find-account-by-authentication',
    find_account_by_email: '/auth/find-account-by-email',
    find_account_result: '/auth/find-account-result',
    login: '/auth/login',
    email_login: '/auth/email-login',
    tel_login: '/auth/tel-login',
    second_auth: '/auth/second-auth',
    second_auth_password: '/auth/second-auth/password',
    second_auth_email: '/auth/second-auth/email',
    second_auth_authentication: '/auth/second-auth/authentication',
    find_password: '/auth/find-password',
    reset_password: '/auth/:locale/reset-password',
    reset_password_done: '/auth/:locale/reset-password-done',
    reset_password_invalid_code: '/auth/:locale/reset-password-invalid-code',
    sign_up_email: '/auth/sign-up-email',
    sign_up_done: '/auth/sign-up-done',
    write_email: '/auth/write-email',
    terms_agreement: '/auth/terms-agreement',
    mobile_authentication: '/auth/mobile-authentication',
    profile: '/auth/profile',
    edit_profile: '/auth/edit-profile',
    connect_account: '/auth/connect-account',
    adview_login: '/auth/adview-login',
    shipping_destination: '/auth/shipping-destination',
    language_currency: '/auth/language-currency',
    duplicate_social_account: '/auth/duplicate-social-account',
    redirect_scope: '/auth/redirect-scope',
    add_kakao_channel: '/auth/add-kakao-channel',
    withdrawal: '/auth/withdrawal',
    withdrawal_reason: '/auth/withdrawal/reason',
    recertification: '/auth/recertification',
    activate_account: '/auth/activate-account',
    error: '/auth/error',
    talk_student_terms_agreement: '/auth/talk-student/terms-agreement',
    talk_student_authentication: '/auth/talk-student/authentication',
    talk_student_submit: '/auth/talk-student/submit',
    error_talk_student: '/auth/error/talk-student',
  },
  categories: {
    root: '/categories',
    landing: '/categories/:category_id',
  },
  picks: '/picks',
  catalog_products_detail: '/catalog/products/:catalog_product_id',
  catalog_preview_detail: '/catalog/__preview/:catalog_product_id',
  cart: '/cart',
  product_detail_information: '/products/detail-information/:catalog_product_id',
  orders: '/orders',
  user_locale: '/user-locale',
  my_page: {
    root: '/my-page',
  },
  coupon: {
    user_box: '/coupon/user-box',
  },
  points: '/points',
  mileage: '/mileage',
  my_review_list: '/my-review/list',
  my_review_profile_edit: '/my-review/profile-edit',
  my_review_profile_consent_drawer: '/my-review/profile-consent-drawer',
  review_detail: '/review/detail/:id',
  review_completed: '/review/completed',
};
