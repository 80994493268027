import React from 'react';

export function BoxFBKIcon({ width = 20, height = 20, ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 20 20' {...props}>
      <g id='BoxFBK'>
        <path
          id='Rectangle'
          d='M1.66602 4.18449C1.66602 2.79384 2.78932 1.6665 4.17498 1.6665H15.8237C17.2094 1.6665 18.3327 2.79384 18.3327 4.18449V15.8152C18.3327 17.2058 17.2094 18.3332 15.8237 18.3332H4.17498C2.78932 18.3332 1.66602 17.2058 1.66602 15.8152V4.18449Z'
          fill='#FAE100'
        />
        <path
          id='Vector'
          d='M10.0007 3.3335C8.682 3.3335 7.39296 3.72456 6.29657 4.45722C5.20019 5.18988 4.3457 6.23123 3.8412 7.44956C3.33669 8.66789 3.20483 10.0085 3.46228 11.3018C3.71974 12.595 4.35494 13.7829 5.28756 14.7152C6.22018 15.6474 7.40832 16.2821 8.7017 16.5391C9.99509 16.796 11.3356 16.6636 12.5537 16.1586C13.7719 15.6536 14.8129 14.7987 15.5451 13.702C16.2773 12.6053 16.6678 11.3161 16.6673 9.99747C16.6666 8.22983 15.9639 6.53482 14.7137 5.28516C13.4636 4.0355 11.7683 3.3335 10.0007 3.3335Z'
          fill='#E62F71'
        />
        <path
          id='Vector_2'
          d='M13.877 11.174L9.84941 8.21656C9.29397 7.78227 9.30411 7.55179 9.31426 7.30918C9.32178 7.19585 9.3534 7.08519 9.40718 6.98396C9.46095 6.88272 9.53576 6.79306 9.62702 6.72044C9.71828 6.64782 9.82407 6.59378 9.93791 6.56163C10.0518 6.52947 10.1713 6.51988 10.2891 6.53343C10.407 6.54699 10.5207 6.58341 10.6233 6.64046C10.726 6.69751 10.8154 6.774 10.886 6.86525C10.9567 6.95649 11.0071 7.06056 11.0343 7.1711C11.0615 7.28164 11.0648 7.39632 11.044 7.50812C11.0265 7.59435 11.0455 7.68368 11.0969 7.75648C11.1483 7.82928 11.2278 7.87957 11.3179 7.8963C11.408 7.91303 11.5014 7.89483 11.5775 7.84569C11.6536 7.79655 11.7062 7.7205 11.7237 7.63428C11.7673 7.43106 11.7663 7.2214 11.7209 7.01856C11.6754 6.81572 11.5865 6.62411 11.4596 6.45585C11.3328 6.28759 11.1708 6.14634 10.9839 6.04104C10.7971 5.93573 10.5894 5.86866 10.3741 5.84407C10.1588 5.81948 9.94043 5.8379 9.73291 5.89817C9.5254 5.95844 9.33322 6.05925 9.16853 6.19422C9.00384 6.32919 8.87022 6.49538 8.77612 6.68229C8.68202 6.86919 8.62949 7.07274 8.62186 7.28006C8.60157 7.68766 8.64976 8.1365 9.41824 8.73576L13.4002 11.6471C13.4839 11.7321 13.4458 11.8267 13.3596 11.8267H6.6664C6.57763 11.8267 6.54212 11.7321 6.62582 11.6471L9.1773 9.78385C9.23747 9.7264 9.27354 9.64984 9.27867 9.56866C9.2838 9.48748 9.25765 9.40732 9.20515 9.34335C9.15265 9.27938 9.07745 9.23604 8.9938 9.22154C8.91014 9.20704 8.82383 9.22238 8.75121 9.26466L6.14393 11.174C6.00677 11.2769 5.908 11.4193 5.8623 11.5802C5.8166 11.7411 5.82642 11.9118 5.8903 12.0669C5.95719 12.2036 6.06552 12.3179 6.20122 12.3949C6.33692 12.4719 6.4937 12.5081 6.65118 12.4987H13.3444C13.5018 12.5077 13.6584 12.4714 13.7941 12.3944C13.9297 12.3175 14.0381 12.2034 14.1053 12.0669C14.1715 11.9142 14.1851 11.7453 14.1441 11.5847C14.103 11.4241 14.0095 11.2802 13.877 11.174Z'
          fill='#FFE500'
        />
      </g>
    </svg>
  );
}
