/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { ClassNames } from '@emotion/react';

import { UxGoodsCardItemForVerticalProductCard } from '../vertical-product-card/types';
import * as cssVariables from './constants/cssVariables';
import { useCalculatedColumnCount } from './hooks/useCalculatedColumnCount';
import { useDividedItemsWithColumnCount } from './hooks/useDividedItemsWithColumnCount';
import { useSize } from './hooks/useSize';
import { extractProps } from './utils/extractProps';
import { getWrapperCss } from './utils/getWrapperCss';
import { DEFAULT_COLUMN_COUNT, DEFAULT_SIZE } from './gridLayoutManager';
import { ItemContentContainer } from './ItemContentContainer';
import { CommonGridContainerProps, GridContainerCotextType } from './types';

export interface CreateGridContainerOptions<D extends UxGoodsCardItemForVerticalProductCard, P extends {}> {
  renderProductCard: (item: D, itemIndex: number, context: GridContainerCotextType<D, P>) => React.ReactNode;
}

export type GridContainerProps<
  D extends UxGoodsCardItemForVerticalProductCard,
  P extends {},
> = CommonGridContainerProps & {
  items: D[];
  banner?: GridContainerCotextType<D, P>['banner'];
  onEndReached?: (index: number) => void;
} & P;

const MemoizedItemContentContainer = React.memo(ItemContentContainer) as typeof ItemContentContainer;

export function createGridContainer<P extends {}, D extends UxGoodsCardItemForVerticalProductCard>(
  options: CreateGridContainerOptions<D, P>,
) {
  const { renderProductCard } = options;

  const Container = (props: GridContainerProps<D, P>) => {
    const { commonProps, otherProps } = extractProps(props);

    const {
      defaultColumnCount = DEFAULT_COLUMN_COUNT,
      defaultSize = DEFAULT_SIZE,
      columnCount = 'auto',
      columnStyle = 'basic',
      banner,
      items,
      onEndReached,
    } = commonProps;

    const { ref: lastItemRef } = useInView({
      skip: typeof onEndReached !== 'function',
      onChange: (inView) => {
        if (inView && onEndReached) {
          onEndReached(items.length - 1);
        }
      },
    });

    const calculatedColumnCount = useCalculatedColumnCount({ columnCount, defaultColumnCount });
    const size = useSize({ columnStyle, calculatedColumnCount, defaultSize });

    const diviededItems = useDividedItemsWithColumnCount(items, calculatedColumnCount);

    const context: GridContainerCotextType<D, P> = {
      size,
      columnCount: calculatedColumnCount,
      columnStyle,
      banner,
      otherProps,
    };

    return (
      <ClassNames>
        {({ cx, css }) => {
          const wrapperCss = getWrapperCss(css, cssVariables, columnCount);
          return (
            <div className={cx([wrapperCss, columnStyle])}>
              {diviededItems.map((item, parentIndex, arr) => {
                let itemRef: typeof lastItemRef | undefined;

                if (parentIndex === arr.length - 1 && onEndReached) {
                  itemRef = lastItemRef;
                }

                return (
                  <MemoizedItemContentContainer
                    key={parentIndex}
                    ref={itemRef}
                    items={item}
                    parentIndex={parentIndex}
                    context={context}
                    renderProductCard={renderProductCard}
                  />
                );
              })}
            </div>
          );
        }}
      </ClassNames>
    );
  };

  Container.displayName = 'GridContainer';

  return Container;
}
