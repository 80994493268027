import { getConfigValue } from '@common/config';

import { getKakaoAPI } from './getKakaoAPI';

declare const window: Window & {
  Kakao?: {
    Auth: {
      /** (추가 항목 동의 받기)[https://developers.kakao.com/docs/latest/ko/kakaologin/js#additional-consent] */
      /** [parameters 자세히](https://developers.kakao.com/docs/latest/ko/kakaologin/rest-api#request-token) */
      login: (parameters: {
        scope?: string;
        success: (response: {
          access_token: string;
          expires_in: number;
          refresh_token: string;
          refresh_token_expires_in: number;
          scope?: string;
          token_type: string;
        }) => void;
        fail: (error: { error: string; error_description: string }) => void;
      }) => void;
      authorize: (parameters: { redirectUri: string; scope?: string; state?: string; throughTalk?: boolean }) => void;
    };
    init: (key: string) => void;
    isInitialized: () => boolean;
    Channel: {
      addChannel: (parameter: { channelPublicId: string }) => void;
    };
  };
};

export const loginKakao = async (redirect_url?: string | null) => {
  const { redirect_url: new_redirect_url } = getRedirectUrl({
    app_url: getConfigValue('appUrl') as string,
    redirect_path: redirect_url,
  });

  const Kakao = await getKakaoAPI();

  const redirect_uri = new_redirect_url ?? window.location.href;
  const kakao_state = { redirect_uri, is_login: 'true' };
  Kakao.Auth.authorize({
    redirectUri: `${location.origin.replace('store.zigzag', 'zigzag')}/auth/redirect-scope`,
    state: stateToParams(kakao_state),
  });
};

interface GetRedirectUrlParams {
  app_url?: string;
  redirect_path?: string | null;
}

interface RedirectUrl {
  redirect_url: string | null;
  redirect_url_for_web_login: string;
}

function stateToParams(state: Record<string, string>) {
  return Object.entries(state)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

function getRedirectUrl({ app_url, redirect_path }: GetRedirectUrlParams): RedirectUrl {
  const url = redirect_path ?? window.location.href;
  const { searchParams } = new URL(url, app_url);

  if (searchParams.has('redirect')) {
    const redirect = searchParams.get('redirect');
    searchParams.delete('redirect');
    const remain_params = searchParams.toString();
    const params = remain_params ? `&${remain_params}` : '';
    return { redirect_url: redirect ?? '', redirect_url_for_web_login: `${redirect}${params}` };
  }

  return {
    redirect_url: url,
    redirect_url_for_web_login: url,
  };
}
