import type { ProductCardMetadataReviewProps } from '@croquiscom-pvt/zds';

export type ReturnMetadataReviewFunction<T extends undefined | null | ProductCardMetadataReviewProps> =
  T extends ProductCardMetadataReviewProps
    ? { hasReview: true; metadataReviewProps: ProductCardMetadataReviewProps }
    : { hasReview: false; metadataReviewProps: undefined };

export interface UseMetadataReviewOptions {
  review?: ProductCardMetadataReviewProps | null;
}

export function useMetadataReview<T extends UseMetadataReviewOptions, R = ReturnMetadataReviewFunction<T['review']>>(
  options: T,
): R {
  const { review } = options;
  const hasReview = review !== undefined;

  return {
    hasReview,
    metadataReviewProps: hasReview ? review : undefined,
  } as R;
}
