import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconEyeOnRegular32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path
        fill='currentColor'
        d='m6 16-.666-.346-.18.346.18.346L6 16Zm20 0 .666.346.18-.346-.18-.346L26 16Zm-10 6.25c-2.893 0-5.211-1.592-6.85-3.273a16.569 16.569 0 0 1-2.334-3.055 9.33 9.33 0 0 1-.15-.268L6 16a82.3 82.3 0 0 0-.665.347l.001.002.003.006.01.018.036.067c.031.057.077.137.136.24a18.063 18.063 0 0 0 2.555 3.344C9.848 21.841 12.53 23.75 16 23.75v-1.5ZM6 16l.665.346.001-.002a1.873 1.873 0 0 1 .034-.061 14.507 14.507 0 0 1 .59-.959 16.569 16.569 0 0 1 1.86-2.3C10.79 11.341 13.108 9.75 16 9.75v-1.5c-3.47 0-6.152 1.908-7.924 3.727a18.063 18.063 0 0 0-2.555 3.344 10.654 10.654 0 0 0-.172.306l-.01.018a.382.382 0 0 0-.003.006v.002h-.001L6 16Zm10 7.75c3.47 0 6.152-1.908 7.924-3.727a18.061 18.061 0 0 0 2.555-3.344 10.358 10.358 0 0 0 .172-.306l.01-.018a.734.734 0 0 1 .003-.006v-.002h.001L26 16l-.665-.346-.001.002a.818.818 0 0 1-.034.061 14.578 14.578 0 0 1-.59.959 16.568 16.568 0 0 1-1.86 2.3C21.21 20.659 18.892 22.25 16 22.25v1.5ZM26 16l.665-.346v-.001l-.001-.002a.734.734 0 0 1-.003-.006l-.01-.018a15.969 15.969 0 0 0-.696-1.138 18.061 18.061 0 0 0-2.031-2.512C22.152 10.158 19.47 8.25 16 8.25v1.5c2.893 0 5.211 1.592 6.85 3.273a16.568 16.568 0 0 1 2.334 3.055 9.5 9.5 0 0 1 .15.268L26 16Z'
      />
      <path stroke='currentColor' strokeWidth={1.5} d='M16 13A3 3 0 1 0 16 19 3 3 0 1 0 16 13z' />
    </Svg>
  );
};
export default SvgIconEyeOnRegular32;
