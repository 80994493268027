import { BaseConfigType } from './default';

const Config: BaseConfigType = {
  baseDomain: 'zigzag.kr',
  apiBaseUrl: 'https://api.zigzag.kr/api/store',
  apiConsumerBaseUrl: 'https://api.zigzag.kr/api/2',
  appUrl: 'https://zigzag.kr',
  redirectAppUrl: 'https://store.zigzag.kr',
  contentUrl: 'https://content.zigzag.kr',
  assetPrefix: `https://www.zigzag.kr/resources/${process.env.ARCH}/${process.env.IMAGE_TAG}`,
  payApiBaseUrl: 'https://api.zigzag.kr/api/user-pay',
  inlineImageLimit: 8192,
  localIdentName: '[hash:base64]',
  maintenanceDataUrl: 'https://s3.ap-northeast-2.amazonaws.com/ec.s.zigzag.kr/status/maintenance.json',
  simplePayPassword: 'https://api.zigzag.kr/api/security-keypad/static/js/simple_pay/simple_pay_password.js?t=20231220',
  simpleBankAppVersion: '6.6.0',
  sentry_dsn: 'https://baec8a7cec0f4385868e4d522aec72b3@o314572.ingest.sentry.io/4505390115913728',
  sentry_org: 'zigzag-production',
  resUrl: 'https://cf.res.s.zigzag.kr',
  zendeskRequestOrderItemNumberKey: '4540109294223',
  zendeskRequestOrderItemCsUrlKey: '4540097882127',
  csUrl: 'https://cs.zigzag.kr',
  braze_api_key: '103ec90c-cf52-4ad7-b6e9-12957ab2c361',
  ga4_id: 'G-3JHT92YZJ8',
  display_google_ads_id: '',
  search_google_ads_id: '',
  amplitude_id: 'b31370650d2a75565e9d1c17f0a08529',
  apps_flyer_pba_id: '83e876bb-2917-4557-8b76-bd7530f0f0c0',
  airbridge_app: 'zigzag',
  airbridge_web_token: '215f496de7984d60a7d4f50321419acd',
  kakao_pixel_id: '',
  facebook_pixel_id: '',
  naver_account_id: 's_57739c3166db',
  appier_id: 'ce31',
  appier_track_id: '56a529d9dada3ca',
  business_juso_api_key: 'U01TX0FVVEgyMDIyMTEwNzEzMDEzMjExMzE5Mjg=',
  hackle_key: 'TkjLEPFTSOyUlA8GGWmCNkilhvBtDrfP',
  zigzag_postcode_url: 'https://zigzag.kr/postcode',
  kakao_rest_key: '9f5d80b8a7a81f07c75e57fb08f85792',
  kakao_app_key: '49c459f8302031e03cbeb78c87f05e1e',
  zendesk_url: 'https://kakaostyle.zendesk.com/hc/p/requests-with-sso',
};

export default Config;
