import { isBrowser } from '@croquiscom/web2app';

export const isZigzag = () => {
  if (isBrowser()) {
    const app_window = window as any;
    const HAS_ZIGZAG_PROPS = !!(app_window?.zigzag || app_window?.webkit?.messageHandlers?.zigzag);
    return HAS_ZIGZAG_PROPS || /(zigzag)/i.test(navigator.userAgent);
  } else {
    return false;
  }
};
