import { GetUserPostboxList_user_postbox_list_item_list as UserNotification, UserNotificationCategory } from '../api';
import image from '../img/ic_welcome_coupon.png';

export const notificationFirstBuyCard: UserNotification = Object.freeze({
  id: 'notificationFirstBuyId',
  uid: 'notificationFirstBuyUid',
  category: UserNotificationCategory.NORMAL,
  date_created: 0,
  date_read: null,
  read: false,
  icon_image_url: null,
  image_url: image,
  image_url_list: null,
  title: '지그재그가 처음이신가요?',
  text: '지금 최대 76,000원 웰컴 혜택을 받으세요.',
  url: 'https://zigzag.kr/events/7eff045d-8295-4d45-b5c9-e3f24e762595',
  shop_id: null,
  campaign_id: null,
  site_id: null,
  shop_info: null,
  shop_unseen_count: null,
  extra_info: null,
});
