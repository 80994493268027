import pipe from 'lodash/fp/pipe';

import { isBrowser } from '../../utils/conditions';
import { devLogger } from '../../utils/devLogger';

const handleArgs =
  (function_name: string, event_name: string) =>
  <A>(args?: A) => {
    const iosParam = {
      function_name,
      event_name,
      ...(!!args && { parameters: args }),
    };
    return {
      iosParam,
      aosParam: args,
    };
  };

const MKT_MSG_HANDLER = 'kakaostyle-mkt';

export const callKakaostyleMktFunction = (functionName: string, eventName: string) =>
  pipe(
    handleArgs(functionName, eventName),
    devLogger(MKT_MSG_HANDLER, functionName, eventName),
    ({ iosParam, aosParam }) => {
      if (!isBrowser()) {
        return console.warn('Should invoke callKakaostyleMktFunction in client');
      }
      const _window: any = window;
      const inIOS =
        _window.webkit &&
        _window.webkit.messageHandlers &&
        _window.webkit.messageHandlers[MKT_MSG_HANDLER] &&
        _window.webkit.messageHandlers[MKT_MSG_HANDLER].postMessage;
      const inAndroid = _window[MKT_MSG_HANDLER] && _window[MKT_MSG_HANDLER][functionName];

      if (inIOS) {
        _window.webkit.messageHandlers[MKT_MSG_HANDLER].postMessage(iosParam);
      }
      if (inAndroid) {
        _window[MKT_MSG_HANDLER][functionName](eventName, JSON.stringify(aosParam));
      }
    },
  );
