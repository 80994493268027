import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { brazeTracker } from './brazeTracker';

export const useBrazeEvent = () => {
  const {
    asPath,
    query: { utm_campaign },
  } = useRouter();

  const checkUtmCampaign = useCallback(() => {
    if (asPath.startsWith('/home')) {
      if (utm_campaign) {
        brazeTracker.logCustomEvent('source', {
          utm_source: utm_campaign,
        });
      }
    }
  }, [asPath, utm_campaign]);

  return { checkUtmCampaign };
};
