import { UserNotificationCategory } from '../api/index_types.2';

export const CATEGORY_GUIDE_TEXT = {
  [UserNotificationCategory.RESTOCK]: '직진배송 상품 재입고 시 발송되는 알림입니다.',
  [UserNotificationCategory.EXPIRED_USER_BENEFIT]: '나의 포인트가 소멸되기 전에 발송되는 알림입니다.',
  [UserNotificationCategory.SAVED_PRODUCTS]: '찜한 상품의 가격이 하락하면 발송되는 알람입니다.',
};

export enum UserNotificationCustomCategory {
  ADVERTISEMENT = 'ADVERTISEMENT',
}

export type Category = UserNotificationCustomCategory | UserNotificationCategory;

export interface CategoryI {
  title: string;
  category: Category;
  sequence?: number;
  order?: number;
}

export const CATEGORY_NAME = {
  [UserNotificationCategory.ORDER]: '주문/배송',
  [UserNotificationCategory.RESTOCK]: '재입고',
  [UserNotificationCustomCategory.ADVERTISEMENT]: '혜택/이벤트',
  [UserNotificationCategory.EXPIRED_USER_BENEFIT]: '포인트 소멸',
  [UserNotificationCategory.REVIEW]: '커뮤니티',
  [UserNotificationCategory.SAVED_PRODUCTS]: '찜한 상품',
  [UserNotificationCategory.WEATHER]: '날씨',
};

// TODO: 멤버십 알림의 경우 발송주체가 현재 없어 리스트에서 제외함
export const CATEGORY_LIST = {
  [UserNotificationCategory.ORDER]: {
    title: CATEGORY_NAME[UserNotificationCategory.ORDER],
    category: UserNotificationCategory.ORDER,
    navigation: 'order_shipping',
    sequence: 1,
  },
  [UserNotificationCategory.RESTOCK]: {
    title: CATEGORY_NAME[UserNotificationCategory.RESTOCK],
    category: UserNotificationCategory.RESTOCK,
    navigation: 'restock',
    sequence: 3,
  },
  [UserNotificationCategory.NORMAL]: {
    title: CATEGORY_NAME[UserNotificationCustomCategory.ADVERTISEMENT],
    category: UserNotificationCustomCategory.ADVERTISEMENT,
    navigation: 'benefit_event',
    sequence: 2,
  },
  [UserNotificationCategory.EXPIRED_USER_BENEFIT]: {
    title: CATEGORY_NAME[UserNotificationCategory.EXPIRED_USER_BENEFIT],
    category: UserNotificationCategory.EXPIRED_USER_BENEFIT,
    navigation: 'expiring_point',
    sequence: 5,
  },
  [UserNotificationCategory.REVIEW]: {
    title: CATEGORY_NAME[UserNotificationCategory.REVIEW],
    category: UserNotificationCategory.REVIEW,
    navigation: 'community',
    sequence: 6,
    sub_categories: [
      {
        name: '리뷰 댓글, 답글',
        category: UserNotificationCategory.REVIEW,
        guide_text: '리뷰의 댓글이 등록된 경우 발송되는 알림입니다.',
        navigation_sub: 'review',
      },
      { name: 'é pick', category: UserNotificationCategory.EPICK, navigation_sub: 'epick' },
      { name: '톡라운지', category: UserNotificationCategory.TALK_LOUNGE, navigation_sub: 'talk_lounge' },
    ],
  },
  [UserNotificationCategory.SAVED_PRODUCTS]: {
    title: CATEGORY_NAME[UserNotificationCategory.SAVED_PRODUCTS],
    category: UserNotificationCategory.SAVED_PRODUCTS,
    navigation: 'saved_product',
    sequence: 4,
  },
  [UserNotificationCategory.WEATHER]: {
    title: CATEGORY_NAME[UserNotificationCategory.WEATHER],
    category: UserNotificationCategory.WEATHER,
    navigation: 'weather',
    sequence: 4,
  },
};
