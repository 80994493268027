import React, { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Gray_Light_White } from '@common/styles';

export interface BaseNavigationBarProps extends React.HTMLAttributes<HTMLDivElement> {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
}

export const BaseNavigationBar = forwardRef<HTMLDivElement, BaseNavigationBarProps>(
  ({ left, right, center, ...container_props }, ref) => {
    return (
      <Container ref={ref} {...container_props}>
        <LeftItem>{left}</LeftItem>
        <CenterItem>{center}</CenterItem>
        <RightItem>{right}</RightItem>
      </Container>
    );
  },
);

const Container = styled.nav`
  height: 52px;
  background: ${Gray_Light_White};
  display: flex;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
`;

const LeftItem = styled.div`
  flex: 1;
`;

const CenterItem = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  flex: 2;
`;

const RightItem = styled.div`
  text-align: right;
  flex: 1;
  & > * {
    margin-right: 16px;
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }
`;
