/**
 *
 * @param cookie_str
 * @param set_cookie_list
 * @returns
 */
export function mergeCookie(cookie_str: string | undefined, set_cookie_list: string[]) {
  let cookies = cookie_str ? cookie_str.split('; ') : [];
  for (const set_cookie of set_cookie_list) {
    const name_value = set_cookie.split(';')[0];
    const name = name_value.split('=')[0] + '=';
    cookies = [...cookies.filter((cookie) => !cookie.startsWith(name)), name_value];
  }
  return cookies.length > 0 ? cookies.join('; ') : undefined;
}
