import React from 'react';
import { HStack, IconArrowRightBold, Text } from '@croquiscom-pvt/zds';
import styled from '@emotion/styled';

import { CheckBox, CheckBoxProps } from '@common/toolbox';

export interface CheckboxListItemProps extends CheckBoxProps {
  label: string;
  onView?: () => void;
}

export const CheckboxListItem: React.FC<CheckboxListItemProps> = ({ label, onView, ...props }) => {
  return (
    <HStack align='space-between' blockAlign='center'>
      <CheckBox {...props}>
        <CheckBoxLabel variant='Body_15_Medium'>{label}</CheckBoxLabel>
      </CheckBox>
      {onView && (
        <IconButton type='button' onClick={onView}>
          <IconArrowRightBold size={24} v3Color='gray300' />
        </IconButton>
      )}
    </HStack>
  );
};

const CheckBoxLabel = styled(Text)`
  margin-left: 8px;
`;

const IconButton = styled.button`
  cursor: pointer;
`;
