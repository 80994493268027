import React from 'react';
import useBrowserEffect from 'hooks/useBrowserEffect';

import { setGa4UserId } from 'util/tracking';

export const GoogleAnalytics: React.FC = () => {
  useBrowserEffect(() => {
    try {
      const uuid = localStorage.getItem('uuid');
      if (uuid) {
        setGa4UserId(uuid);
      }
    } catch (err) {
      // ignore error
    }
  }, []);

  return null;
};
