import { MutableRefObject, ReactNode, useEffect, useRef } from 'react';
import { observe } from 'react-intersection-observer';

import { useLikeStatusSelector } from '../hooks/useLikeStatusSelector';
import { likeMetaStore } from '../store/LikeStore';

interface LikeObserverChildrenProps {
  isLiked: boolean;
  ref: MutableRefObject<HTMLElement | null>;
}

interface LikeObserverProps {
  children: ((props: LikeObserverChildrenProps) => ReactNode) | ReactNode;
  catalogProductId: string;
}

export const LikeObserver = (props: LikeObserverProps) => {
  const { catalogProductId, children } = props;

  const isLiked = useLikeStatusSelector(catalogProductId);
  const ref = useLikeMetaSubscribe(catalogProductId);

  if (typeof children === 'function') {
    return children({ isLiked, ref });
  }

  return children;
};

export function useLikeMetaSubscribe(catalogProductId: string) {
  const internalRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    let unobserve: (() => void) | undefined;
    if (internalRef && internalRef.current) {
      unobserve = observe(
        internalRef.current,
        (inView) => {
          likeMetaStore.setState((prevState) => {
            const nextState: (typeof prevState)[string] = {
              ...prevState[catalogProductId],
              id: catalogProductId,
              inView,
            };
            return { [catalogProductId]: nextState };
          });
        },
        { rootMargin: '0px 0px 70% 0px', threshold: 0.5 },
      );
    }

    return () => {
      if (unobserve) {
        unobserve();
      }
    };
  }, [catalogProductId]);

  return internalRef;
}
