import { ProductCardCarouselSlidesPerView } from './types';

export interface CreateCarouselLayoutManagerOptions {
  spaceBetween: number;
  slidesOffsetBefore: number;
  slidesOffsetAfter: number;
}

export function createCarouselLayoutManager(options: CreateCarouselLayoutManagerOptions) {
  const { spaceBetween, slidesOffsetBefore, slidesOffsetAfter } = options;

  function estimateWidthBySlides(windowWidth: number, slidesPerView: ProductCardCarouselSlidesPerView) {
    /* 
      계산 공식: width = (스크린 너비 - 캐러셀 좌측 컨테이너 여백 - 캐러셀 아이템 간 여백 - (스크린 너비 당 슬라이드 비율 - 1)) / 스크린 너비 당 슬라이드 비율
      예시:
        - 스크린 너비: 375px
        - 캐러셀 좌측 컨테이너 여백: 16px
        - 캐러셀 아이템 간 여백: 2px
        - 스크린 너비 당 슬라이드 비율: 3.14
        - 계산 결과: (375px - 16px - 2px - (3.14 - 1)) / 3.14 = 113.0127388535px
      관련 논의 스레드: https://croquis.slack.com/archives/C06AKCJSR0E/p1719540129166739 
    */
    const offsets = slidesOffsetBefore;
    const gap = spaceBetween;
    return (windowWidth - offsets - gap * (slidesPerView - 1)) / slidesPerView;
  }

  return { spaceBetween, slidesOffsetBefore, slidesOffsetAfter, estimateWidthBySlides };
}
