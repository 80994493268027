import { gql_request, GqlRequestOptions } from '@common/api-request';

import * as types from './index_types.2';

export function getUserPostboxList(variable: types.GetUserPostboxListVariables, options?: GqlRequestOptions) {
  const query = 'fragment UserPostboxItem on PostboxResponse { id uid category date_created date_read read icon_image_url image_url image_url_list title text url shop_id campaign_id site_id shop_info { shop_name shop_image } shop_unseen_count extra_info { buttons { name link_url } } } query GetUserPostboxList($categories: [String!], $limit: Int, $end_cursor: ID, $shop_id: ID) { user_postbox_list(categories: $categories, limit: $limit, end_cursor: $end_cursor, shop_id: $shop_id) { item_list { ...UserPostboxItem } limit end_cursor total_count total_unread_count } }';
  return gql_request<types.GetUserPostboxList, types.GetUserPostboxListVariables>('GetUserPostboxList', query, variable, { use_consumer: true, ...options });
}

export function getUserAccountShopNotificationList(variable: types.GetUserAccountShopNotificationListVariables, options?: GqlRequestOptions) {
  const query = 'fragment UserNotificationItem on UserNotification { id uid category date_created date_read read icon_image_url image_url image_url_list title text url shop_id campaign_id site_id shop_info { shop_name shop_image } shop_unseen_count extra_info { buttons { name link_url } } } query GetUserAccountShopNotificationList($shop_id: ID!) { user_account_shop_notification_list(shop_id: $shop_id) { shop_info { shop_name shop_image } item_list { ...UserNotificationItem } total_count } }';
  return gql_request<types.GetUserAccountShopNotificationList, types.GetUserAccountShopNotificationListVariables>('GetUserAccountShopNotificationList', query, variable, { use_consumer: true, ...options });
}

export function getUserNotificationCategoryList(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserNotificationCategoryList { user_notification_category_list { country_id category_list } }';
  return gql_request<types.GetUserNotificationCategoryList, null>('GetUserNotificationCategoryList', query, variable, { use_consumer: true, ...options });
}

export function deleteUserNotification(variable: types.DeleteUserNotificationVariables, options?: GqlRequestOptions) {
  const query = 'mutation DeleteUserNotification($input: DeleteUserNotificationInput!) { deleteUserNotification(input: $input) }';
  return gql_request<types.DeleteUserNotification, types.DeleteUserNotificationVariables>('DeleteUserNotification', query, variable, { use_consumer: true, ...options });
}

export function deleteUserAccountShopNotificationList(variable: types.DeleteUserAccountShopNotificationListVariables, options?: GqlRequestOptions) {
  const query = 'mutation DeleteUserAccountShopNotificationList($input: DeleteUserAccountShopNotificationListInput!) { deleteUserAccountShopNotificationList(input: $input) }';
  return gql_request<types.DeleteUserAccountShopNotificationList, types.DeleteUserAccountShopNotificationListVariables>('DeleteUserAccountShopNotificationList', query, variable, { use_consumer: true, ...options });
}

export function updateUserNotificationSeenDate(variable?: null, options?: GqlRequestOptions) {
  const query = 'mutation UpdateUserNotificationSeenDate { updateUserNotificationSeenDate }';
  return gql_request<types.UpdateUserNotificationSeenDate, null>('UpdateUserNotificationSeenDate', query, variable, { use_consumer: true, ...options });
}

export function markUserNotificationAsRead(variable: types.MarkUserNotificationAsReadVariables, options?: GqlRequestOptions) {
  const query = 'mutation MarkUserNotificationAsRead($input: MarkUserNotificationAsReadInput!) { markUserNotificationAsRead(input: $input) { total_unseen_count } }';
  return gql_request<types.MarkUserNotificationAsRead, types.MarkUserNotificationAsReadVariables>('MarkUserNotificationAsRead', query, variable, { use_consumer: true, ...options });
}

export function getUserAdNotiStatus(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserAdNotiStatus { user { ad_noti_status } }';
  return gql_request<types.GetUserAdNotiStatus, null>('GetUserAdNotiStatus', query, variable, { use_consumer: true, ...options });
}

export function updateUserAdNotiStatus(variable: types.UpdateUserAdNotiStatusVariables, options?: GqlRequestOptions) {
  const query = 'mutation UpdateUserAdNotiStatus($input: UpdateUserAdNotiStatusInput!) { updateUserAdNotiStatus(input: $input) { success message } }';
  return gql_request<types.UpdateUserAdNotiStatus, types.UpdateUserAdNotiStatusVariables>('UpdateUserAdNotiStatus', query, variable, { use_consumer: true, ...options });
}

export function getMarketingAgreementStatus(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetMarketingAgreementStatus { user_account_marketing_agreement { sms_reception_agreed email_reception_agreed nightly_ad_noti_agreed } }';
  return gql_request<types.GetMarketingAgreementStatus, null>('GetMarketingAgreementStatus', query, variable, { use_consumer: true, ...options });
}

export function updateSmsReceptionAgreed(variable: types.UpdateSmsReceptionAgreedVariables, options?: GqlRequestOptions) {
  const query = 'mutation UpdateSmsReceptionAgreed($input: SetUserAccountSmsReceptionAgreedInput!) { setUserAccountSmsReceptionAgreed(input: $input) }';
  return gql_request<types.UpdateSmsReceptionAgreed, types.UpdateSmsReceptionAgreedVariables>('UpdateSmsReceptionAgreed', query, variable, { use_consumer: true, ...options });
}

export function updateEmailReceptionAgreed(variable: types.UpdateEmailReceptionAgreedVariables, options?: GqlRequestOptions) {
  const query = 'mutation UpdateEmailReceptionAgreed($input: SetUserAccountEmailReceptionAgreedInput!) { setUserAccountEmailReceptionAgreed(input: $input) }';
  return gql_request<types.UpdateEmailReceptionAgreed, types.UpdateEmailReceptionAgreedVariables>('UpdateEmailReceptionAgreed', query, variable, { use_consumer: true, ...options });
}

export function updateNightlyAdNotiAgreed(variable: types.UpdateNightlyAdNotiAgreedVariables, options?: GqlRequestOptions) {
  const query = 'mutation UpdateNightlyAdNotiAgreed($input: SetUserAccountNightlyAdNotiAgreedInput!) { setUserAccountNightlyAdNotiAgreed(input: $input) }';
  return gql_request<types.UpdateNightlyAdNotiAgreed, types.UpdateNightlyAdNotiAgreedVariables>('UpdateNightlyAdNotiAgreed', query, variable, { use_consumer: true, ...options });
}

export function getUserPushAgreementStatusList(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserPushAgreementStatusList { user_notification_push_agreement_list { total_count item_list { category_id agreed } } }';
  return gql_request<types.GetUserPushAgreementStatusList, null>('GetUserPushAgreementStatusList', query, variable, { use_consumer: true, ...options });
}

export function updateUserPushAgreementStatus(variable: types.UpdateUserPushAgreementStatusVariables, options?: GqlRequestOptions) {
  const query = 'mutation UpdateUserPushAgreementStatus($input: SetUserNotificationPushAgreedInput!) { setUserNotificationPushAgreed(input: $input) }';
  return gql_request<types.UpdateUserPushAgreementStatus, types.UpdateUserPushAgreementStatusVariables>('UpdateUserPushAgreementStatus', query, variable, { use_consumer: true, ...options });
}

export function getUserNotificationAgreementList(variable?: null, options?: GqlRequestOptions) {
  const query = 'query GetUserNotificationAgreementList { user_notification_agreement_list { item_list { order name description item_list { order name description type category agreed } } } }';
  return gql_request<types.GetUserNotificationAgreementList, null>('GetUserNotificationAgreementList', query, variable, { use_consumer: true, ...options });
}

export function updateAgreeUserNotification(variable: types.UpdateAgreeUserNotificationVariables, options?: GqlRequestOptions) {
  const query = 'mutation UpdateAgreeUserNotification($input: AgreeUserNotificationInput!) { agreeUserNotification(input: $input) { message } }';
  return gql_request<types.UpdateAgreeUserNotification, types.UpdateAgreeUserNotificationVariables>('UpdateAgreeUserNotification', query, variable, { use_consumer: true, ...options });
}
