import React, { ReactNode, useState } from 'react';
import {
  HStack,
  IconCautionRegular32,
  IconCheckRegular32,
  IconEyeOffRegular32,
  IconEyeOnRegular32,
} from '@croquiscom/zds';
import styled from '@emotion/styled';

import { colors } from '@common/styles';
import { FormItem, TextField, TextFieldProps } from '@common/toolbox';

export interface FormInputProps extends TextFieldProps {
  label?: ReactNode;
  status?: 'error' | 'success';
  error_message?: string;
  expire_time?: number;
  onExpire?: () => void;
  suffix?: ReactNode;
  button?: ReactNode;
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  status,
  error_message,
  suffix,
  button,
  type,
  disabled,
  ...props
}) => {
  const has_error = !!error_message;
  const is_valid = status === 'success';
  const [input_type, setInputType] = useState(type);
  const selected_suffix = suffix ? (
    suffix
  ) : has_error ? (
    <IconCautionRegular32 color={colors.Red_Light_200} />
  ) : is_valid ? (
    <IconCheckRegular32 color={colors.Green_Light_200} />
  ) : undefined;

  return (
    <FormItem label={label} error={error_message}>
      <InputContainer>
        <TextField
          disabled={disabled}
          type={input_type}
          state={has_error ? 'error' : undefined}
          {...props}
          suffix={
            <StatusBox spacing={8} status={has_error ? 'error' : status} disabled={disabled}>
              {selected_suffix}
              <div
                onClick={() => {
                  setInputType(input_type === 'password' ? 'text' : 'password');
                }}
              >
                {input_type === 'password' && (
                  <IconEyeOffRegular32 color={disabled ? colors.Gray_Light_300 : colors.Gray_Light_400} />
                )}
                {type === 'password' && input_type === 'text' && (
                  <IconEyeOnRegular32 color={disabled ? colors.Gray_Light_300 : colors.Gray_Light_400} />
                )}
              </div>
            </StatusBox>
          }
        />
        {button}
      </InputContainer>
    </FormItem>
  );
};

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
const StatusBox = styled(HStack)<{ status?: 'error' | 'success'; disabled?: boolean }>`
  color: ${({ status, disabled }) =>
    status === 'error'
      ? colors.Red_Light_200
      : disabled
      ? colors.Gray_Light_300
      : status === 'success'
      ? colors.Green_Light_200
      : 'inherit'};
`;
