import type { ProductCardThumbnailProps } from '@croquiscom-pvt/zds';

export interface UseThumbnailOptions {
  webpImageUrl: string | null;
  imageUrl: string | null;
  jpegImageUrl?: string | null;
}

export function useThumbnail(options: UseThumbnailOptions) {
  const { imageUrl, webpImageUrl, jpegImageUrl } = options;

  const thumbnailProps: ProductCardThumbnailProps = {
    src: jpegImageUrl ? jpegImageUrl : webpImageUrl ? webpImageUrl : imageUrl ? imageUrl : '',
  };

  return {
    thumbnailProps,
  };
}
