import React from 'react';
import { ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';
import clsx from 'clsx';

import { useThumbnail, UseThumbnailOptions } from '../hooks/useThumbnail';

export interface ThumbnailProps extends UseThumbnailOptions, Pick<ProductCardProps, 'size'> {
  className?: string;
  children: React.ReactNode;
}

export const Thumbnail = (props: ThumbnailProps) => {
  const { size, className, imageUrl, webpImageUrl, jpegImageUrl, children } = props;

  const { thumbnailProps } = useThumbnail({
    imageUrl,
    webpImageUrl,
    jpegImageUrl,
  });

  return (
    <ProductCard.Thumbnail css={productCardThumbnailCss} className={clsx([size, className])} {...thumbnailProps}>
      {children}
    </ProductCard.Thumbnail>
  );
};

const productCardThumbnailCss = css`
  &.small {
    margin-bottom: 10px;
  }
  &.medium {
    margin-bottom: 12px;
  }
  &.large {
    margin-bottom: 12px;
  }
`;
