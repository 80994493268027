import { ClassNamesContent } from '@emotion/react';

interface CssVariables {
  column: string;
  spacing: string;
}

export function getWrapperCss(css: ClassNamesContent['css'], cssVariables: CssVariables, columnCount: number | 'auto') {
  return css({
    'overflow': 'hidden',
    [cssVariables.spacing]: 2,
    '&.basic': {
      margin: '0px 0px',
    },
    '&.content': {
      margin: '0px 16px',
    },
    ...(columnCount === 'auto'
      ? {
          [cssVariables.column]: 2,
          '@media screen and (min-width: 450px)': {
            [cssVariables.column]: 3,
          },
        }
      : { [cssVariables.column]: columnCount }),
  });
}
