import { callZigzagFunction } from '../../callZigzagFunction';
import { zigzagHandler } from '../../handlers';

// statusbar height 값 리턴을 위해 웹에서 콜 (리턴값은 `onStatusbarHeight` 참조)
// 안드로이드만 구현되어있습니다.
// v6.84.0
export const gsbh = () => {
  return new Promise<number>(resolve => {
    callZigzagFunction('gsbh')();
    zigzagHandler('onStatusbarHeight', statusbarHeight => {
      resolve(statusbarHeight);
    });
  });
};
