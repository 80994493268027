import { gridLayoutManager } from './grid-container/gridLayoutManager';
export { fastShipDeliveryIcon } from './constants';

if (typeof window !== 'undefined') {
  gridLayoutManager.init();
}

export type { ProductCardProviderProps } from './ProductCardContext';
export { ProductCardProvider } from './ProductCardContext';

// carousel
export * as ProductCardCarousel from './carousel/Carousel';

// components
export type { UseHorizontalProductCardWithItemOptions } from './components/horizontal-product-card-with-item/hooks/useHorizontalProductCardWithItem';
export { useHorizontalProductCardWithItem } from './components/horizontal-product-card-with-item/hooks/useHorizontalProductCardWithItem';
export type { HorizontalProductCardWithItemProps } from './components/horizontal-product-card-with-item/HorizontalProductCardWithItem';
export { HorizontalProductCardWithItem } from './components/horizontal-product-card-with-item/HorizontalProductCardWithItem';
export { ThumbnailLikeButton } from './components/ThumbnailLikeButton';
export type { UseVerticalProductCardWithItemOptions } from './components/vertical-product-card-with-item/hooks/useVerticalProductCardWithItem';
export { useVerticalProductCardWithItem } from './components/vertical-product-card-with-item/hooks/useVerticalProductCardWithItem';
export type { VerticalProductCardWithItemProps } from './components/vertical-product-card-with-item/VerticalProductCardWithItem';
export { VerticalProductCardWithItem } from './components/vertical-product-card-with-item/VerticalProductCardWithItem';
// 아래 코드는 지면에서 VerticalProductCard를 사용하지 못하고 급하게 신규 컴포넌트를 만들어야 할 경우를 위한 용도입니다.
// 사용하기 전 **반드시** 시스템화에 대한 논의 후 사용해주세요. (논의 없이 사용될 경우 코드 관리가 제대로 되지 않을 수 있습니다.)
// vertical-product-card/context
export type {
  VerticalProductCardContextType,
  VerticalProductCardProviderProps,
} from './vertical-product-card/VerticalProductCardContext';
// vertical-product-card/components
export type { MetadataOneDayDeliveryProps as VerticalProductCardMetadataOneDayDeliveryProps } from './vertical-product-card/components/MetadataOneDayDelivery';
export { MetadataOneDayDelivery as VerticalProductCardMetadataOneDayDelivery } from './vertical-product-card/components/MetadataOneDayDelivery';
export type { MetadataPlpBadgeItemsProps as VerticalProductCardMetadataPlpBadgeItemsProps } from './vertical-product-card/components/MetadataPlpBadgeItems';
export { MetadataPlpBadgeItems as VerticalProductCardMetadataPlpBadgeItems } from './vertical-product-card/components/MetadataPlpBadgeItems';
export type { MetadataPriceProps as VerticalProductCardMetadataPriceProps } from './vertical-product-card/components/MetadataPrice';
export { MetadataPrice as VerticalProductCardMetadataPrice } from './vertical-product-card/components/MetadataPrice';
export type { MetadataReviewWithFomoProps as VerticalProductCardMetadataReviewWithFomoProps } from './vertical-product-card/components/MetadataReviewWithFomo';
export { MetadataReviewWithFomo as VerticalProductCardMetadataReviewWithFomo } from './vertical-product-card/components/MetadataReviewWithFomo';
export type { MetadataRootProps as VerticalProductCardMetadataRootProps } from './vertical-product-card/components/MetadataRoot';
export { MetadataRoot as VerticalProductCardMetadataRoot } from './vertical-product-card/components/MetadataRoot';
export type { MetadataTitleProps as VerticalProductCardMetadataTitleProps } from './vertical-product-card/components/MetadataTitle';
export { MetadataTitle as VerticalProductCardMetadataTitle } from './vertical-product-card/components/MetadataTitle';
export type {
  ProductCardLinkItemProps as VerticalProductCardProductCardLinkItemProps,
  ProductCardLinkProps as VerticalProductCardProductCardLinkProps,
} from './vertical-product-card/components/ProductCardLink';
export {
  getItemPropsByVerticalProductCardProps,
  ProductCardLink as VerticalProductCardProductCardLink,
} from './vertical-product-card/components/ProductCardLink';
export type { ProductCardRootProps as VerticalProductCardProductCardRootProps } from './vertical-product-card/components/ProductCardRoot';
export { ProductCardRoot as VerticalProductCardProductCardRoot } from './vertical-product-card/components/ProductCardRoot';
export type { ThumbnailProps as VerticalProductCardThumbnailProps } from './vertical-product-card/components/Thumbnail';
export { Thumbnail as VerticalProductCardThumbnail } from './vertical-product-card/components/Thumbnail';
export type { ThumbnailEmblemProps as VerticalProductCardThumbnailEmblemProps } from './vertical-product-card/components/ThumbnailEmblem';
export { ThumbnailEmblem as VerticalProductCardThumbnailEmblem } from './vertical-product-card/components/ThumbnailEmblem';
export type { ThumbnailNudgeProps as VerticalProductCardThumbnailNudgeProps } from './vertical-product-card/components/ThumbnailNudge';
export { ThumbnailNudge as VerticalProductCardThumbnailNudge } from './vertical-product-card/components/ThumbnailNudge';
export type { ThumbnailRankProps as VerticalProductCardThumbnailRankProps } from './vertical-product-card/components/ThumbnailRank';
export { ThumbnailRank as VerticalProductCardThumbnailRank } from './vertical-product-card/components/ThumbnailRank';
// vertical-product-card/hooks
export type { UseMetadataOneDayDeliveryOptions as UseVerticalProductCardMetadataOneDayDeliveryOptions } from './vertical-product-card/hooks/useMetadataOneDayDelivery';
export { useMetadataOneDayDelivery as useVerticalProductCardMetadataOneDayDelivery } from './vertical-product-card/hooks/useMetadataOneDayDelivery';
export type { UseMetadataPlpBadgeItemsOptions as UseVerticalProductCardMetadataPlpBadgeItemsOptions } from './vertical-product-card/hooks/useMetadataPlpBadgeItems';
export { useMetadataPlpBadgeItems as useVerticalProductCardMetadataPlpBadgeItems } from './vertical-product-card/hooks/useMetadataPlpBadgeItems';
export type { UseMetadataPriceOptions as UseVerticalProductCardMetadataPriceOptions } from './vertical-product-card/hooks/useMetadataPrice';
export { useMetadataPrice as useVerticalProductCardMetadataPrice } from './vertical-product-card/hooks/useMetadataPrice';
export type { UseMetadataReviewOptions as UseVerticalProductCardMetadataReviewOptions } from './vertical-product-card/hooks/useMetadataReview';
export { useMetadataReview as useVerticalProductCardMetadataReview } from './vertical-product-card/hooks/useMetadataReview';
export type { UseMetadataTitleOptions as UseVerticalProductCardMetadataTitleOptions } from './vertical-product-card/hooks/useMetadataTitle';
export { useMetadataTitle as useVerticalProductCardMetadataTitle } from './vertical-product-card/hooks/useMetadataTitle';
export type { UseThumbnailOptions as UseVerticalProductCardThumbnailOptions } from './vertical-product-card/hooks/useThumbnail';
export { useThumbnail as useVerticalProductCardThumbnail } from './vertical-product-card/hooks/useThumbnail';
export type { UseThumbnailEmblemOptions } from './vertical-product-card/hooks/useThumbnailEmblem';
export { useThumbnailEmblem } from './vertical-product-card/hooks/useThumbnailEmblem';
export type { UseThumbnailNudgeOptions } from './vertical-product-card/hooks/useThumbnailNudge';
export { useThumbnailNudge } from './vertical-product-card/hooks/useThumbnailNudge';
// vertical-product-card/types
export type { UxGoodsCardItemForVerticalProductCard } from './vertical-product-card/types';
// vertical-product-card/utils
export { getVerticalProductCardPropsByUxGoodsCardItemForVerticalProductCard } from './vertical-product-card/utils';
export type { VerticalProductCardProps } from './vertical-product-card/VerticalProductCard';
export { VerticalProductCard } from './vertical-product-card/VerticalProductCard';
// grid-container
export type { CreateGridContainerOptions } from './grid-container/Factory';
export { createGridContainer } from './grid-container/Factory';
// vertical-product-card-grid-container
export type { VerticalProductCardGridContainerProps } from './vertical-product-card-grid-container/VerticalProductCardGridContainer';
export { VerticalProductCardGridContainer } from './vertical-product-card-grid-container/VerticalProductCardGridContainer';
// virtuoso-grid-container
export type { CreateVirtuosoGridContainerOptions } from './virtuoso-grid-container/Factory';
export { createVirtuosoGridContainer } from './virtuoso-grid-container/Factory';
export type { VirtuosoGridContainerProps } from './virtuoso-grid-container/types';
// vertical-product-card-virtuoso-grid-container
export type { VerticalProductCardVirtuosoGridContainerProps } from './vertical-product-card-virtuoso-grid-container/VerticalProductCardVirtuosoGridContainer';
export { VerticalProductCardVirtuosoGridContainer } from './vertical-product-card-virtuoso-grid-container/VerticalProductCardVirtuosoGridContainer';
// 아래 코드는 지면에서 HorizontalProductCard를 사용하지 못하고 급하게 신규 컴포넌트를 만들어야 할 경우를 위한 용도입니다.
// 사용하기 전 **반드시** 시스템화에 대한 논의 후 사용해주세요. (논의 없이 사용될 경우 코드 관리가 제대로 되지 않을 수 있습니다.)
// horizontal-product-card/context
export type {
  HorizontalProductCardContextType,
  HorizontalProductCardProviderProps,
} from './horizontal-product-card/HorizontalProductCardContext';

// horizontal-product-card/components
export type { MetadataLikeButtonProps as HorizontalProductCardMetadataLikeButtonProps } from './horizontal-product-card/components/MetadataLikeButton';
export { MetadataLikeButton as HorizontalProductCardMetadataLikeButton } from './horizontal-product-card/components/MetadataLikeButton';
export type { MetadataPlpBadgeItemsProps as HorizontalProductCardMetadataPlpBadgeItemsProps } from './horizontal-product-card/components/MetadataPlpBadgeItems';
export { MetadataPlpBadgeItems as HorizontalProductCardMetadataPlpBadgeItems } from './horizontal-product-card/components/MetadataPlpBadgeItems';
export type { MetadataPriceProps as HorizontalProductCardMetadataPriceProps } from './horizontal-product-card/components/MetadataPrice';
export { MetadataPrice as HorizontalProductCardMetadataPrice } from './horizontal-product-card/components/MetadataPrice';
export type { MetadataReviewWithFomoProps as HorizontalProductCardMetadataReviewProps } from './horizontal-product-card/components/MetadataReviewWithFomo';
export { MetadataReviewWithFomo as HorizontalProductCardMetadataReviewWithFomo } from './horizontal-product-card/components/MetadataReviewWithFomo';
export type { MetadataRootProps as HorizontalProductCardMetadataRootProps } from './horizontal-product-card/components/MetadataRoot';
export { MetadataRoot as HorizontalProductCardMetadataRoot } from './horizontal-product-card/components/MetadataRoot';
export type { MetadataTitleProps as HorizontalProductCardMetadataTitleProps } from './horizontal-product-card/components/MetadataTitle';
export { MetadataTitle as HorizontalProductCardMetadataTitle } from './horizontal-product-card/components/MetadataTitle';
export type {
  ProductCardLinkItemProps as HorizontalProductCardProductCardLinkItemProps,
  ProductCardLinkProps as HorizontalProductCardProductCardLinkProps,
} from './horizontal-product-card/components/ProductCardLink';
export {
  getItemPropsByHorizontalProductCardProps,
  ProductCardLink as HorizontalProductCardProductCardLink,
} from './horizontal-product-card/components/ProductCardLink';
export type { ProductCardRootProps as HorizontalProductCardRootProps } from './horizontal-product-card/components/ProductCardRoot';
export { ProductCardRoot as HorizontalProductCardRoot } from './horizontal-product-card/components/ProductCardRoot';
export type { ThumbnailProps as HorizontalProductCardThumbnailProps } from './horizontal-product-card/components/Thumbnail';
export { Thumbnail as HorizontalProductCardThumbnail } from './horizontal-product-card/components/Thumbnail';
export type { ThumbnailEmblemProps as HorizontalProductCardThumbnailEmblemProps } from './horizontal-product-card/components/ThumbnailEmblem';
export { ThumbnailEmblem as HorizontalProductCardThumbnailEmblem } from './horizontal-product-card/components/ThumbnailEmblem';
export type { ThumbnailNudgeProps as HorizontalProductCardThumbnailNudgeProps } from './horizontal-product-card/components/ThumbnailNudge';
export { ThumbnailNudge as HorizontalProductCardThumbnailNudge } from './horizontal-product-card/components/ThumbnailNudge';
export type { ThumbnailRankProps as HorizontalProductCardThumbnailRankProps } from './horizontal-product-card/components/ThumbnailRank';
export { ThumbnailRank as HorizontalProductCardThumbnailRank } from './horizontal-product-card/components/ThumbnailRank';
// horizontal-product-card/hooks
export type { UseMetadataPlpBadgeItemsOptions as UseHorizontalProductCardMetadataPlpBadgeItemsOptions } from './horizontal-product-card/hooks/useMetadataPlpBadgeItems';
export { useMetadataPlpBadgeItems as useHorizontalProductCardMetadataPlpBadgeItems } from './horizontal-product-card/hooks/useMetadataPlpBadgeItems';
export type { UseMetadataPriceOptions as UseHorizontalProductCardMetadataPriceOptions } from './horizontal-product-card/hooks/useMetadataPrice';
export { useMetadataPrice as useHorizontalProductCardMetadataPrice } from './horizontal-product-card/hooks/useMetadataPrice';
export type { UseMetadataReviewOptions as UseHorizontalProductCardMetadataReviewOptions } from './horizontal-product-card/hooks/useMetadataReview';
export { useMetadataReview as useHorizontalProductCardMetadataReview } from './horizontal-product-card/hooks/useMetadataReview';
export type { UseMetadataTitleOptions as UseHorizontalProductCardMetadataTitleOptions } from './horizontal-product-card/hooks/useMetadataTitle';
export { useMetadataTitle as useHorizontalProductCardMetadataTitle } from './horizontal-product-card/hooks/useMetadataTitle';
export { useSizeByThumbnailWidth as useHorizontalProductCardSizeByThumbnailWidth } from './horizontal-product-card/hooks/useSizeByThumbnailWidth';
export type { UseThumbnailOptions as UseHorizontalProductCardThumbnailOptions } from './horizontal-product-card/hooks/useThumbnail';
export { useThumbnail as useHorizontalProductCardThumbnail } from './horizontal-product-card/hooks/useThumbnail';
export type { UseThumbnailEmblemOptions as UseHorizontalThumbnailEmblemOptions } from './horizontal-product-card/hooks/useThumbnailEmblem';
export { useThumbnailEmblem as useHorizontalThumbnailEmblem } from './horizontal-product-card/hooks/useThumbnailEmblem';
export type { UseThumbnailNudgeOptions as UseHorizontalThumbnailNudgeOptions } from './horizontal-product-card/hooks/useThumbnailNudge';
export { useThumbnailNudge as useHorizontalThumbnailNudge } from './horizontal-product-card/hooks/useThumbnailNudge';
export type { UseVisibleOfBlocksOptions as UseHorizontalProductCardVisibleOfBlockOptions } from './horizontal-product-card/hooks/useVisibleOfBlocks';
export { useVisibleOfBlocks as useHorizontalProductCardVisibleOfBlocks } from './horizontal-product-card/hooks/useVisibleOfBlocks';
// horizontal-product-card/HorizontalProductCard
export type { HorizontalProductCardProps } from './horizontal-product-card/HorizontalProductCard';
export { HorizontalProductCard } from './horizontal-product-card/HorizontalProductCard';
// horizontal-product-card/types
export type { UxGoodsCardItemForHorizontalProductCard } from './horizontal-product-card/types';
// horizontal-product-card/utils
export { getHorizontalProductCardPropsByUxGoodsCardItemForHorizontalProductCard } from './horizontal-product-card/utils';
// horizontal-product-card-list-container
export type { HorizontalProductCardListContainerProps } from './horizontal-product-card-list-container/HorizontalProductCardListContainer';
export { HorizontalProductCardListContainer } from './horizontal-product-card-list-container/HorizontalProductCardListContainer';
export type { SelectableVerticalProductCardProps } from './vertical-product-card/SelectableVerticalProductCard';
export { SelectableVerticalProductCard } from './vertical-product-card/SelectableVerticalProductCard';
