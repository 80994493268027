import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import { useUserLocale } from '@common/i18n';
import getConfig from 'next/config';

const runtime_config = getConfig().publicRuntimeConfig;

const origin_url = typeof window !== 'undefined' ? window.location.origin : '';

export const env = runtime_config?.stage
  ? runtime_config.stage
  : origin_url.includes('zigzag.kr')
  ? 'production'
  : origin_url.match(/(.*)\.zigzag/)?.[1] ?? 'local';

const Stage = () => {
  const [stage, setStage] = useState<string>('');
  const { language, country, currency } = useUserLocale();

  useEffect(() => {
    setStage(env);
  }, []);

  return (
    <div
      css={css`
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10000;
        display: ${!!stage && stage !== 'production' ? 'block' : 'none'};
        font-size: 13px;
        opacity: 0.5;
        color: green;

        .stage {
          margin: 0 3px;
          font-weight: bold;
        }

        .user-locale {
          font-size: 10px;
        }
      `}
    >
      <span className='stage'>{stage.toUpperCase()}</span>
      <span className='user-locale'>
        language: {language}, country: {country}, currency: {currency}
      </span>
    </div>
  );
};
export default Stage;
