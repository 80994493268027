import { BaseConfigType } from './default';
import ProductionConfig from './production';

const Config: BaseConfigType = {
  ...ProductionConfig,
  baseDomain: 'beta-qm.zigzag.kr',
  apiBaseUrl: 'https://api.beta-qm.zigzag.kr/api/store',
  apiConsumerBaseUrl: 'https://api.beta-qm.zigzag.kr/api/2',
  appUrl: 'https://store.beta-qm.zigzag.kr',
  contentUrl: 'https://content.beta-qm.zigzag.kr',
  resUrl: 'https://cf.res.s.zigzag.kr',
  zigzag_postcode_url: 'https://store.beta.zigzag.kr/postcode',
};

export default Config;
