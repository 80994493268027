import getConfig from 'next/config';

import BaseConfig, { BaseConfigType } from './default';

const {
  publicRuntimeConfig: { release, stage: runtimeStage },
} = getConfig() || { publicRuntimeConfig: {} };

const stage = runtimeStage || process.env.STAGE || process.env.NODE_ENV;

let Config = {
  ...BaseConfig,
  release,
  stage,
};

const DEVELOPMENT_ENV = process.env.DEVELOPMENT_ENV;

try {
  const EnvConfig: BaseConfigType = stage.includes('dev-') ? require(`./dev`).default : require(`./${stage}`).default;

  const base_domain =
    EnvConfig.baseDomain &&
    (DEVELOPMENT_ENV ? `${DEVELOPMENT_ENV}.development.${EnvConfig.baseDomain}` : EnvConfig.baseDomain);

  Config = {
    ...Config,
    ...EnvConfig,
    ...(base_domain &&
      [
        'apiBaseUrl',
        'apiConsumerBaseUrl',
        'appUrl',
        'payApiBaseUrl',
        // TODO: dev에서 포트 노출이 안되 임시로 alpha, prod 서비스 사용.
        // 'simplePayPassword',
      ].reduce<BaseConfigType>((result, key) => {
        result[key] = EnvConfig[key].replace(EnvConfig.baseDomain, base_domain);
        return result;
      }, {})),
  };
} catch (e) {
  console.log(`Cannot find configs for env=${stage}`);
}

export * from './default';
export { Config };
