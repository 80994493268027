import styled from '@emotion/styled';

import { buildCSSWithLength } from '../styles/buildCSSWithLength';
import { size } from '../styles/size';
import { space } from '../styles/space';
import { StackAlignment, StackProps } from './types';

export type VerticalAlignment = 'stretch' | 'top' | 'bottom' | 'center';

const STACK_ALIGNMENT_BY_VERTICAL_ALIGNMENT: Record<VerticalAlignment, StackAlignment> = {
  stretch: 'stretch',
  top: 'flex-start',
  bottom: 'flex-end',
  center: 'center',
};

export interface HStackProps extends StackProps {
  alignment?: VerticalAlignment;
}

export const HStack = styled.div<HStackProps>`
  display: flex;
  flex-direction: row;
  ${({ alignment }) => alignment !== undefined && `align-items: ${STACK_ALIGNMENT_BY_VERTICAL_ALIGNMENT[alignment]}`};

  ${size};
  ${space};

  ${({ spacing }) => {
    switch (spacing) {
      case undefined:
        return '';
      case 'center':
        return 'justify-content: center;';
      case 'auto':
        return 'justify-content: space-between;';
      default:
        return buildCSSWithLength('column-gap', spacing);
    }
  }}
`;
