import type { ProductCardMetadataTitleProps, ProductCardProps } from '@croquiscom-pvt/zds';

import { BadgeItem } from '../../components/BadgeItem';
import { BadgeListItemProps } from '../types';
import { covertBadgeItemPropsBySize } from '../utils';

export interface UseMetadataTitleOptions extends Pick<ProductCardProps, 'size' | 'soldOut'> {
  title: {
    brandName?: string | null;
    productName: string;
    productNameLineClamp?: ProductCardMetadataTitleProps['productNameLineClamp'];
    /** 브랜드 이름 옆에 붙는 뱃지 */
    brandNameBadgeList?: BadgeListItemProps[];
  };
}

export function useMetadataTitle(options: UseMetadataTitleOptions): {
  metadataTitleProps: ProductCardMetadataTitleProps;
} {
  const { size, soldOut, title } = options;

  const metadataTitleProps: ProductCardMetadataTitleProps = {
    size,
    soldOut,
    brandName: title.brandName,
    productName: title.productName,
    badgeItems: title.brandNameBadgeList
      ? title.brandNameBadgeList.map((item) => {
          return <BadgeItem {...covertBadgeItemPropsBySize(item, size)} />;
        })
      : undefined,
    productNameLineClamp: title.productNameLineClamp,
  };
  return {
    metadataTitleProps,
  };
}
