import type { ProductCardMetadataBadgeItemsProps, ProductCardProps } from '@croquiscom-pvt/zds';

import { BadgeItem } from '../../components/BadgeItem';
import { BadgeListItemProps } from '../types';
import { covertBadgeItemPropsBySize } from '../utils';

export type ReturnMetadataPlpBadgeItemsFunction<T extends undefined | null | BadgeListItemProps[]> =
  T extends BadgeListItemProps[]
    ? { hasPlpBadgeItems: true; metadataPlpBadgeItemsProps: ProductCardMetadataBadgeItemsProps }
    : { hasPlpBadgeItems: false; metadataPlpBadgeItemsProps: undefined };

export interface UseMetadataPlpBadgeItemsOptions extends Pick<ProductCardProps, 'size'> {
  plpBadgeList?: BadgeListItemProps[] | null;
}

export function useMetadataPlpBadgeItems<
  T extends UseMetadataPlpBadgeItemsOptions,
  R = ReturnMetadataPlpBadgeItemsFunction<T['plpBadgeList']>,
>(options: T): R {
  const { plpBadgeList, size } = options;

  const hasPlpBadgeItems = plpBadgeList !== null && plpBadgeList !== undefined && plpBadgeList.length > 0;
  return {
    hasPlpBadgeItems,
    metadataPlpBadgeItemsProps: hasPlpBadgeItems
      ? {
          items: plpBadgeList?.map((item, index) => {
            return <BadgeItem key={index} {...covertBadgeItemPropsBySize(item, size)} />;
          }),
        }
      : undefined,
  } as R;
}
