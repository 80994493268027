import type { ProductCardThumbnailRankProps } from '@croquiscom-pvt/zds';

export type ReturnThumbnailRankFunction<T extends undefined | ProductCardThumbnailRankProps> =
  T extends ProductCardThumbnailRankProps
    ? { hasRank: true; thumbnailRankProps: ProductCardThumbnailRankProps }
    : { hasRank: false; thumbnailRankProps: undefined };

export interface UseThumbnailRankOptions {
  rank?: ProductCardThumbnailRankProps;
}

export function useThumbnailRank<T extends UseThumbnailRankOptions, R = ReturnThumbnailRankFunction<T['rank']>>(
  options: T,
): R {
  const { rank } = options;
  const hasRank = rank !== undefined;

  return {
    hasRank,
    thumbnailRankProps: hasRank ? rank : undefined,
  } as R;
}
