import React from 'react';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

export const IconLock: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg width={size} height={size} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x='6.25' y='10.25' width='12.5' height='10.5' rx='1.25' stroke='#121314' stroke-width='1.5' />
    <path
      d='M16.5 7.5V7.5C16.5 5.84315 15.1569 4.5 13.5 4.5H12.1923C10.1531 4.5 8.5 6.1531 8.5 8.19231V10.5'
      stroke='#121314'
      stroke-width='1.5'
    />
  </svg>
);
