export enum TalkStudentStorageKey {
  REQUEST = 'request',
  PRESENT_WEB_URL = 'present_web_url',
}

export interface TalkStudentRequest {
  request_id: string;
  presentation_definition_id: string;
}

const is_browser = typeof window !== 'undefined' && typeof sessionStorage !== 'undefined';

export class TalkStudentSessionStorage {
  static saveData<T>(key: TalkStudentStorageKey, data_to_save: T) {
    try {
      if (is_browser) {
        sessionStorage.setItem(key, JSON.stringify(data_to_save));
      }
    } catch (err) {
      console.error(err);
    }
  }

  static getData<T>(key: TalkStudentStorageKey): T | null {
    try {
      if (is_browser) {
        return JSON.parse(sessionStorage.getItem(key)!);
      } else {
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  static clearData(key: TalkStudentStorageKey) {
    if (is_browser) {
      sessionStorage.removeItem(key);
    }
  }

  static savePresentWebUrl(present_web_url: string) {
    this.saveData<string>(TalkStudentStorageKey.PRESENT_WEB_URL, present_web_url);
  }

  static saveRequest(request: TalkStudentRequest) {
    this.saveData<TalkStudentRequest>(TalkStudentStorageKey.REQUEST, request);
  }

  static getPresentWebUrl(): string | null {
    return this.getData<string>(TalkStudentStorageKey.PRESENT_WEB_URL);
  }

  static getRequest(): TalkStudentRequest | null {
    return this.getData<TalkStudentRequest>(TalkStudentStorageKey.REQUEST);
  }

  static clearPresentWebUrl() {
    this.clearData(TalkStudentStorageKey.PRESENT_WEB_URL);
  }

  static clearRequest() {
    this.clearData(TalkStudentStorageKey.REQUEST);
  }

  static clearAllData() {
    this.clearPresentWebUrl();
    this.clearRequest();
  }
}
