import { initReactI18next, Namespace, TFuncKey } from 'react-i18next';
import { TOptions } from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend/cjs';
import type { UserConfig } from 'next-i18next';

import { getConfig } from '@common/config';

const isBrowser = typeof window !== 'undefined';
const isDev = process.env.NODE_ENV === 'development';

const config = getConfig();

/** @public */
export const i18NextOption: UserConfig = {
  i18n: {
    defaultLocale: 'ko',
    locales: ['en', 'ja', 'ko'],
  },
  defaultNS: 'common',
  fallbackLng: 'ko',
  interpolation: { escapeValue: false },
  keySeparator: false,
  nsSeparator: ':',
  backend: {
    backendOptions: [
      {
        expirationTime: isDev ? 0 : 60 * 60 * 1000,
        loadPath: (locales: string[], namespaces: string[]) => {
          const [locale] = locales;
          const [ns] = namespaces;
          if (process.env.LOCALE_HASH) {
            return `${config.assetPrefix || ''}/locales/${locale}/${camelToKebab(ns)}.${process.env.LOCALE_HASH}.json`;
          }
          return `${config.assetPrefix || ''}/locales/${locale}/${camelToKebab(ns)}.json`;
        },
      },
      {},
    ],
    backends: isBrowser ? [HttpBackend] : [],
  },
  ns: [
    'common',
    'products',
    'cart',
    'order',
    'benefit',
    'shipping',
    'inquiry',
    'auth',
    'pay',
    'noti',
    'routeTitle',
    'addressBook',
    'affiliate',
    'search',
    'contentEditor',
    'review',
    'raffle',
  ],
  react: { useSuspense: false },
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend, initReactI18next] : [initReactI18next],
  localePath: (locale, namespace) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const localePathRoot = typeof window === 'undefined' ? require('path').resolve('./public/locales') : '/locales';
    if (process.env.LOCALE_HASH) {
      return `${localePathRoot}/${locale}/${camelToKebab(namespace)}.${process.env.LOCALE_HASH}.json`;
    }
    return `${localePathRoot}/${locale}/${camelToKebab(namespace)}.json`;
  },
};

/** @public */
export type I18nKey<N extends Namespace> = TFuncKey<N> extends infer A ? A : never;
/** @public */
export type I18nValues = TOptions<Record<string, string | number>>;

function camelToKebab(camelCase: string) {
  return camelCase.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
