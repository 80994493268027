import { createContext, useContext } from 'react';

import { ProductCardLinkBaseProps } from './components/ProductCardLink';

export interface HorizontalProductCardContextType extends ProductCardLinkBaseProps {}

const HorizontalProductCardContext = createContext<HorizontalProductCardContextType | null>(null);

export interface HorizontalProductCardProviderProps {
  horizontalProductCard: HorizontalProductCardContextType;
  children: React.ReactNode;
}

export const useHorizontalProductCardContext = () => {
  const context = useContext(HorizontalProductCardContext);
  if (!context) {
    throw new Error('useProductCardLinkContext must be used within a ProductCardProvider');
  }
  return context;
};

export const HorizontalProductCardProvider = (props: HorizontalProductCardProviderProps) => {
  const { horizontalProductCard, children } = props;
  return (
    <HorizontalProductCardContext.Provider value={horizontalProductCard}>
      {children}
    </HorizontalProductCardContext.Provider>
  );
};
