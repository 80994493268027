import { UserNotificationCategory } from '../api';

export enum NotificationTab {
  ALL = 'ALL',
  ORDER = 'ORDER',
  RESTOCK = 'RESTOCK',
  NORMAL = 'NORMAL',
  EXPIRED_USER_BENEFIT = 'EXPIRED_USER_BENEFIT',
  INFORMATION = 'INFORMATION',
  SHOP = 'SHOP',
  REVIEW = 'REVIEW',
  SAVED_PRODUCTS = 'SAVED_PRODUCTS',
  WEATHER = 'WEATHER',
}

export interface Tab {
  name: string;
  type: NotificationTab;
  log_id: string;
  sequence: number;
  categories: UserNotificationCategory[];
}

export const TAB_LIST = {
  [NotificationTab.ALL]: {
    name: '전체',
    type: NotificationTab.ALL,
    log_id: 'all',
    sequence: 1,
    categories: [],
  },
  [UserNotificationCategory.ORDER]: {
    name: '주문/배송',
    type: NotificationTab.ORDER,
    log_id: 'order_shipping',
    sequence: 2,
    categories: [UserNotificationCategory.ORDER],
  },
  [UserNotificationCategory.RESTOCK]: {
    name: '재입고',
    type: NotificationTab.RESTOCK,
    log_id: 'restock',
    sequence: 4,
    categories: [UserNotificationCategory.RESTOCK],
  },
  [UserNotificationCategory.NORMAL]: {
    name: '혜택/이벤트',
    type: NotificationTab.NORMAL,
    log_id: 'benefit_event',
    sequence: 3,
    categories: [
      UserNotificationCategory.NORMAL,
      UserNotificationCategory.ZPAY,
      UserNotificationCategory.DAILY_MISSION,
      UserNotificationCategory.BEAUTY,
      UserNotificationCategory.SALE_TAB,
    ],
  },
  [UserNotificationCategory.SAVED_PRODUCTS]: {
    name: '찜한 상품',
    type: NotificationTab.SAVED_PRODUCTS,
    log_id: 'saved_product',
    sequence: 5,
    categories: [UserNotificationCategory.SAVED_PRODUCTS],
  },
  [UserNotificationCategory.WEATHER]: {
    name: '날씨',
    type: NotificationTab.WEATHER,
    log_id: 'weather',
    sequence: 5,
    categories: [UserNotificationCategory.WEATHER],
  },
  [UserNotificationCategory.EXPIRED_USER_BENEFIT]: {
    name: '포인트 소멸',
    type: NotificationTab.EXPIRED_USER_BENEFIT,
    log_id: 'expiring_point',
    sequence: 6,
    categories: [UserNotificationCategory.EXPIRED_USER_BENEFIT],
  },
  [UserNotificationCategory.REVIEW]: {
    name: '커뮤니티',
    type: NotificationTab.REVIEW,
    log_id: 'community',
    sequence: 7,
    categories: [UserNotificationCategory.REVIEW, UserNotificationCategory.EPICK, UserNotificationCategory.TALK_LOUNGE],
  },
  [UserNotificationCategory.INFORMATION]: {
    name: '고객센터',
    type: NotificationTab.INFORMATION,
    log_id: 'customer_center',
    sequence: 10,
    categories: [UserNotificationCategory.INFORMATION],
  },
  [UserNotificationCategory.SHOP]: {
    name: '스토어소식',
    type: NotificationTab.SHOP,
    log_id: 'shop_news',
    sequence: 8,
    categories: [UserNotificationCategory.SHOP],
  },
};
