import { COOKIE_NAME } from '../constants';

export function getZigzagUuidFromRequestHeadersCookie(cookie: string): string | null {
  const cookieString = parseCookieString(cookie);
  if (cookieString[COOKIE_NAME] && cookieString[COOKIE_NAME].trim() !== '') {
    return cookieString[COOKIE_NAME];
  }
  return null;
}

function parseCookieString(cookieString: string): Record<string, string> {
  const cookies: Record<string, string> = {};
  const cookiePairs = cookieString.split(';');

  for (const cookiePair of cookiePairs) {
    const [cookieName, cookieValue] = cookiePair.trim().split('=');
    cookies[cookieName] = cookieValue;
  }

  return cookies;
}
