/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any */
import { UxGoodsCardItemForVerticalProductCard } from '../../vertical-product-card/types';
import { VirtuosoGridContainerProps } from '../types';

export function extractProps<D extends UxGoodsCardItemForVerticalProductCard, P extends {}>(
  props: VirtuosoGridContainerProps<D, P>,
) {
  const commonPropKeys = [
    'defaultColumnCount',
    'defaultSize',
    'items',
    'alignToBottom',
    'atBottomStateChange',
    'atBottomThreshold',
    'atTopStateChange',
    'atTopThreshold',
    'banner',
    'columnCount',
    'columnStyle',
    'computeItemKey',
    'customScrollParent',
    'defaultItemHeight',
    'firstItemIndex',
    'fixedItemHeight',
    'followOutput',
    'headerFooterTag',
    'increaseViewportBy',
    'initialScrollTop',
    'initialTopMostItemIndex',
    'isScrolling',
    'itemSize',
    'itemsRendered',
    'onEndReached',
    'overscan',
    'rangeChanged',
    'restoreStateFrom',
    'scrollerRef',
    'scrollSeekConfiguration',
    'startReached',
    'topItemCount',
    'totalListHeightChanged',
    'useWindowScroll',
  ] as const; // TODO: 나중에 nextjs 버전 업으로 인해 typescript satisfies를 쓸 수 있게 되면 다음과 같이 변경 `satisfies Array<keyof typeof props>`

  const commonProps = {} as any;
  const otherProps = {} as any;

  for (const key in props) {
    if (commonPropKeys.includes(key as (typeof commonPropKeys)[number])) {
      commonProps[key] = props[key as keyof typeof props];
    } else {
      otherProps[key] = props[key as keyof typeof props];
    }
  }

  return {
    commonProps,
    otherProps,
  } as {
    commonProps: Pick<typeof props, (typeof commonPropKeys)[number]>;
    otherProps: P;
  };
}
