import { callZpayFunction } from '../../callZpayFunction';

// 포토리뷰 상세
// 지원 브라우저 : Z결제 브라우저, Z결제 옵션, Product Detail(내재화된 상품 상세)
// - order_item_number : (필수) 분석용 이벤트에 사용됨
//   - navigation log parameter
//   - click_close_on_product_review_photo_detail
// - attachment_list: (필수) Array<{
//     type: ProductReviewType! : TEXT or PHOTO or VIDEO
//     original_url: String!
//     thumbnail_url: String!
//     status: ProductReviewAttachmentStatus! : NORMAL or EXCLUDED_BY_USER or DELETED_BY_ADMIN
//   }>
// - selectect_photo_index : (선택) Attachment 중 초기 선택 index (기본값 : 0)
export interface ZpayProductReviewAttachment {
  object_key?: string;
  type: string;
  original_url: string;
  thumbnail_url: string;
  status: string;
}

export const productReviewPhotoDetail = (
  order_item_number: string,
  attachment_list: ZpayProductReviewAttachment[],
  selected_photo_index = 0,
) => {
  callZpayFunction('productReviewPhotoDetail')({
    order_item_number,
    attachment_list: attachment_list.map((attachment) => ({
      object_key: '',
      ...attachment,
    })),
    selected_photo_index,
  });
};
