import { SiteId } from '@common/site-manager';

export interface BrandColorType {
  Brand_050: string;
  Brand_100: string;
  Brand_150: string;
  Brand_200: string;
  Brand_300: string;
  Brand_400: string;
  Brand_500: string;
  Brand_600: string;
  Brand_700: string;
  Brand_800: string;
  Brand_900: string;
}

export const BrandColorMap: Record<SiteId, BrandColorType> = {
  [SiteId.ZIGZAG]: {
    Brand_050: '#FEF6FD',
    Brand_100: '#FFEBFC',
    Brand_150: '#FFD1F7',
    Brand_200: '#FFB2F3',
    Brand_300: '#FF8AEB',
    Brand_400: '#FA6EE3',
    Brand_500: '#E560CF',
    Brand_600: '#BF49AB',
    Brand_700: '#913382',
    Brand_800: '#6D2261',
    Brand_900: '#4F1245',
  },
  [SiteId.POSTY]: {
    Brand_050: '#E1E5FF',
    Brand_100: '#B2BDFF',
    Brand_150: '#8C9EFF',
    Brand_200: '#7187FE',
    Brand_300: '#536DFE',
    Brand_400: '#4864FF',
    Brand_500: '#445CD1',
    Brand_600: '#384BA4',
    Brand_700: '#2D3A7A',
    Brand_800: '#232A52',
    Brand_900: '#121940',
  },
  [SiteId.FBK]: {
    Brand_050: '#FFEDF3',
    Brand_100: '#FFDEEA',
    Brand_150: '#FFCBDE',
    Brand_200: '#FFB8D2',
    Brand_300: '#FF92BA',
    Brand_400: '#FF6AA0',
    Brand_500: '#FF347D',
    Brand_600: '#E62F71',
    Brand_700: '#CF2A66',
    Brand_800: '#A72253',
    Brand_900: '#871C43',
  },
} as const;
