import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconEyeOffRegular32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path
        fill='currentColor'
        d='m6 16-.666-.346-.18.346.18.346L6 16Zm20 0 .666.346.18-.346-.18-.346L26 16Zm-10 6.25c-2.893 0-5.211-1.592-6.85-3.273a16.569 16.569 0 0 1-2.334-3.055 9.33 9.33 0 0 1-.15-.268L6 16a82.3 82.3 0 0 0-.665.347l.001.002.003.006.01.018.036.067c.031.057.077.137.136.24a18.063 18.063 0 0 0 2.555 3.344C9.848 21.841 12.53 23.75 16 23.75v-1.5ZM26 16l.665-.346v-.001l-.001-.002a.734.734 0 0 1-.003-.006l-.01-.018a15.887 15.887 0 0 0-.696-1.138 18.061 18.061 0 0 0-2.031-2.512C22.152 10.158 19.47 8.25 16 8.25v1.5c2.893 0 5.211 1.592 6.85 3.273a16.568 16.568 0 0 1 2.334 3.055 9.5 9.5 0 0 1 .15.268L26 16ZM6 16a181.572 181.572 0 0 1 .665.346l.003-.005.012-.022.052-.095a16.265 16.265 0 0 1 1.095-1.653c.777-1.03 1.911-2.29 3.36-3.264l-.836-1.245c-1.635 1.098-2.885 2.496-3.722 3.606a17.78 17.78 0 0 0-.957 1.4 13.045 13.045 0 0 0-.312.539l-.018.033a3.276 3.276 0 0 1-.005.01l-.002.002v.002L6 16Zm7.257-5.759A7.959 7.959 0 0 1 16 9.75v-1.5a9.46 9.46 0 0 0-3.257.582l.514 1.41ZM16 23.75c2.234 0 4.144-.793 5.688-1.838l-.841-1.242c-1.362.922-2.983 1.58-4.847 1.58v1.5Zm7.924-3.727a18.061 18.061 0 0 0 2.555-3.344 10.065 10.065 0 0 0 .172-.306l.01-.018a.734.734 0 0 1 .003-.006v-.002h.001v-.001L26 16a166.24 166.24 0 0 1-.665-.346l-.001.002a.818.818 0 0 1-.034.061 14.578 14.578 0 0 1-.59.959 16.568 16.568 0 0 1-1.86 2.3l1.074 1.047Z'
      />
      <path stroke='currentColor' strokeWidth={1.5} d='M13.894 13.864A3 3 0 0 0 18 18.236M7 7l18 18' />
    </Svg>
  );
};
export default SvgIconEyeOffRegular32;
