import isArray from 'lodash/fp/isArray';
import pipe from 'lodash/fp/pipe';

import { isBrowser } from '../../utils/conditions';
import { devLogger } from '../../utils/devLogger';
import { injectZpayHandler } from '../handlers/inject';

const handleArgs =
  (function_name: string) =>
  <A>(args?: A) => {
    const iosParam = { function_name, ...(!!args && { args }) };
    const aosParam = !args ? [] : Object.values(args).map((arg) => (isArray(arg) ? JSON.stringify(arg) : arg));
    return {
      iosParam,
      aosParam,
    };
  };

export const callZpayFunction = (functionname: string) =>
  pipe(injectZpayHandler, handleArgs(functionname), devLogger('zpay', functionname), ({ iosParam, aosParam }) => {
    if (!isBrowser()) {
      return console.warn('Should invoke callZpayFunction in client');
    }

    const _window: any = window;
    const inIOS = _window.webkit && _window.webkit.messageHandlers && _window.webkit.messageHandlers.zpay;
    const inAndroid = _window.zpay && _window.zpay[functionname];

    if (inIOS) {
      _window.webkit.messageHandlers.zpay.postMessage(iosParam);
    }
    if (inAndroid) {
      _window.zpay[functionname](...aosParam);
    }
  });
