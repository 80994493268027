import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { css, Global } from '@emotion/react';
import { Gray_Light_900, Gray_Light_White, web_max_width } from '@common/styles';

import { colorWithAlpha } from '@common/utils';
export interface BaseLayoutProps {
  children: ReactNode;
}
export function BaseLayout({ children }: BaseLayoutProps) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { cacheTime: 300000, refetchOnWindowFocus: false, staleTime: 300000, retry: 1 } },
      }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      <Global
        styles={css`
          #__next,
          #root {
            max-width: ${web_max_width}px;
            width: 100%;
            height: auto;
            background: ${Gray_Light_White};
            margin: 0 auto;
          }
          a,
          button {
            cursor: pointer;
            transition: background 0.25s cubic-bezier(0.33, 1, 0.68, 1), color 0.25s cubic-bezier(0.33, 1, 0.68, 1);
            :active {
              background: ${colorWithAlpha(Gray_Light_900, 0.05)};
            }
          }
        `}
      />
      {children}
    </QueryClientProvider>
  );
}
