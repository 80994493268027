import { OneDayDeliveryProps } from '../../components/OneDayDelivery';

export type ReturnMetadataOneDayDeliveryFunction<T extends undefined | null | OneDayDeliveryProps> =
  T extends OneDayDeliveryProps
    ? { hasOneDayDelivery: true; metadataOneDayDeliveryProps: OneDayDeliveryProps }
    : { hasOneDayDelivery: false; metadataOneDayDeliveryProps: undefined };

export interface UseMetadataOneDayDeliveryOptions {
  oneDayDelivery?: OneDayDeliveryProps | null;
}

export function useMetadataOneDayDelivery<
  T extends UseMetadataOneDayDeliveryOptions,
  R = ReturnMetadataOneDayDeliveryFunction<T['oneDayDelivery']>,
>(options: T): R {
  const { oneDayDelivery } = options;
  const hasOneDayDelivery = oneDayDelivery !== undefined && oneDayDelivery !== null;

  return {
    hasOneDayDelivery,
    metadataOneDayDeliveryProps: hasOneDayDelivery ? oneDayDelivery : undefined,
  } as R;
}
