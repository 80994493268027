import { SDK_ENDPOINT } from './constants';

type BrazeTrackerLogCustomEventName = 'purchase_by_order_web' | 'source';

class BrazeTracker {
  braze: typeof import('@braze/web-sdk') | null;
  contentSecurityNonce: string;

  constructor() {
    this.contentSecurityNonce = '';
    this.braze = null;
  }

  async initializeBraze(brazeApiKey: string, contentSecurityNonce: string) {
    const braze = await import('@braze/web-sdk');
    this.braze = braze;

    this.contentSecurityNonce = contentSecurityNonce;

    if (this.braze) {
      this.braze.initialize(brazeApiKey, {
        baseUrl: SDK_ENDPOINT,
        enableLogging: true,
        contentSecurityNonce,
        allowUserSuppliedJavascript: true,
        minimumIntervalBetweenTriggerActionsInSeconds: 5,
      });

      this.braze.automaticallyShowInAppMessages();
      this.braze.openSession();
    }
  }

  changeUser(uuid: string) {
    if (this.braze) {
      this.braze.changeUser(uuid);
    }
  }

  toggleLogging() {
    if (this.braze) {
      this.braze.toggleLogging();
    }
  }

  logCustomEvent(eventName: BrazeTrackerLogCustomEventName, eventProperties?: Record<string, unknown>) {
    const braze = this.braze;
    if (!braze) {
      return;
    }

    try {
      braze.logCustomEvent(eventName, eventProperties);
    } catch (e) {
      console.error('#BrazeTracker logCustomEvent Error', e);
    }
  }
}

export const brazeTracker = new BrazeTracker();
