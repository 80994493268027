const base_url = 'http://localhost';

const Config = {
  baseDomain: 'localhost',
  apiBaseUrl: `${base_url}:3061/api/store`,
  apiConsumerBaseUrl: `${base_url}:3061/api/2`,
  appUrl: `${base_url}:3061`,
  redirectAppUrl: `${base_url}:3061`,
  contentUrl: 'https://content.alpha.zigzag.kr',
  assetPrefix: '',
  payApiBaseUrl: `${base_url}:9132/api/user-pay`,
  maintenanceDataUrl: `${base_url}:3061/maintenance`,
  simplePayPassword: `${base_url}:9141/api/security-keypad/static/js/simple_pay/simple_pay_password_local.js`,
  simpleBankAppVersion: '6.5.0',
  reviewAppIOSVersion: '6.12.0',
  reviewAppAndroidVersion: '6.14.1',
  inlineImageLimit: Number.POSITIVE_INFINITY,
  localIdentName: '[path][name]__[local]--[hash:base64:5]',
  resUrl: 'https://cf.res.s.alpha.zigzag.kr',
  port: 3061,
  sentry_dsn: '',
  sentry_org: '',
  zendeskRequestOrderItemNumberKey: '4524551062799',
  zendeskRequestOrderItemCsUrlKey: '4524568239375',
  csUrl: 'https://cs.alpha.zigzag.kr',
  storybook_deploy_path: process.env.STORYBOOK_DEPLOY_PATH ? `/${process.env.STORYBOOK_DEPLOY_PATH}` : '',
  braze_api_key: '',
  ga4_id: '',
  display_google_ads_id: '',
  search_google_ads_id: '',
  amplitude_id: '',
  apps_flyer_pba_id: '',
  airbridge_app: '',
  airbridge_web_token: '',
  kakao_pixel_id: '',
  facebook_pixel_id: '',
  naver_account_id: '',
  appier_id: '',
  appier_track_id: '',
  kakao_postcode_script: 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  kakao_postcode_api: 'https://postcode.map.daum.net/search',
  business_juso_api: 'https://business.juso.go.kr/addrlink/addrMobileLinkUrl.do',
  business_juso_api_key: 'TESTJUSOGOKR', // production, beta, alpha, dev-order API 키 등록 (테스트키로는 검색결과 더미값으로 나옴)
  hackle_key: 'ZTr2ywTsTgfUw2O62zTYoZsl99lMxlH4',
  zigzag_postcode_url: 'https://store.alpha.zigzag.kr/postcode',
  kakao_rest_key: '6ef3431bc78a9764f47247dd94ae1297',
  kakao_app_key: '8e1e0475881411db87f1d618570dfdd5',
  auth_login_route: '/auth/login',
  zendesk_url: 'https://kakaostyle1647308466.zendesk.com/hc/p/requests-with-sso',
};

// from https://github.com/krzkaczor/ts-essentials
type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : // tslint:disable-next-line:no-shadowed-variable
    T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>;
};

export type BaseConfigType = DeepPartial<typeof Config>;

export default Config;
