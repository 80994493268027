import React from 'react';
import styled from '@emotion/styled';

import { colors, Typography } from '@common/styles';

export interface FormLayoutProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

export const FormLayout: React.FC<FormLayoutProps> = ({ title, description, children, footer }) => {
  return (
    <Container>
      <TitleBox>
        <Title className={Typography.HEAD_22_BOLD}>{title}</Title>
        {description && <Description className={Typography.BODY_14_MEDIUM}>{description}</Description>}
      </TitleBox>
      <FormBox>{children}</FormBox>
      {footer}
    </Container>
  );
};

const Description = styled.div`
  color: ${colors.Gray_Light_500};
  white-space: pre-line;
  word-break: keep-all;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  row-gap: 10px;
`;
const Title = styled.h1`
  margin-bottom: 8px;
  white-space: pre-line;
`;
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;
