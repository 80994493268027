import { ThemeProvider as ZDSThemeProvider, ThemeProviderProps as ZDSThemeProviderProps } from '@croquiscom-pvt/zds';

export interface ThemeProviderProps extends ZDSThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = (props: ThemeProviderProps) => {
  const { children, ...restProps } = props;
  return <ZDSThemeProvider {...restProps}>{children}</ZDSThemeProvider>;
};
