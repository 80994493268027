import { ProductCard as V4ProuctCard } from '@croquiscom-pvt/zds';

import { OneDayDelivery as OriginalOneDayDelivery } from '../../components/OneDayDelivery';
import { useMetadataOneDayDelivery, UseMetadataOneDayDeliveryOptions } from '../hooks/useMetadataOneDayDelivery';

export interface MetadataOneDayDeliveryProps extends UseMetadataOneDayDeliveryOptions {
  className?: string;
}

export const MetadataOneDayDelivery = (props: MetadataOneDayDeliveryProps) => {
  const { className, oneDayDelivery } = props;
  const { hasOneDayDelivery, metadataOneDayDeliveryProps } = useMetadataOneDayDelivery({ oneDayDelivery });

  if (!hasOneDayDelivery) {
    return null;
  }

  return (
    <V4ProuctCard.MetadataItem className={className} marginTop={6}>
      <OriginalOneDayDelivery {...metadataOneDayDeliveryProps} />
    </V4ProuctCard.MetadataItem>
  );
};
