export enum SignupType {
  SOCIAL = 'SOCIAL',
  EMAIL = 'EMAIL',
}

export interface TermsAgreement {
  email_sms_reception_agreed: boolean;
  nightly_ad_noti_agreed: boolean;
  third_provide_agreed: boolean;
}
