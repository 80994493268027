import React, { cloneElement, isValidElement, ReactElement } from 'react';
import { HStack, IconArrowRightBold, Text, VStack, ZDSIconComponentProps } from '@croquiscom-pvt/zds';
import styled from '@emotion/styled';

import { colors } from '@common/styles';

interface AuthCardProps {
  icon: ReactElement<ZDSIconComponentProps>;
  title: string;
  description: string;
  onClick?: () => void;
}

export const AuthCard: React.FC<AuthCardProps> = ({ icon, title, description, onClick }) => {
  const _icon = isValidElement(icon) ? cloneElement(icon, { v3Color: 'currentColor', size: 24 }) : null;

  return (
    <CardContainer onClick={onClick}>
      <HStack blockAlign='center'>
        <IconContainer>{_icon}</IconContainer>
        <VStack spacing={'6px'}>
          <Text variant='Body_16_SemiBold'>{title}</Text>
          <Text variant='Caption_12_Medium' v3Color='gray400'>
            {description}
          </Text>
        </VStack>
      </HStack>
      <IconArrowRightBold size={24} v3Color='gray400' />
    </CardContainer>
  );
};

const CardContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid ${colors.Gray_Light_150};
  background-color: ${colors.white};
  cursor: pointer;
  text-align: left;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 16px;
  border-radius: 10px;
  background-color: ${colors.Gray_Light_100};
  color: ${colors.Gray_Light_950};
`;
