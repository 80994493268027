import React from 'react';

export function BoxZigzagIcon({ width = 20, height = 20, ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg width={width} height={height} fill='none' viewBox='0 0 20 20' {...props}>
      <g id='BoxZigzag'>
        <path
          id='Rectangle'
          d='M1.66602 4.18449C1.66602 2.79384 2.78932 1.6665 4.17498 1.6665H15.8237C17.2094 1.6665 18.3327 2.79384 18.3327 4.18449V15.8152C18.3327 17.2058 17.2094 18.3332 15.8237 18.3332H4.17498C2.78932 18.3332 1.66602 17.2058 1.66602 15.8152V4.18449Z'
          fill='#FA6EE3'
        />
        <path
          id='Vector'
          d='M6.83073 5.4165V7.55886H9.66053L6.66602 14.5832H13.1676V12.436H10.3599L13.3327 5.4165H6.83073Z'
          fill='black'
        />
      </g>
    </svg>
  );
}
