import { likeMetaStore } from '../store/LikeStore';

export function visiblitySubscribe() {
  const handleViewEvent = () => {
    if (document.visibilityState === 'hidden') {
      const likeMeta = likeMetaStore.getState();
      for (const id in likeMeta) {
        likeMeta[id].has = true;
      }
    }
    if (document.visibilityState === 'visible') {
      likeMetaStore.setState({});
    }
  };
  document.addEventListener('visibilitychange', handleViewEvent);
  return () => document.removeEventListener('visibilitychange', handleViewEvent);
}
