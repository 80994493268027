import React from 'react';

/** browser 안에서만 동작하는 useEffect, ssr 에는 실행하지 않음 */
const useBrowserEffect: typeof React.useEffect = (effect, deps = []) => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      effect();
    }
  }, [...deps]);
};

export default useBrowserEffect;
