import React, { forwardRef, ReactNode } from 'react';
import { cx } from '@emotion/css';
import styled from '@emotion/styled';

import { colors, Typography } from '@common/styles';

export type TextFieldSize = 'small' | 'medium';

export interface TextFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  state?: 'error' | 'success';
  suffix?: ReactNode;
  /** @default medium */
  size?: TextFieldSize;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ state, suffix, size = 'medium', className, disabled, ...props }, ref) => {
    return (
      <Container
        size={size}
        className={cx(className, Typography.BODY_15_MEDIUM)}
        error={state === 'error'}
        disabled={disabled}
      >
        <Input ref={ref} {...props} disabled={disabled} />
        {suffix && <Suffix>{suffix}</Suffix>}
      </Container>
    );
  },
);

interface ContainerProps {
  size: TextFieldSize;
  error: boolean;
  disabled?: boolean;
}

const switchSize =
  (small: string, medium: string) =>
  ({ size }: ContainerProps) => {
    switch (size) {
      case 'small':
        return small;
      case 'medium':
      default:
        return medium;
    }
  };

const containerPadding = switchSize('0 12px', '0 16px');

const containerHeight = switchSize('40px', '48px');

const Container = styled.span<ContainerProps>`
  width: 100%;
  background: ${({ disabled }) => (disabled ? colors.Gray_Light_050 : colors.Gray_Light_White)};
  border: 2px solid ${({ error }) => (error ? colors.Red_Light_200 : colors.Gray_Light_150)};
  color: ${({ error }) => (error ? colors.Red_Light_200 : colors.Gray_Light_950)};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: ${containerPadding};
  height: ${containerHeight};
  &:focus-within {
    border: 2px solid ${colors.Gray_Light_200};
  }
`;

const Input = styled.input`
  caret-color: ${colors.Pink_Light_400};
  width: 100%;
  height: 100%;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  background-color: inherit;
  &::placeholder {
    color: ${colors.Gray_Light_300};
  }
  &:disabled {
    color: ${colors.Gray_Light_300};
    border-color: ${colors.Gray_Light_150};
  }
`;

const Suffix = styled.span`
  flex: 0;
  display: flex;
  align-items: center;
  position: relative;
  right: -8px;
`;
