import numeral from 'numeral';
import { CurrencyMeta, PaymentCurrency } from './graphql/currency_types.2';

/** @public */
export const DEFAULT_CURRENCY_META: CurrencyMeta = {
  currency: PaymentCurrency.KRW, // 통화 enum (KRW, USD, JPY)
  decimal: 0, // Int 소숫점 자리수 (ex> 10.23$로 변환이 되면 decimal = 2)
  converted_rate: 1.0, //  Float 원화에 곱하는 값 (원화 x exchange_rate = display 통화)
  display_currency: '원', // 보여줄 통화별 기호값. ($, ¥)
  is_currency_prefix: false, // 통화심볼이 price 앞에 오는지 여부
};

/** @public */
export interface CurrencyUtils {
  currency_meta: CurrencyMeta;
  displayPriceWithCurrency: DisplayPriceWithCurrencyFunc;
  exchangeCurrency: ExchangeCurrencyFunc;
}

interface CurrencyDisplayOptions {
  hidden_symbol?: boolean;
  exchanged?: boolean;
}

/** @public */
export interface CurrencyAmount {
  amount: number; // 원화 (비지니스 로직에 사용)
  amount_with_currency: number; // 환전 된 통화금액 (다통화 디스플레이 용도로 사용) todo:더 명확한 네이밍이 있다면 변경하자!
}

/**
 * 원화 가격을 받아 통화별 심볼과 천단위 콤마를 추가해 string으로 반환한다.
 * (ex. 5,200원, $10.23, ¥500 등)
 * @public
 * @param price 원화 가격 (Int)
 * @param {CurrencyDisplayOptions?} options (optional) { hidden_symbol: true }로 설정 시 심볼이 제거된다
 */
export type DisplayPriceWithCurrencyFunc = (price: number, options?: CurrencyDisplayOptions | undefined) => string;

/**
 * @public
 * 원화 가격을 받아 현재 통화로 환전한 금액(Number)를 반환한다.
 * @param price 원화 가격 (Int)
 */
export type ExchangeCurrencyFunc = (price: number) => number;

/**
 * @public
 * 원화 가격과 통화정보를 받아 환전한 금액(Number) 반환.
 * @param price 원화 가격 (Int)
 * @param currency_meta CurrencyMeta 정보
 */
const exchangeCurrency = (price: number, { currency, decimal, converted_rate }: CurrencyMeta): number => {
  if (currency === PaymentCurrency.KRW) {
    return price;
  }

  return Math.round(price * converted_rate * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

/**
 * @public
 * 주어진 currency meta를 이용하여,
 * 원화 가격을 받아 통화별 심볼과 천단위 콤마를 추가해 string으로 반환하는 함수를 생성한다.
 * (ex. 5,200원, $10.23, ¥500 등)
 * @param currency_meta 표기할 CurrencyMeta 정보
 */
export const generateDisplayPriceWithCurrencyFunc = (currency_meta: CurrencyMeta): DisplayPriceWithCurrencyFunc => {
  return (price: number, options?: CurrencyDisplayOptions) => {
    const { decimal, display_currency, is_currency_prefix } = currency_meta;

    const exchange_price = options?.exchanged ? price.toFixed(decimal) : exchangeCurrency(price, currency_meta);
    const displayed_price = numeral(exchange_price).format('0,0' + (decimal ? '.' + '0'.repeat(decimal) : ''));

    if (options?.hidden_symbol) {
      return displayed_price;
    }

    return is_currency_prefix ? display_currency + displayed_price : displayed_price + display_currency;
  };
};

/**
 * @public
 * 주어진 currency meta를 이용하여,
 * 원화 가격을 받아 환전한 금액(Number)을 리턴하는 함수를 생성한다.
 * @param currency_meta 표기할 CurrencyMeta 정보
 */
export const generateExchangeCurrencyFunc = (currency_meta: CurrencyMeta): ExchangeCurrencyFunc => {
  return (price: number) => {
    return exchangeCurrency(price, currency_meta);
  };
};

/**
 * @public
 * 원화 가격과 currency meta를 받아, 통화별 심볼과 천단위 콤마를 추가해 string으로 반환한다.
 * @param price 원화 가격 (Int)
 * @param currency_meta 표기할 CurrencyMeta 정보
 * @param options (optional) { hidden_symbol: true }로 설정 시 심볼이 제거된다
 */
export const displayPriceWithCurrencyMeta = (
  price: number,
  currency_meta: CurrencyMeta,
  options?: CurrencyDisplayOptions,
): string => generateDisplayPriceWithCurrencyFunc(currency_meta)(price, options);

/**
 * currency meta를 받아, 해당 통화의 최소 단위 금액을 반환한다.
 * @param currency_meta 표기할 CurrencyMeta 정보
 */
export const getMinimumCurrencyAmount = (currency_meta: CurrencyMeta) => {
  return 1 / Math.pow(10, currency_meta.decimal);
};
