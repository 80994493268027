import { ProductCard } from '@croquiscom-pvt/zds';

import { useMetadataPlpBadgeItems, UseMetadataPlpBadgeItemsOptions } from '../hooks/useMetadataPlpBadgeItems';

export interface MetadataPlpBadgeItemsProps extends UseMetadataPlpBadgeItemsOptions {
  className?: string;
}

export const MetadataPlpBadgeItems = (props: MetadataPlpBadgeItemsProps) => {
  const { className, size, plpBadgeList } = props;

  const { hasPlpBadgeItems, metadataPlpBadgeItemsProps } = useMetadataPlpBadgeItems({ size, plpBadgeList });

  if (!hasPlpBadgeItems) {
    return null;
  }

  return (
    <ProductCard.MetadataItem className={className} marginTop={6}>
      <ProductCard.MetadataBadgeItems itemSize='16px' {...metadataPlpBadgeItemsProps} />
    </ProductCard.MetadataItem>
  );
};
