import { createContext, MutableRefObject, useContext, useMemo } from 'react';

import {
  HorizontalProductCardContextType,
  HorizontalProductCardProvider,
} from './horizontal-product-card/HorizontalProductCardContext';
import {
  VerticalProductCardContextType,
  VerticalProductCardProvider,
} from './vertical-product-card/VerticalProductCardContext';

type LikeObserverContextValue = (productId: string) => {
  isLiked: boolean;
  ref: MutableRefObject<HTMLElement | null>;
};

const ProductCardUseLikeObserverContext = createContext<LikeObserverContextValue | null>(null);

export interface ProductCardProviderProps {
  useLikeObserver: LikeObserverContextValue;
  verticalProductCard?: VerticalProductCardContextType;
  horizontalProductCard?: HorizontalProductCardContextType;
  children: React.ReactNode;
}

export const useProductCardUseLikeObserverContext = () => {
  const context = useContext(ProductCardUseLikeObserverContext);
  if (!context) {
    throw new Error('ProductCardUseLikeObserverContext must be used within a ProductCardProvider');
  }
  return context;
};

export const ProductCardProvider = (props: ProductCardProviderProps) => {
  const { useLikeObserver, verticalProductCard, horizontalProductCard, children } = props;

  const verticalProductCardContextValue = useMemo<VerticalProductCardContextType>(() => {
    return {
      href: verticalProductCard?.href,
      renderProductCardLink: verticalProductCard?.renderProductCardLink,
    };
  }, [verticalProductCard]);

  const horizontalProductCardContextValue = useMemo<HorizontalProductCardContextType>(() => {
    return {
      href: horizontalProductCard?.href,
      renderProductCardLink: horizontalProductCard?.renderProductCardLink,
    };
  }, [horizontalProductCard]);

  return (
    <ProductCardUseLikeObserverContext.Provider value={useLikeObserver}>
      <VerticalProductCardProvider verticalProductCard={verticalProductCardContextValue}>
        <HorizontalProductCardProvider horizontalProductCard={horizontalProductCardContextValue}>
          {children}
        </HorizontalProductCardProvider>
      </VerticalProductCardProvider>
    </ProductCardUseLikeObserverContext.Provider>
  );
};
