import React, { createContext, useContext, useMemo } from 'react';
import { ClassNames } from '@emotion/react';

import { Swiper, SwiperProps, SwiperSlide, SwiperSlideProps } from '@common/carousel/swiper';
import { isMobile } from '@common/device-manager';

import { ResizeObserverProvider } from '../resize-observer/store';
import { ProductCardCarouselNavigation, ProductCardCarouselNavigationProps } from './components/Navigation';
import {
  ProductCardCarouselVerticalProductCardSlideItems,
  ProductCardCarouselVerticalProductCardSlideItemsProps,
} from './components/VerticalProductCardSlideItems';
import { calculateTopStyle } from './utils/calculateTopStyle';
import { carouselLayoutManager } from './carouselLayoutManager';
import {
  cssVariables,
  MAX_WIDTH,
  PRODUCT_CARD_CAROUSEL_ROOT_CLASS_NAME,
  SLIDE_ITEM_MAX_WIDTH_2_5,
  SLIDE_ITEM_MAX_WIDTH_2_12,
  SLIDE_ITEM_MAX_WIDTH_3_14,
  SLIDE_ITEM_MIN_WIDTH_2_5,
  SLIDE_ITEM_MIN_WIDTH_2_12,
  SLIDE_ITEM_MIN_WIDTH_3_14,
} from './constants';
import { getSlidesPerViewClassNameMap } from './getSlidesPerViewClassNameMap';
import { ProductCardCarouselSlidesPerView } from './types';

interface ProductCardCarouselContextType {
  cssMode: boolean;
  slidesPerView: ProductCardCarouselSlidesPerView;
  spaceBetween: number;
  slidesOffsetBefore: number;
  slidesOffsetAfter: number;
}

const ProductCardCarouselContext = createContext<ProductCardCarouselContextType | null>(null);

export function useProductCardCarouselContext() {
  const context = useContext(ProductCardCarouselContext);
  if (!context) {
    throw new Error('useProductCardCarouselContext must be used within a ProductCardCarouselContext');
  }
  return context;
}

interface ProductCardCarouselProps {
  /** @default 600 */
  resizeObserverDefaultWindowWidth?: number;
  /** @default 3.14 */
  slidesPerView?: ProductCardCarouselSlidesPerView;
  /** @default true */
  navigation?: boolean;
  children: React.ReactNode;
}

export const navigationClassNameMap = getSlidesPerViewClassNameMap('product-card-carousel-root-navigation');

const ProductCardCarouselRoot = (props: ProductCardCarouselProps) => {
  const { resizeObserverDefaultWindowWidth = MAX_WIDTH, slidesPerView = 3.14, navigation = true, children } = props;

  const isVisibleNavigation = navigation && typeof window !== 'undefined' && !isMobile();
  const cssMode = isMobile();

  const value = useMemo<ProductCardCarouselContextType>(
    () => ({
      cssMode,
      slidesPerView,
      spaceBetween: carouselLayoutManager.spaceBetween,
      slidesOffsetBefore: carouselLayoutManager.slidesOffsetBefore,
      slidesOffsetAfter: carouselLayoutManager.slidesOffsetAfter,
    }),
    [cssMode, slidesPerView],
  );

  const swiperProps = useMemo(() => {
    return {
      className: PRODUCT_CARD_CAROUSEL_ROOT_CLASS_NAME,
      cssMode: value.cssMode,
      slidesPerView: 'auto',
      slidesPerGroupAuto: true,
      slidesOffsetBefore: value.slidesOffsetBefore,
      slidesOffsetAfter: value.slidesOffsetAfter,
      spaceBetween: value.spaceBetween,
    } as SwiperProps;
  }, [value]);

  return (
    <ResizeObserverProvider defaultWindowWidth={resizeObserverDefaultWindowWidth}>
      <ProductCardCarouselContext.Provider value={value}>
        <ClassNames>
          {({ css }) => {
            const wrapperCss = css({
              [cssVariables.slidesOffsetBefore]: `${value.slidesOffsetBefore}px`,
              '.carousel': {
                boxSizing: 'border-box',
              },
              '.swiper-slide': {
                height: 'auto',
              },
              '.swiper-horizontal.swiper-css-mode > .swiper-wrapper': {
                padding: [`0px ${value.slidesOffsetBefore}px`, `0 var(${cssVariables.slidesOffsetBefore})`],
                scrollSnapType: 'none',
                boxSizing: 'border-box',
              },
            });

            const navigationCss = css`
              &.${navigationClassNameMap['2.12']} {
                ${calculateTopStyle({
                  itemMinWidth: SLIDE_ITEM_MIN_WIDTH_2_12,
                  itemMaxWidth: SLIDE_ITEM_MAX_WIDTH_2_12,
                  slidesPerView: 2.12,
                  spaceBetween: value.spaceBetween,
                  slidesOffsetBefore: value.slidesOffsetBefore,
                }).cssValue}
              }
              &.${navigationClassNameMap['2.5']} {
                ${calculateTopStyle({
                  itemMinWidth: SLIDE_ITEM_MIN_WIDTH_2_5,
                  itemMaxWidth: SLIDE_ITEM_MAX_WIDTH_2_5,
                  slidesPerView: 2.5,
                  spaceBetween: value.spaceBetween,
                  slidesOffsetBefore: value.slidesOffsetBefore,
                }).cssValue}
              }
              &.${navigationClassNameMap['3.14']} {
                ${calculateTopStyle({
                  itemMinWidth: SLIDE_ITEM_MIN_WIDTH_3_14,
                  itemMaxWidth: SLIDE_ITEM_MAX_WIDTH_3_14,
                  slidesPerView: 3.14,
                  spaceBetween: value.spaceBetween,
                  slidesOffsetBefore: value.slidesOffsetBefore,
                }).cssValue}
              }
            `;

            return (
              <div css={wrapperCss}>
                <Swiper {...swiperProps}>
                  {children}
                  {isVisibleNavigation && (
                    <ProductCardCarouselNavigation
                      css={navigationCss}
                      className={navigationClassNameMap[slidesPerView]}
                    />
                  )}
                </Swiper>
              </div>
            );
          }}
        </ClassNames>
      </ProductCardCarouselContext.Provider>
    </ResizeObserverProvider>
  );
};

ProductCardCarouselRoot.displayName = 'ProductCardCarousel.Root';

interface ProductCardCarouselSlideItemProps extends SwiperSlideProps {
  className?: string;
  children: React.ReactNode;
}

const ProductCardCarouselSlideItem = (props: ProductCardCarouselSlideItemProps) => {
  const { children, ...restProps } = props;
  return <SwiperSlide {...restProps}>{children}</SwiperSlide>;
};

// Swiper 라이브러리 이슈 때문에 displayName을 설정해줍니다. https://github.com/nolimits4web/swiper/issues/4413#issuecomment-1021387492
ProductCardCarouselSlideItem.displayName = 'SwiperSlide';

export {
  ProductCardCarouselNavigation as Navigation,
  ProductCardCarouselRoot as Root,
  ProductCardCarouselSlideItem as SlideItem,
  ProductCardCarouselVerticalProductCardSlideItems as VerticalProductCardSlideItems,
};

export type {
  ProductCardCarouselNavigationProps as NavigationProps,
  ProductCardCarouselProps as RootProps,
  ProductCardCarouselSlideItemProps as SlideItemProps,
  ProductCardCarouselVerticalProductCardSlideItemsProps as VerticalProductCardSlideItemsProps,
};
