import { css } from '@emotion/react';

import { getConfig } from '@common/config';

const config = getConfig();

export const font = css`
  @font-face {
    font-family: Pretendard;
    src: local('Pretendard-Regular'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Regular.subset.woff2') format('woff2'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Regular.woff2') format('woff2'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Regular.subset.woff') format('woff'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: local('Pretendard-Medium'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Medium.subset.woff2') format('woff2'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Medium.woff2') format('woff2'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Medium.subset.woff') format('woff'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: local('Pretendard-SemiBold'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-SemiBold.subset.woff2') format('woff2'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-SemiBold.woff2') format('woff2'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-SemiBold.subset.woff') format('woff'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: local('Pretendard-Bold'), url('${config.resUrl}/fonts/Pretendard/Pretendard-Bold.subset.woff2') format('woff2'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Bold.woff2') format('woff2'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Bold.subset.woff') format('woff'),
      url('${config.resUrl}/fonts/Pretendard/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
  }

  .HEAD_28 {
    font-size: 28px;
    line-height: 34px;
  }
  .HEAD_24 {
    font-size: 24px;
    line-height: 29px;
  }
  .HEAD_22 {
    font-size: 22px;
    line-height: 26px;
  }
  .HEAD_20 {
    font-size: 20px;
    line-height: 24px;
  }
  .BODY_18 {
    font-size: 18px;
    line-height: 22px;
  }
  .BODY_17 {
    font-size: 17px;
    line-height: 20px;
  }
  .BODY_16 {
    font-size: 16px;
    line-height: 19px;
  }
  .BODY_15 {
    font-size: 15px;
    line-height: 18px;
  }
  .BODY_14 {
    font-size: 14px;
    line-height: 17px;
  }
  .BODY_13 {
    font-size: 13px;
    line-height: 16px;
  }
  .CAPTION_12 {
    font-size: 12px;
    line-height: 14px;
  }
  .CAPTION_11 {
    font-size: 11px;
    line-height: 13px;
  }
  .CAPTION_10 {
    font-size: 10px;
    line-height: 12px;
  }
  .CAPTION_9 {
    font-size: 9px;
    line-height: 11px;
  }

  .HEAD_28,
  .HEAD_24,
  .HEAD_22,
  .HEAD_20,
  .BODY_18,
  .BODY_17,
  .BODY_16,
  .BODY_15,
  .BODY_14,
  .BODY_13,
  .CAPTION_12,
  .CAPTION_11,
  .CAPTION_10,
  .CAPTION_9 {
    &.BOLD {
      font-weight: 700;
    }
    &.SEMIBOLD {
      font-weight: 600;
    }
    &.MEDIUM {
      font-weight: 500;
    }
    &.REGULAR {
      font-weight: 400;
    }
  }
`;
