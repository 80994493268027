import { useMemo } from 'react';

import { Log, Tracker, useTracker } from './Tracker';
import { UBL, ZigzagEvent } from './zigzag_app_event';

export interface UBLSender extends Tracker {
  (log: Log): void | Promise<void>;
}

export function getUblSender({ tracker }: { tracker: Tracker }) {
  let ublSender = (log: Log) => {
    const is_zigzag = isZigzagApp();
    const condition = getLogCondition(is_zigzag, log.category);

    switch (condition) {
      case 'APP_LOG':
        return ZigzagEvent.subl(convertToAppUblFromLog(log));
      case 'WEB_ADD':
        return tracker.addLog(log);
      case 'WEB_SEND':
        return tracker.sendImmediately(log);
      case 'WEB_PAGEVIEW':
        return tracker.addLog(convertToPageviewLog(log));
    }
  };
  ublSender = Object.assign(ublSender, tracker);
  return ublSender as unknown as UBLSender;
}

export function useUBL() {
  const tracker = useTracker();
  const ublSender = useMemo(() => getUblSender({ tracker }), [tracker]);

  return ublSender;
}

function isZigzagApp() {
  const app_window = window as any;
  const HAS_ZIGZAG_PROPS = !!(app_window?.zigzag || app_window?.webkit?.messageHandlers?.zigzag);
  return HAS_ZIGZAG_PROPS || /(zigzag)/i.test(navigator.userAgent);
}

function getLogCondition(isZigzagApp: boolean, category: Log['category']) {
  if (isZigzagApp) {
    return 'APP_LOG';
  }
  if (category === 'tti') {
    return 'WEB_SEND';
  }
  if (category === 'click') {
    return 'WEB_SEND';
  }
  if (category === 'pageview') {
    return 'WEB_PAGEVIEW';
  }
  return 'WEB_ADD';
}

function convertToPageviewLog(log: Log): Log {
  log.navigation_sub = { ...log.navigation_sub, page_url: window.location.href };
  log.data = { ...log.data, is_opened: true };
  return log;
}

function convertToAppUblFromLog(log: Log): UBL {
  const { navigation_sub, data, ...restLog } = log;
  const ubl: UBL = { ...restLog };

  if (navigation_sub) {
    ubl.navigation_sub = JSON.stringify(log.navigation_sub);
  }

  if (data) {
    ubl.data = JSON.stringify(log.data);
  }

  return ubl;
}
