import { createContext, useContext } from 'react';

import { ProductCardLinkBaseProps } from './components/ProductCardLink';

export interface VerticalProductCardContextType extends ProductCardLinkBaseProps {}

const VerticalProductCardContext = createContext<VerticalProductCardContextType | null>(null);

export interface VerticalProductCardProviderProps {
  verticalProductCard: VerticalProductCardContextType;
  children: React.ReactNode;
}

export const useVerticalProductCardContext = () => {
  const context = useContext(VerticalProductCardContext);
  if (!context) {
    throw new Error('useProductCardLinkContext must be used within a ProductCardProvider');
  }
  return context;
};

export const VerticalProductCardProvider = (props: VerticalProductCardProviderProps) => {
  const { verticalProductCard, children } = props;
  return (
    <VerticalProductCardContext.Provider value={verticalProductCard}>{children}</VerticalProductCardContext.Provider>
  );
};
