import { Namespace, useTranslation } from 'react-i18next';

import { I18nKey, I18nValues } from '@common/i18n';

interface TransPlainTextProps<N extends Namespace> {
  ns: N;
  i18nKey: I18nKey<N>;
  values?: I18nValues;
}

export const TransPlainText = <N extends Namespace>({ ns, i18nKey, values }: TransPlainTextProps<N>) => {
  const { t } = useTranslation(ns);
  return <>{t(i18nKey, values)}</>;
};
