import constant from 'lodash/fp/constant';
import noop from 'lodash/fp/noop';

import { isBrowser } from '../../../utils/conditions';

export const injectZpayHandler = <A>(args?: A) => {
  if (!isBrowser()) {
    return console.warn('Should invoke injectZpayHandler in client');
  }

  const _window: any = window;
  if (!_window.zpayHandler) {
    _window.zpayHandler = {
      onMessage: noop,
      handleBackClick: constant(false),
      handleCloseClick: constant(false),
    };
  }
  return args;
};
