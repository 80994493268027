export const web_max_width = 600;
export const web_mobile_width = 450;

/**
 * Card Thumnail (Image)
 * 섬네일 이미지에 Corner Radius 값을 사용할 때 권장하는 수치
 *
 * lg(160 ~): 12
 * md(63 ~): 8
 * sm(default): 4
 */
export const image_thumbnail_corner_radius: { lg: 12; md: 8; sm: 4 } = {
  lg: 12,
  md: 8,
  sm: 4,
};
