export const filterZigzagSid = (cookies: string[]) => {
  const modified_cookies = cookies.reduce((acc: string[], cur: string) => {
    if (cur.includes('connect.sid') && /(store|content)(?=\.)(|.+)\.zigzag\.kr/i.test(cur)) {
      return [...acc, cur.replace(/(Expires=)(|.+)(;)/i, 'max-age=Sun 11 Nov 2023 00:00:00 GMT; max-age=-1;')];
    }

    return acc;
  }, []);

  return modified_cookies;
};
