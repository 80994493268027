import { Gray_Light_150, Pink_Light_400 } from '@common/styles';

export const IconCheckLargeSelected = ({
  width = 24,
  height = 24,
  color = Pink_Light_400,
  disable_color = Gray_Light_150,
  disabled,
  ...props
}: JSX.IntrinsicElements['svg'] & {
  disable_color?: string;
  disabled?: boolean;
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    color={disabled ? disable_color : color}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23 12C23 5.92803 18.072 1 12 1C5.92803 1 1 5.92803 1 12C1 18.072 5.92803 23 12 23C18.072 23 23 18.072 23 12ZM18.3217 8.91421L16.9075 7.5L10.4393 13.9682L6.81424 10.3431L5.40002 11.7573L10.4393 16.7966L18.3217 8.91421Z'
      fill='currentColor'
    />
  </svg>
);
